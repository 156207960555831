import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ILeague} from "data/providers/api/league.api.provider";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {H2HFinalType, RoundStatus} from "data/enums";
import {generateFinalsArrayAccordingFinalRound} from "data/utils/finals";
import {last} from "lodash";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IMatchupStore} from "data/stores/matchup/matchup.store";
import type {IDropdownRound, IRound} from "data/providers/json/json.provider";
import type {SelectChangeEvent} from "@mui/material";
import type {IDriverStore} from "data/stores/driver/drivers.store";

export interface ILeagueH2HMatchUpController extends ViewController {
	fetchMatchup: () => void;

	handleRoundChange: (event: SelectChangeEvent<number>) => void;

	get isH2HLeagueStarted(): boolean;

	get roundFixtures(): number[][] | undefined;

	get league(): ILeague | undefined;

	get currentRoundId(): number;

	get availableRounds(): IRound[];

	get preparedRounds(): IDropdownRound[];

	get preparedWithoutFutureRounds(): IDropdownRound[];

	get isLoading(): boolean;
}

@injectable()
export class LeagueH2HMatchUpController implements ILeagueH2HMatchUpController {
	@observable private _selectedRoundId: number | undefined = undefined;

	constructor(
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore,
		@inject(Bindings.MatchupStore) private _matchupStore: IMatchupStore
	) {
		makeAutoObservable(this);
	}

	get league(): ILeague | undefined {
		return this._leaguesStore.selectedLeague;
	}

	get isH2HLeagueStarted(): boolean {
		return this._leaguesStore.getIsH2HLeagueStarted(this.league);
	}

	get roundFixtures(): number[][] | undefined {
		return this.league?.fixture?.[this.currentRoundId];
	}

	get currentRoundId() {
		return this._selectedRoundId || this._roundStore.currentRound?.id || 0;
	}

	protected get finalRounds(): IRound[] {
		const finalRoundIds = this.isTop4 ? [23, 24, 25] : [22, 23, 24, 25];
		const finalRounds = this._roundStore.list.filter((e) => finalRoundIds.includes(e.id));

		const leagueRoundsWithoutFinals = this._roundStore.list.filter((e) => {
			return e.id >= (this.league?.startRound || 0) && !finalRoundIds.includes(e.id);
		});

		const isFirstFinalComplete = finalRounds[0]?.status === RoundStatus.Complete;

		if (last(leagueRoundsWithoutFinals)?.status !== RoundStatus.Complete) {
			return [];
		}

		if (this.isTop4) {
			return isFirstFinalComplete ? finalRounds : [finalRounds[0]];
		}

		const semiFinal = finalRounds.find((e) => e.id === 24);

		if (semiFinal?.status === RoundStatus.Complete) {
			return finalRounds;
		}

		if (isFirstFinalComplete) {
			return finalRounds.slice(0, 3);
		}

		return [finalRounds[0]];
	}

	protected get isTop4(): boolean {
		return this.league?.finalsFormat === H2HFinalType.Top4;
	}

	get availableRounds(): IRound[] {
		const final3 = generateFinalsArrayAccordingFinalRound(this._roundStore.list.length, 3);
		const final4 = generateFinalsArrayAccordingFinalRound(this._roundStore.list.length, 4);

		const finalRounds = this.isTop4 ? final3 : final4;
		const leagueRoundsWithoutFinals = this._roundStore.list.filter((e) => {
			return e.id >= (this.league?.startRound || 0) && !finalRounds.includes(e.id);
		});

		//const preparedFinals = this.finalRounds.filter(Boolean);
		const preparedFinals = this._roundStore.list.filter((e) => finalRounds.includes(e.id));

		return [...leagueRoundsWithoutFinals, ...preparedFinals];
	}

	get preparedRounds(): IDropdownRound[] {
		return this.availableRounds.map((round) =>
			this._roundStore.prepareRoundForDropdown(round, this.league)
		);
	}

	get preparedWithoutFutureRounds(): IDropdownRound[] {
		const result = [];

		for (const item of this.preparedRounds) {
			result.push(item);
			if (item.status === RoundStatus.Scheduled) {
				break;
			}
		}
		return result;
	}

	get isLoading(): boolean {
		return this._matchupStore.isLoading;
	}

	public fetchMatchup = (round?: number): void => {
		void this._matchupStore.fetchMatchups(round || this.currentRoundId);
	};

	public handleRoundChange = (event: SelectChangeEvent<number>): void => {
		const value = Number(event.target.value);

		if (!value) return;

		this._selectedRoundId = value;
		this.fetchMatchup(value);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		void this._driverStore.fetchDrivers();
	}
}
