import React, {Fragment} from "react";
import {ModalAuth} from "views/components/modals/modal_auth.component";
import {ModalForgotPassword} from "views/components/modals/modal_forgot_password.component";
import {ModalResetPassword} from "views/components/modals/modal_reset_password";
import {ModalAccountUpdatedComponent} from "views/components/modals/modal_account_update.component";
import {ModalDeactivateConfirm} from "views/components/modals/modal_confirm_deactivate.component";
import {ModalPasswordChangedComponent} from "views/components/modals/modal_password_changed.component";
import {ModalError} from "views/components/modals/modal_error.component";
import {NotificationComponent} from "views/components/Notification/notification.component";
import {ModalUserExists} from "views/components/modals/modal_user_exists/modal_user_exists.component";
import {ModalTerms} from "views/components/modals/modal_terms/modal_terms.component";

export const Modals: React.FC = () => (
	<Fragment>
		<ModalAuth />
		<ModalForgotPassword />
		<ModalResetPassword />
		<ModalAccountUpdatedComponent />
		<ModalPasswordChangedComponent />
		<ModalDeactivateConfirm />
		<ModalError />
		<NotificationComponent />
		<ModalUserExists />
		<ModalTerms />
	</Fragment>
);
