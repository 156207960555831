import {ViewController} from "data/types/structure";
import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {RoundStatus} from "data/enums";
import {last} from "lodash";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IMatchupStore} from "data/stores/matchup/matchup.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IMatchupTeam} from "data/providers/api/league.api.provider";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IDriverStore} from "data/stores/driver/drivers.store";

interface IParams {
	homeTeamId: number;
	awayTeamId: number;
	roundId: number;
}

export interface IMatchupCardController extends ViewController<IParams> {
	get homeTeam(): IMatchupTeam | undefined;

	get awayTeam(): IMatchupTeam | undefined;

	get isMatchupCanBeOpen(): boolean;

	get isHomeWinner(): boolean;

	get isAwayWinner(): boolean;

	get homeCaptainImage(): string;

	get awayCaptainImage(): string;
}

@injectable()
export class MatchupCardController implements IMatchupCardController {
	private _homeTeamId: number | undefined;
	private _awayTeamId: number | undefined;
	private _roundId: number | undefined;

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.MatchupStore) private _matchupStore: IMatchupStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore
	) {
		makeAutoObservable(this);
	}

	get isMatchupCanBeOpen(): boolean {
		const round = this._roundsStore.getRoundById(this._roundId);
		if (!this.userId || round?.status === RoundStatus.Scheduled) {
			return false;
		}

		return this.homeTeam?.userId === this.userId || this.awayTeam?.userId === this.userId;
	}

	get homeTeam(): IMatchupTeam | undefined {
		if (!this._homeTeamId) return;

		return this.getTeamById(this._homeTeamId);
	}

	get awayTeam(): IMatchupTeam | undefined {
		if (!this._awayTeamId) return;

		return this.getTeamById(this._awayTeamId);
	}

	get isHomeWinner(): boolean {
		return this.isFinal && (this.homeTeam?.points || 0) > (this.awayTeam?.points || 0);
	}

	get isAwayWinner(): boolean {
		return this.isFinal && (this.awayTeam?.points || 0) > (this.homeTeam?.points || 0);
	}

	private get userId(): number | undefined {
		return this._userStore.user?.id;
	}

	protected get isFinal(): boolean {
		const lastRound = last(this._roundsStore.list);
		if (!this._roundId || !lastRound) {
			return false;
		}
		return this._roundId === lastRound.id;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._homeTeamId = param.homeTeamId;
		this._awayTeamId = param.awayTeamId;
		this._roundId = param.roundId;
	}

	private getTeamById = (teamId: number): IMatchupTeam | undefined => {
		return this._matchupStore.getMatchupTeamById(teamId);
	};

	public get awayCaptainImage(): string {
		const captain = this.awayTeam?.lineup.captain;
		const driver = this._driverStore.getDriverById(captain);
		if (!driver) {
			return "";
		}
		return driver.image;
	}

	public get homeCaptainImage(): string {
		const captainId = this.homeTeam?.lineup.captain;
		const driver = this._driverStore.getDriverById(captainId);
		if (!driver) {
			return "";
		}
		return driver.image;
	}
}
