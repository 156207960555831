import React, {Fragment} from "react";
import styled from "styled-components";
import {Outlet} from "react-router-dom";

import {Nav} from "views/components/Nav/nav.component";
import {Footer} from "views/components/footer/footer_component";
import backgroundImg from "assets/img/background.png";
import {useViewController} from "data/services/locator";
import type {IMainLayoutController} from "views/components/main_layout/main_layout.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import {AdWidgetMREC} from "views/components/ad_widget_mrec/ad_widget_mrec.component";
import {AdWidgetLong} from "views/components/ad_widget_long/ad_widget_long.component";
import {mobileViewQuery} from "data/constants";
import {Container as BaseContainer} from "views/components/common";
import media from "assets/css/media";
import {NotificationBar} from "views/components/notifications_bar/notifications_bar.component";
export const Main = styled.main`
	display: flex;
	flex-flow: column;
	flex: 1;
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;

	@media (max-width: ${media.mobile}) {
		background: #d9d9d9;
	}
`;

const MainWrapper = styled.div`
	width: 100%;
	display: flex;
	gap: 40px;
	padding-top: 40px;
`;

const TwoThirds = styled.div`
	display: flex;
	flex-direction: column;
	width: 66.66%;
	@media (${mobileViewQuery}) {
		width: 100%;
	}
`;

const OneThird = styled.div`
	display: flex;
	flex-direction: column;
	width: 33.33%;
	@media (${mobileViewQuery}) {
		display: none;
	}
`;

const PageBanner = styled.div`
	width: 100%;
	background: url(${backgroundImg});
	background-size: cover;
	height: 65px;
	padding: 0 30px;

	@media (max-width: ${media.tablet}) {
		padding: 0;
	}
`;

const InnerContent = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 20px;
	color: white;
	font-family: var(--fontFamilyBase);
	font-weight: 600;
	letter-spacing: 0.2px;
	height: 100%;
	padding: 0 0;
	@media (${mobileViewQuery}) {
		padding-left: 20px;
	}
`;

const FlexCol = styled.div`
	background: var(--primaryColor);
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const MainLayoutAuth: React.FC = observer(() => {
	const controller = useViewController<IMainLayoutController>(Bindings.MainLayoutController);
	return (
		<Fragment>
			<NotificationBar />
			<Nav />
			<PageBanner>
				<InnerContent>{controller.title}</InnerContent>
			</PageBanner>
			<Main>
				<Outlet />
			</Main>
			<AdWidgetLong />

			<Footer />
		</Fragment>
	);
});

export const AdSideLayout: React.FC = observer(() => {
	const controller = useViewController<IMainLayoutController>(Bindings.MainLayoutController);
	return (
		<Fragment>
			<NotificationBar />
			<Nav />
			<PageBanner>
				<InnerContent>{controller.title}</InnerContent>
			</PageBanner>
			<Main>
				<MainWrapper>
					<TwoThirds>
						<Outlet />
					</TwoThirds>
					<OneThird>
						<AdWidgetMREC />
					</OneThird>
				</MainWrapper>
			</Main>
			{/* <AdWidgetLong /> */}
			<Footer />
		</Fragment>
	);
});

export const LandingLayout: React.FC = () => {
	return (
		<Fragment>
			<FlexCol>
				<Outlet />
			</FlexCol>
			<Footer />
		</Fragment>
	);
};
const Container = styled(BaseContainer)`
	display: flex;
	flex-flow: column;
	align-items: center;
`;

interface ITwoColsLayoutWithContent {
	mainContent: React.ReactNode;
	rightContent: React.ReactNode;
	reverse?: boolean;
}

const LayoutContent = styled.section`
	flex: 1;
	@media (max-width: 1000px) {
		width: 100%;
	}
`;

const Aside = styled.aside`
	width: 380px;
`;

const TwoColumnLayout = styled.div<{
	reverse?: boolean;
}>`
	display: flex;
	flex-flow: column;
	width: 100%;

	${Container} {
		flex-flow: row;
		align-items: flex-start;
		gap: 80px;
		margin-bottom: 0px;
		padding: 0;
		margin-left: 0;
	}

	@media (max-width: 1200px) {
		${Container} {
			gap: 10px;
			margin-bottom: 0px;
		}
	}

	@media (max-width: 1000px) {
		${Container} {
			flex-direction: column;

			${({reverse}) =>
				reverse &&
				`
				flex-direction: column-reverse;
			`}
		}
		${Aside} {
			margin: auto;
			width: 100%;
		}
	}
	@media (max-width: ${media.mobile}) {
		background: #eeeeee;
		${Container} {
			gap: 0;
		}
	}
`;

export const TwoColsLayoutWithContent: React.FC<ITwoColsLayoutWithContent> = ({
	mainContent,
	rightContent,
	reverse,
}) => (
	<React.Fragment>
		<TwoColumnLayout reverse={reverse}>
			<Container>
				<LayoutContent>{mainContent}</LayoutContent>
				<Aside>{rightContent}</Aside>
			</Container>
		</TwoColumnLayout>
	</React.Fragment>
);
