import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ModalType, RoundStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRound} from "data/providers/json/json.provider";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IModalTeamSavedController extends ViewController {
	close: () => void;

	get isOpen(): boolean;

	get nextRound(): IRound | undefined;
}

@injectable()
export class ModalTeamSavedController implements IModalTeamSavedController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	public get nextRound(): IRound | undefined {
		if (this.currentRound?.status === RoundStatus.Scheduled) {
			return this.currentRound;
		}

		const currentIndex = this._roundsStore.list.findIndex(
			(e) => e.id === this.currentRound?.id
		);
		if (currentIndex === -1) {
			return;
		}
		return this._roundsStore.list[currentIndex + 1];
	}

	public get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TEAM_SAVED;
	}

	private get currentRound(): IRound | undefined {
		return this._roundsStore.currentRound;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};
}
