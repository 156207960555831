import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {ICountry, IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";

export interface ICountriesStore {
	fetchCountries(): Promise<void>;
	list: ICountry[];
}

@injectable()
export class CountriesStore implements ICountriesStore {
	@observable private _list: ICountry[] = [];

	get list() {
		return this._list;
	}

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action async fetchCountries() {
		try {
			const {data} = await this._jsonProvider.countries();

			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		}
	}
}
