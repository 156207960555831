import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IDriver} from "data/types/team";
import {action, makeAutoObservable, observable, toJS} from "mobx";
import {TradeSideType} from "data/enums";

interface IInitProps {
	driver: IDriver;
}

export interface IDriverActionsController extends ViewController<IInitProps> {
	addDriverToTeam: (driver: IDriver) => void;

	isDriverInTeam: (driver: IDriver) => boolean;

	removeDriverFromTeam: (driver: IDriver) => void;

	handleDriverOnTrade: (driver: IDriver) => void;

	isCostMoreThanSalaryCap: (driver: IDriver) => boolean;

	isRowFull: (driver: IDriver) => boolean;

	isPlayerActionsDisabled: (driver: IDriver) => boolean;

	closeDriverPool: () => void;

	onChange: (params: IInitProps) => void;

	get isTeamFullFilled(): boolean;

	get isOnSwap(): boolean;

	get showTradesIcon(): boolean;

	get isOnTrade(): boolean;

	get isAddDisabled(): boolean;
}

@injectable()
export class DriverActionsController implements IDriverActionsController {
	@observable _driver: IDriver | null = null;

	constructor(@inject(Bindings.TeamStore) private _teamStore: ITeamStore) {
		makeAutoObservable(this);
	}

	get isTeamFullFilled(): boolean {
		const drivers = this._teamStore.getDriversInTeam;
		return drivers.every((e) => e !== 0);
	}

	get showTradesIcon(): boolean {
		if (!this._driver) {
			return false;
		}
		const isInTeam = this.isDriverInTeam(this._driver);
		return isInTeam
			? this._teamStore.isOutTradesAvailable(this._driver)
			: this._teamStore.isInTradesAvailable(this._driver);
	}

	get jsDriver() {
		return toJS(this._driver);
	}

	get isOnTrade(): boolean {
		return this._teamStore.isDriverOnTrade(this.jsDriver?.id);
	}

	get isOnSwap(): boolean {
		return this._teamStore.isSwapInProgress;
	}

	get isAddDisabled(): boolean {
		return this.isPlayerActionsDisabled(this._driver) && !this.isDriverInTeam(this._driver);
	}

	public isCostMoreThanSalaryCap = (driver: IDriver): boolean => {
		if (!driver) {
			return true;
		}

		return driver.cost > this._teamStore.salaryCap;
	};

	public isRowFull = (driver: IDriver): boolean => {
		if (!driver) {
			return true;
		}
		const row = this._teamStore.getLineupForRow(driver.regionId);
		return row.every((e) => e !== 0);
	};

	public isPlayerActionsDisabled = (driver: IDriver | null): boolean => {
		if (!driver) {
			return true;
		}

		return (
			this.isCostMoreThanSalaryCap(driver) || this.isTeamFullFilled || this.isRowFull(driver)
		);
	};

	dispose(): void {
		return;
	}

	@action
	init(params: IInitProps): void {
		if (params.driver) {
			this._driver = params.driver;
		}
	}

	@action
	onChange = (params: IInitProps): void => {
		this._driver = params.driver;
	};

	public addDriverToTeam = (driver: IDriver) => {
		this._teamStore.addDriverToTeam(driver.id);
	};

	public removeDriverFromTeam = (driver: IDriver) => {
		this._teamStore.removeDriverFromTeam(driver.id);
	};

	public isDriverInTeam = (driver: IDriver | null) => {
		if (!driver) {
			return false;
		}
		return this._teamStore.getIsDriverInTeam(driver.id);
	};

	public closeDriverPool = () => {
		this._teamStore.toggleDriverPool(false);
	};

	public handleDriverOnTrade = (driver: IDriver) => {
		const side = this.isDriverInTeam(driver) ? TradeSideType.tradeOut : TradeSideType.tradeIn;
		if (this.isOnTrade) {
			this._teamStore.removeDriverFromTrade(driver.id, side);
		} else {
			this._teamStore.tryAddDriverToTrade(driver, side);
		}
	};
}
