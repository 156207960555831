import React, {Fragment, ReactNode} from "react";
import {PagePreloader} from "views/components/Preloader";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import {IGameGateController} from "views/components/session_gate/game_gate.controller";
import {Bindings} from "data/constants/bindings";
import {IS_PRESEASON, PRESEASON_SEARCH_KEY} from "data/constants";

export const GameGate: React.FC<{children: ReactNode}> = observer(({children}) => {
	const {isSessionChecked, updateStorage} = useViewController<IGameGateController>(
		Bindings.GameGateController
	);
	const searchParams = new URLSearchParams(window.location.search);

	if (searchParams.get(PRESEASON_SEARCH_KEY) === "true") {
		void updateStorage();
	}

	if (IS_PRESEASON) {
		return <Fragment>{children}</Fragment>;
	}
	return isSessionChecked ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
