import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ITempUserData, IUserStore} from "data/stores/user/user.store";
import type {ILoginPayload} from "data/providers/api/auth.api.provider";
import React from "react";
import {action, makeAutoObservable, observable} from "mobx";
import {ModalType, RequestState} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {Bindings} from "data/constants/bindings";
import {getErrorMessageFromAxiosResponse, trackSentryErrors} from "data/utils";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";

export interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

export interface IUserLoginData {
	email: string;
	password: string;
	confirmPassword: string;
	firstName: string;
	lastName: string;
	teamName: string;
	dob: string;
	country: string;
	postcode: number;
	favDriver: string;
	terms: boolean;
	isNotificationsEnabled: boolean;
}

export interface IFormLoginController extends ViewController {
	get error(): string | undefined;
	get formErrors(): Record<string, string>;
	get tmpUserData(): ITempUserData | null;
	get isFormLocked(): boolean;
	get formValidationHelper(): IFormValidationHelper;
	get requestState(): RequestState;
	get isAuthorized(): boolean;

	handleSubmitForm: (event: React.SyntheticEvent<ILoginForm>) => void;
	handleFormChange: (event: React.ChangeEvent<ILoginForm>) => void;
	goToRegistration: () => void;
	goToForgotPassword: () => void;
	login: (params: ILoginPayload) => Promise<void>;
}

@injectable()
export class FormLoginController implements IFormLoginController {
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _error?: string = undefined;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	get tmpUserData() {
		return this._userStore.tmpUserData;
	}

	get error() {
		return this._error;
	}

	get requestState() {
		return this._requestState;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get isFormLocked() {
		return this._requestState === RequestState.PENDING;
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	goToRegistration = () => this._modalsStore.showModal(ModalType.REGISTRATION);

	goToForgotPassword = () => this._modalsStore.showModal(ModalType.FORGOT_PASSWORD);

	@action handleFormChange = ({target}: React.ChangeEvent<HTMLFormElement>) => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
		this._validationHelper.clearFormFieldError(target.name);
		this._userStore.setTmpUserData({
			[target.name]: target.value as string,
		});
	};

	@action private onError = (error: AxiosError) => {
		trackSentryErrors(error, {}, "form login");
		this._requestState = RequestState.ERROR;
		this._error = getErrorMessageFromAxiosResponse(
			error as AxiosError<IAxiosApiError, unknown>
		);
	};

	@action private onSuccess = () => {
		this._modalsStore.hideModal();
		this._requestState = RequestState.SUCCESS;
	};

	@action login({email, password}: ILoginPayload) {
		this._requestState = RequestState.PENDING;

		return this._userStore.login({email, password}).then(this.onSuccess).catch(this.onError);
	}

	@action handleSubmitForm = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		this._error = undefined;
		const form = event.currentTarget;

		if (!this._validationHelper.checkValidity(form)) {
			return;
		}

		const {email, password} = form;

		void this.login({
			email: email.value,
			password: password.value,
		});
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	get formValidationHelper(): IFormValidationHelper {
		return this._validationHelper;
	}
}
