import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IStaticContentStore} from "data/stores/static_content/static_content.store";

export interface INotificationBarController extends ViewController {
	get isVisible(): boolean;
	get message(): string;
}

@injectable()
export class NotificationBarController implements INotificationBarController {
	constructor(
		@inject(Bindings.StaticContentStore) private _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		void this._staticContentStore.fetchNotificationBar();
	}

	get isVisible(): boolean {
		return this._staticContentStore.notificationBar.isEnabled;
	}

	get message(): string {
		return this._staticContentStore.notificationBar.message;
	}
}
