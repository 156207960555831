import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ILeague} from "data/providers/api/league.api.provider";
import type {ITitleStore} from "data/stores/title/title.store";

interface IControllerProps {
	leagueId: number | undefined;
}

export interface ILeagueAboutController extends ViewController<IControllerProps> {
	get league(): ILeague | undefined;
}

@injectable()
export class LeagueAboutController implements ILeagueAboutController {
	@observable _leagueId: number | undefined;

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	get league() {
		return this._leaguesStore.selectedLeague;
	}

	dispose(): void {
		return;
	}

	init({leagueId = 0}: IControllerProps) {
		this._leagueId = leagueId;
		this.setTitle();
	}

	setTitle() {
		if (!this._leaguesStore.selectedLeague) {
			return;
		}
		void this._titleStore.setTitle(this._leaguesStore.selectedLeague?.name);
	}
}
