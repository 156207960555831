import {useContext, useEffect, useRef} from "react";
import {interfaces} from "inversify";
import {ViewController} from "data/types/structure";
import {InjectionContext} from "data/services/locator/locator_provider.service";

/**
 * React hook that used to receive ViewModel(aka ViewController) from the DI container.
 * @param identifier - The first parameter is a ViewModel identifier by which a class was registered.
 * We highly recommend to use "Symbol" for this.
 * @param param - The second parameter is an optional parameter that will be passed to the "init" method on instance creation.
 * The parameter type is inferred from a generic type.
 *
 * @example
 * const {decrement, increment, counter} = useViewController<IController>(IController, {
 *     initialCounter: 5
 * });
 */
export function useViewController<
	T extends ViewController<T2>,
	T2 = T extends ViewController<infer TParam> ? TParam : undefined
>(
	identifier: interfaces.ServiceIdentifier<T>,
	...param: T extends ViewController<infer TParam> ? [TParam] : []
) {
	const {container} = useContext(InjectionContext);

	if (!container) {
		throw new Error("InversifyJS Container must be provided to <InversifyProvider> component");
	}

	const vmRef = useRef<T>(container.get<T>(identifier));

	useEffect(() => {
		const vm = vmRef.current;
		vm.init(param[0]);

		return () => {
			vm.dispose();
		};

		/**
		 * We don't want to call the "init" method one more time if parameter was changed.
		 * This effect should be called once, on a component initialization
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return vmRef.current;
}
