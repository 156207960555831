import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILeagueApiProvider, IMatchupTeam} from "data/providers/api/league.api.provider";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";

export interface IMatchupStore {
	fetchMatchups(round?: number): Promise<void>;

	getMatchupTeamById(teamId: number): IMatchupTeam | undefined;

	get isLoading(): boolean;

	get isMatchupsExists(): boolean;
}

@injectable()
export class MatchupStore implements IMatchupStore {
	@observable private _matchupTeams: IMatchupTeam[] = [];

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.LeagueApiProvider) private _leagueApiProvider: ILeagueApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isMatchupsExists(): boolean {
		return this._matchupTeams.length > 0;
	}

	public getMatchupTeamById(teamId: number): IMatchupTeam | undefined {
		return this._matchupTeams.find((e) => e.userId === teamId);
	}

	@action
	public fetchMatchups(round?: number): Promise<void> {
		if (!this._leaguesStore.selectedLeague) {
			return Promise.reject("No selected league found");
		}

		this.changeLoadingState(true);
		return this._leagueApiProvider
			.fetchMatchups({
				roundId: round,
				leagueId: this._leaguesStore.selectedLeague?.id,
			})
			.then((response) => {
				this._matchupTeams = response.data.success.teams;
			})
			.catch((e) => {
				const error = e as AxiosError<IAxiosApiError, unknown>;
				this._modalsStore.showAxiosError(error);
			})
			.finally(() => {
				this.changeLoadingState(false);
			});
	}

	@action
	private changeLoadingState(value: boolean): void {
		this._isLoading = value;
	}
}
