import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IRank, IRankingsApiProvider} from "data/providers/api/rankings.api.provider";

export interface IFetchRankings {
	page?: number;
	round?: number;
}

export interface IRankingsStore {
	get nextPage(): boolean;
	get rankingsUsers(): IRank[];
	get userRank(): IRank | null;
	fetchRankings: (round: number, firstPage?: boolean) => void;
	loadMore: (round: number) => void;
	get isRequestInProgress(): boolean;
}

@injectable()
export class RankingsStore implements IRankingsStore {
	@observable private _page: number = 1;
	@observable private _fetching: boolean = false;
	@observable private _userRank: IRank | null = null;
	@observable private _rankingsUsers: IRank[] = [];
	@observable private _nextPage: boolean = false;

	constructor(
		@inject(Bindings.RankingsApiProvider) private _rankingsApiProvider: IRankingsApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get nextPage() {
		return this._nextPage;
	}

	get rankingsUsers() {
		return this._rankingsUsers;
	}

	get userRank(): IRank | null {
		return this._userRank;
	}

	@action
	async fetchRankings(round: number, firstPage = true) {
		if (this._fetching) {
			return;
		}

		// Clear all if load ladders from 1st page (when first load or round/overall select change)
		if (firstPage) {
			this._page = 1;
			this._rankingsUsers = [];
			this._userRank = null;
		}
		this._fetching = true;

		const payload: IFetchRankings = {
			page: this._page,
			round: round === 0 ? undefined : round,
		};

		const response = await this._rankingsApiProvider.rankings(payload);

		runInAction(() => {
			this._rankingsUsers = [...this._rankingsUsers, ...response.data.success.rankings];
			this._nextPage = response.data.success.nextPage;
			this._userRank = response.data.success.user;
			this._page = this._page + 1;
			this._fetching = false;
		});
	}

	@action
	async loadMore(round: number) {
		await this.fetchRankings(round, false);
	}

	get isRequestInProgress() {
		return this._fetching;
	}
}
