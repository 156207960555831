/*
 	Generates array of round indexes according to round number and length
 	For example:
 	round = 26, length = 3; result: [24, 25, 26]
 	round = 26, length = 4; result: [23, 24, 25, 26]
 */

import {H2HFinalType} from "data/enums";

export function generateFinalsArrayAccordingFinalRound(round: number, length: 3 | 4) {
	return Array(length)
		.fill(round)
		.map((roundNumber, index) => roundNumber - index)
		.reverse();
}

export function generateFinalsNamesAccordingFinalRound(round: number) {
	return {
		[H2HFinalType.Top4]: {
			[round - 2]: "Finals (Week 1)",
			[round - 1]: "Finals (Week 2)",
			[round]: "Grand Final",
		},
		[H2HFinalType.Top8]: {
			[round - 3]: "Finals (Week 1)",
			[round - 2]: "Finals (Week 2)",
			[round - 1]: "Semi Finals",
			[round]: "Grand Final",
		},
	};
}
