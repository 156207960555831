import {ViewController} from "data/types/structure";
import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface IHelpListController extends ViewController {
	get currentTab(): string;
	setCurrentTab: (tab: string) => void;
	isCurrentTab: (tab: string) => boolean;
}

@injectable()
export class HelpListController implements IHelpListController {
	@observable private _currentTab = "";

	constructor() {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	get currentTab() {
		return this._currentTab;
	}

	isCurrentTab = (tab: string) => {
		return tab === this._currentTab;
	};

	@action
	setCurrentTab = (tab: string) => {
		this._currentTab = tab;
	};
}
