import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable} from "mobx";
import type {ITitleStore} from "data/stores/title/title.store";

export interface INotFoundController extends ViewController {
	title: string;
}

@injectable()
export class NotFoundController implements INotFoundController {
	constructor(@inject(Bindings.TitleStore) public _titleStore: ITitleStore) {
		makeAutoObservable(this);
	}

	get title() {
		return this._titleStore.title;
	}
	dispose(): void {
		return;
	}

	init() {
		this._titleStore.setTitle("Not Found");
	}
}
