import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ILeague} from "data/providers/api/league.api.provider";
import type {ITitleStore} from "data/stores/title/title.store";
import React from "react";

export interface IJoinLeagueController extends ViewController<IInit> {
	page: number;
	search: string;
	get leagues(): ILeague[];
	get nextPage(): boolean;
	handleLoadMore: (event: React.MouseEvent<HTMLButtonElement>) => void;
	handleJoinLeague: (event: React.MouseEvent<HTMLButtonElement>, code: string) => void;
	handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IInit {
	leagueCode: string;
}

@injectable()
export class JoinLeagueController implements IJoinLeagueController {
	@observable page = 1;
	@observable search = "";

	get leagues() {
		return this._leaguesStore.list;
	}

	get nextPage() {
		return this._leaguesStore.nextPage;
	}

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	@action handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		this.page++;

		void this._leaguesStore.fetchMoreLeagues({page: this.page});
	};

	@action handleJoinLeague = (event: React.MouseEvent<HTMLButtonElement>, code: string) => {
		event.preventDefault();

		void this._leaguesStore.joinLeague(code);
	};

	@action handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		void this._leaguesStore.fetchLeagues({search: event.target.value});
	};

	dispose(): void {
		return;
	}

	init({leagueCode}: IInit): void {
		void this._titleStore.setTitle("Join A League");
		if (leagueCode !== "") {
			void this._leaguesStore.joinLeague(leagueCode);
		}
		void this._leaguesStore.fetchLeagues({});
	}
}
