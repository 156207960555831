import {ViewController} from "data/types/structure";
import {INotification} from "data/types/global";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {INotificationService} from "data/services/Notification.service";
import {makeAutoObservable} from "mobx";

export interface INotificationController extends ViewController {
	showNotification: (notification: INotification) => void;
	closeNotification: (uuid?: string) => void;

	get notifications(): INotification[];
}

@injectable()
export class NotificationController implements INotificationController {
	constructor(
		@inject(Bindings.NotificationService) private _notificationService: INotificationService
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	showNotification(notification: INotification): void {
		this._notificationService.showNotification(notification);
	}

	closeNotification = (uuid?: string): void => {
		if (!uuid) {
			return;
		}
		this._notificationService.cancelNotification(uuid);
	};

	get notifications(): INotification[] {
		return this._notificationService.notifications;
	}
}
