import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IPreRegoPayload, type IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import React from "react";
import {AxiosError} from "axios";
import {
	extractErrorMessageFromAxiosResponse,
	getErrorMessageFromAxiosResponse,
	trackSentryErrors,
} from "data/utils";
import type {IAxiosApiError} from "data/types/global";
import {IApiResponse} from "data/services/http";

export interface IForm extends HTMLFormElement {
	email: HTMLInputElement;
	teamName?: HTMLInputElement;
}

interface IFormattedError {
	[key: string]: string;
}

export interface IOffSeasonController extends ViewController {
	get isPrivateLocked(): boolean;
	get error(): string | undefined;
	get formErrors(): Record<string, string>;
	get isFormLocked(): boolean;
	get formValidationHelper(): IFormValidationHelper;
	get requestState(): RequestState;
	get email(): string;
	get teamName(): string;
	get errorResponse(): IFormattedError;
	get isValidEmail(): boolean;
	handleSubmitForm: (event: React.SyntheticEvent<IForm>) => void;
	handleFormChange: (event: React.SyntheticEvent<IForm>) => void;
}

@injectable()
export class OffSeasonController implements IOffSeasonController {
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _error?: string = undefined;
	@observable private _email = "";
	@observable private _teamName = "";
	@observable private _isValidEmail = false;
	@observable private _errorResponse: IFormattedError = {};
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	get isPrivateLocked(): boolean {
		return this._userStore.isPrivateLocked;
	}

	get error() {
		return this._error;
	}

	get errorResponse() {
		return this._errorResponse;
	}
	get requestState() {
		return this._requestState;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get isFormLocked() {
		return this._requestState === RequestState.PENDING;
	}

	get formValidationHelper(): IFormValidationHelper {
		return this._validationHelper;
	}

	get email() {
		return this._email;
	}

	get teamName() {
		return this._teamName;
	}

	get isValidEmail() {
		return this._isValidEmail;
	}
	@action handleFormChange = (event: React.SyntheticEvent<IForm>) => {
		this._errorResponse = {};
		this._error = undefined;
		this._requestState = RequestState.IDLE;

		const form = event.currentTarget;

		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		const isValid = emailRegex.test(form.email.value);
		this._email = form.email.value;

		if (!isValid) {
			this._isValidEmail = false;
			return;
		}
		this._isValidEmail = true;
	};

	@action private onError = (error: AxiosError, form: IForm) => {
		trackSentryErrors(error, {}, "form login");
		this._requestState = RequestState.ERROR;

		const fields = ["email"];

		this._errorResponse = extractErrorMessageFromAxiosResponse(
			error as AxiosError<IApiResponse>,
			fields
		);

		this._error = getErrorMessageFromAxiosResponse(
			error as AxiosError<IAxiosApiError, unknown>
		);
	};

	@action handleSubmitForm = (event: React.SyntheticEvent<IForm>) => {
		event.preventDefault();
		this._error = undefined;
		const form = event.currentTarget;

		const email = form.email.value;

		this._requestState = RequestState.PENDING;

		this._userStore
			.preregister({email})
			.then((data) => this.onSuccess(data as IPreRegoPayload))
			.catch((err) => this.onError(err as AxiosError, form));
	};

	@action private onSuccess = (data: IPreRegoPayload) => {
		this._requestState = RequestState.SUCCESS;
		this._errorResponse = {};
		//this._email = data.email;
		//this._teamName = "";
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
