export const Bindings = {
	// Stores
	StaticContentStore: Symbol(),
	CountriesStore: Symbol(),
	LeaguesStore: Symbol(),
	UserStore: Symbol(),
	TitleStore: Symbol(),
	TeamStore: Symbol(),
	RoundsStore: Symbol(),
	ModalsStore: Symbol(),
	DriverStore: Symbol(),
	LockoutStore: Symbol(),
	GamebarStore: Symbol(),
	MatchupStore: Symbol(),
	RankingsStore: Symbol(),

	// Providers
	JSONProvider: Symbol(),
	AuthApiProvider: Symbol(),
	LeagueApiProvider: Symbol(),
	TeamApiProvider: Symbol(),
	ApiHTTPClient: Symbol(),
	JsonHTTPClient: Symbol(),
	RankingsApiProvider: Symbol(),

	// Services
	NotificationService: Symbol(),

	// Controllers
	AuthController: Symbol(),
	LoginController: Symbol(),
	MyAccountController: Symbol(),
	JoinLeagueController: Symbol(),
	MyLeaguesController: Symbol(),
	LeagueCoreController: Symbol(),
	LeagueAboutController: Symbol(),
	SettingsLeagueController: Symbol(),
	InviteLeagueController: Symbol(),
	LeagueTableController: Symbol(),
	NavController: Symbol(),
	HelpListController: Symbol(),
	HelpController: Symbol(),
	CreateLeagueController: Symbol(),
	FormValidationHelper: Symbol(),
	FormRegistrationController: Symbol(),
	ModalsController: Symbol(),
	FormLoginController: Symbol(),
	ForgotPasswordFormController: Symbol(),
	FormResetPasswordController: Symbol(),
	ContactUsController: Symbol(),
	MainLayoutController: Symbol(),
	NotFoundController: Symbol(),
	GameGateController: Symbol(),
	FieldController: Symbol(),
	DriverPoolController: Symbol(),
	PoolFiltersController: Symbol(),
	TeamController: Symbol(),
	GamebarController: Symbol(),
	DriverActionsController: Symbol(),
	FieldDriverController: Symbol(),
	FieldActionsBarController: Symbol(),
	NotificationController: Symbol(),
	DriverModalController: Symbol(),
	HeadsLabelController: Symbol(),
	CreateLeagueStepOneFormController: Symbol(),
	RemoveLeagueUserModalController: Symbol(),
	LeaveLeagueController: Symbol(),
	DeleteOrLeaveLeagueModalController: Symbol(),
	TeamSocialSharingController: Symbol(),
	NoHeadsSaveModalController: Symbol(),
	RoundSelectorController: Symbol(),
	LeagueH2HMatchUpController: Symbol(),
	MatchupCardController: Symbol(),
	LeagueH2hMatchupViewController: Symbol(),
	MatchupDriverToDriverController: Symbol(),
	TradeBarController: Symbol(),
	TradesActionsBarController: Symbol(),
	NotificationBarController: Symbol(),
	OffSeasonController: Symbol(),
	ModalUserExistsController: Symbol("ModalUserExistsController"),
	ModalTermsController: Symbol("ModalTermsController"),
	TeamStatsSelectController: Symbol("TeamStatsSelectController"),
	ModalTeamSavedController: Symbol("ModalTeamSavedController"),

	// Rankings
	RankingsController: Symbol(),
};
