import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router-dom";
import {isEqual, orderBy, snakeCase, sortBy} from "lodash";
import type {
	ISections,
	IStaticContentStore,
	TArticle,
} from "data/stores/static_content/static_content.store";
import type {ITitleStore} from "data/stores/title/title.store";

interface IControllerProps {
	navigate: ReturnType<typeof useNavigate>;
	pathname: string;
}

export interface IHelpController extends ViewController<IControllerProps> {
	updatePathname: (pathname: string) => void;
	buildPathName: (pathname: string) => string;
	isActiveTab: (tabId: number) => boolean;
	isContactUs: (tabId: number) => boolean;

	get tabs(): ISections["sections"];

	get contents(): TArticle[];

	get currentTab(): number | null;

	findSectionsById(sectionId: number): TArticle[];
}

@injectable()
export class HelpController implements IHelpController {
	private static rootPath = "/help";
	private _currentTab: number | null = null;
	private _navigate!: IControllerProps["navigate"];

	constructor(
		@inject(Bindings.StaticContentStore)
		public readonly _staticContentStore: IStaticContentStore,
		@inject(Bindings.TitleStore) public _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	get tabs() {
		return sortBy(this._staticContentStore.sections, "position");
	}

	get contents() {
		return this._staticContentStore.articles;
	}

	get currentTab() {
		return this._currentTab;
	}

	public findSectionsById(sectionId: number) {
		const sections = this.contents?.filter((article) => article.section_id === sectionId) ?? [];

		return orderBy(sections, ["position"], ["asc"]);
	}

	public updatePathname = (pathname: string) => {
		const newTab =
			this.tabs.find((it) => pathname.includes(this.buildPathName(it.name)))?.id ?? null;

		if (newTab) {
			this.setCurrentTab(newTab);
		}
	};

	public setCurrentTab = (value: number) => {
		this._currentTab = value;
	};

	public isActiveTab = (tab: number) => {
		return isEqual(this._currentTab, tab);
	};

	public isContactUs = (tabId: number) => {
		const sections = this.findSectionsById(tabId);

		return Boolean(sections.find((article) => article.name.toLowerCase().includes("contact")));
	};

	public buildPathName = (name: string) => {
		return snakeCase(name);
	};

	async init({navigate, pathname}: IControllerProps) {
		await this._staticContentStore.fetchStaticContent();
		this._navigate = navigate;
		this._titleStore.setTitle("Help");

		if (isEqual(pathname, HelpController.rootPath) && this.tabs.length > 0) {
			return navigate(this.buildPathName(this.tabs[0].name), {replace: true});
		}

		this.updatePathname(pathname);
	}

	dispose() {
		return;
	}
}
