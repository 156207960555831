import {action, computed, makeAutoObservable, observable} from "mobx";
import {FORM_VALIDATION_ELEMENT_CLASSNAME} from "data/constants";
import {filter, get, identity, isEmpty, values} from "lodash";
import {injectable} from "inversify";
import {DateTime} from "luxon";

type THTMLFormElements = HTMLInputElement | HTMLSelectElement;

interface IErrorDictionary {
	required: string;
	default: string;
	password_mismatch: string;
	byFieldName: {
		terms: string;
		teamName: string;
		firstName: string;
		lastName: string;
		email: string;
		password: string;
		confirmPassword: string;
	};
}

export interface IFormValidationHelper {
	get formErrors(): Record<string, string>;
	get isValid(): boolean;
	get errors(): IErrorDictionary;

	clearFormFieldError: (fieldName: string) => void;
	setFormFieldError: (fieldName: string, error?: string) => void;
	checkValidity: (form: HTMLFormElement) => boolean;
}

@injectable()
export class FormValidationHelper implements IFormValidationHelper {
	@observable private _formErrors: Record<string, string> = {};

	constructor() {
		makeAutoObservable(this);
	}

	get formErrors() {
		return this._formErrors;
	}

	@computed get isValid() {
		return isEmpty(filter(values(this._formErrors), identity));
	}

	@computed public get errors() {
		const errorMsgPasswordInvalid =
			"Please enter a valid password with a minimum of 8 characters including one number and one capital letter";

		return {
			required: "This field is required",
			default: "Please fill-in a correct value",
			password_mismatch: "The provided passwords do not match",
			byFieldName: {
				terms: "Please accept terms & conditions",
				teamName:
					"Team name must be alphanumeric,  maximum 20 characters length without @ and bad words",
				firstName: "First name must contains at least 2 letters and no longer than 100",
				lastName: "Last name must contains at least 2 letters and no longer than 100",
				email: "The value must be a valid email",
				confirmEmail: "The emails provided must match",
				password: errorMsgPasswordInvalid,
				confirmPassword: errorMsgPasswordInvalid,
				oldPassword: errorMsgPasswordInvalid,
				postcode: "Invalid postcode",
				dob: "Invalid date",
			},
		};
	}

	@action clearFormFieldError = (fieldName: string) => {
		this.setFormFieldError(fieldName);
	};

	@action setFormFieldError = (fieldName: string, error = "") => {
		this._formErrors[fieldName] = error;
	};

	checkValidity = (form: HTMLFormElement) => {
		const fields = Array.from(
			form.getElementsByClassName(FORM_VALIDATION_ELEMENT_CLASSNAME)
		) as unknown as THTMLFormElements[];

		fields.forEach((field) => {
			const emailValue = get(fields, "[3].value", "") as string;
			this.checkRequired(field);
			this.checkValid(field);
			this.checkConfirmEmail(field, emailValue);
			this.checkDateOfBirth(field);
			this.checkTeamName(field);
			this.checkPostcode(field);
		});
		return this.isValid;
	};

	private checkRequired(field: THTMLFormElements): boolean {
		const {type, name, value} = field;
		const isCheckbox = type === "checkbox";
		const hasValue = isCheckbox ? (field as HTMLInputElement).checked : value;
		if (field.hasAttribute("required") && !hasValue) {
			this.setFormFieldError(name, this.errors.required);
			return false;
		}
		return true;
	}

	private checkTeamName(field: THTMLFormElements): boolean {
		if (field.name !== "teamName") {
			return true;
		}

		const isValid = /^[A-Za-z0-9\s]{1,20}$/.test(field.value);
		if (!isValid) {
			this.setFormFieldError(field.name, this.errors.byFieldName.teamName);
			return false;
		}
		return true;
	}

	private checkPostcode(field: THTMLFormElements): boolean {
		if (field.name !== "postcode") {
			return true;
		}

		const isValid = /^\d{4}$/gi.test(field.value);
		if (!isValid) {
			this.setFormFieldError(field.name, this.errors.byFieldName.postcode);
		}

		return isValid;
	}

	private checkValid(field: THTMLFormElements): boolean {
		if (!field.validity.valid) {
			const msg = get(this.errors.byFieldName, field.name, this.errors.default);
			this.setFormFieldError(field.name, msg);
			return false;
		}
		return true;
	}

	private checkConfirmEmail(field: THTMLFormElements, emailValue: string): boolean {
		const {name, value} = field;
		if (name === "confirmEmail" && value !== emailValue) {
			this.setFormFieldError("confirmEmail", this.errors.byFieldName["confirmEmail"]);
			return false;
		}
		return true;
	}

	private checkDateOfBirth(field: THTMLFormElements) {
		const {name, value} = field;
		if (name !== "dob") {
			return true;
		}

		const dateTime = DateTime.fromFormat(value, "yyyy-LL-dd");
		if (dateTime.startOf("day") > DateTime.now().startOf("day")) {
			this.setFormFieldError("dob", this.errors.byFieldName["dob"]);
			return false;
		}
		return true;
	}
}
