import styled from "@emotion/styled";
import React from "react";

const Wrapper = styled.div`
	max-width: 300px;
	width: 100%;
	height: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const AdWidgetMRECComponent: React.FC = () => {
	return <Wrapper />;
};

export const AdWidgetMREC = React.memo(AdWidgetMRECComponent);
