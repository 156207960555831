import React from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {ModalType} from "data/enums";
import {Modal} from "@mui/material";
import {ModalBox, ModalContent} from "views/components/modals/modal_common.component";
import {observer} from "mobx-react";
import CloseIcon from "@mui/icons-material/Close";
import {ButtonPrimary, ModalCloseButton} from "views/components/button";
import styled from "@emotion/styled";
import {isEqual} from "lodash";
import {ForgotPasswordFormComponent} from "views/components/forms/forgot_password_form/forgot_password_form.component";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/Exist";

const SModalContent = styled(ModalContent)`
	max-width: 340px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 56px;
	gap: 8px;
	text-align: center;
`;

const Title = styled.h3`
	font-family: var(--fontFamilyBase);
	color: var(--primaryTextColor);
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.2px;
`;

const Text = styled.p`
	font-family: var(--fontFamilyBase);
	color: var(--primaryTextColor);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.02em;
	padding: 0 20px;
	margin-bottom: 10px;
`;

const SubmitButton = styled(ButtonPrimary)`
	height: 52px;
	width: 100%;
`;

export const ModalForgotPassword: React.FC = observer(() => {
	const {visibleModal, close} = useViewController<IModalsController>(Bindings.ModalsController);
	const isVisible = [ModalType.FORGOT_PASSWORD, ModalType.FORGOT_PASSWORD_CONFIRM].includes(
		visibleModal!
	);

	return (
		<Modal open={isVisible} onClose={close}>
			<ModalBox>
				<SModalContent>
					<ModalCloseButton onClick={close}>
						<CloseIcon />
					</ModalCloseButton>
					<Exist when={isEqual(visibleModal, ModalType.FORGOT_PASSWORD)}>
						<ForgotPasswordFormComponent />
					</Exist>

					<Exist when={isEqual(visibleModal, ModalType.FORGOT_PASSWORD_CONFIRM)}>
						<Title>Email sent</Title>
						<Text>
							A link has been sent to your email address to reset your password. If it
							was not received please contact us or try with a different email
							address.
						</Text>
						<SubmitButton onClick={close}>Ok</SubmitButton>
					</Exist>
				</SModalContent>
			</ModalBox>
		</Modal>
	);
});
