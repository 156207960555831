import styled from "@emotion/styled";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import {Box} from "@mui/material";

export const ModalBox = styled(Box)`
	background: #ffff;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	max-width: 600px;
	min-width: 320px;

	@media (max-width: 860px) {
		max-width: 360px;
	}
`;

export const ModalContent = styled(Box)`
	padding: 20px;
	max-height: 85vh;
	overflow: auto;

	// FIX for iOS
	@supports (-webkit-touch-callout: none) {
		max-height: 75vh;
	}
`;

export const ModalErrorIcon = styled(ErrorSharpIcon)`
	width: 40px;
	height: 40px;
	margin: 16px auto;
	color: var(--iconErrorColor);
`;

export const ModalTitle = styled.h3`
	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.2px;
	color: ${({theme}) => theme.colors.black};
`;

export const ModalDescription = styled.p`
	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.02em;
	color: ${({theme}) => theme.colors.black};

	b {
		font-weight: 700;
	}
`;

export const ModalIconCloseButtonPosition = styled.div`
	position: absolute;
	right: 5px;
	top: 5px;
`;

export const ModalContentCustom = styled(ModalContent)`
	padding: 35px 20px;
`;
