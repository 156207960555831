import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {IDriver, IDriverPriceChange, IPlayerPoolFilters} from "data/types/team";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider, IRound} from "data/providers/json/json.provider";
import {DriverPriceChange, DriverStatus, RoundStatus, TeamPosition} from "data/enums";
import {DriverFiltersService} from "data/services/DriverFilters.service";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {get, keyBy, orderBy} from "lodash";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {getPreparedDriverCost} from "data/utils";

interface IPoints {
	driver: IDriver;
	round?: IRound;
}

export interface IDriverStore {
	get drivers(): IDriver[];

	getSortedDrivers(salaryCap: number): IDriver[];

	get isLoading(): boolean;

	get poolFilters(): IPlayerPoolFilters;

	getDriverById(driverId: number | undefined | null): IDriver | undefined;

	fetchDrivers(): void;

	updatePoolRegion(region: TeamPosition): void;

	updatePoolFilters(filters: IPlayerPoolFilters): void;

	setDefaultFilters(): void;

	getPoints({driver, round}: IPoints): number | string;

	getDriverPriceChanges(driverId: number, currentRound: number): IDriverPriceChange | undefined;
}

const defaultFilers: IPlayerPoolFilters = {
	search: "",
	region: "all",
	price: "all",
	totalPoints: "all",
};

@injectable()
export class DriversStore implements IDriverStore {
	@observable private _drivers: IDriver[] = [];
	@observable private _isLoading: boolean = false;
	@observable private _poolFilters: IPlayerPoolFilters = defaultFilers;

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	public get drivers(): IDriver[] {
		return this._drivers;
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public get poolFilters(): IPlayerPoolFilters {
		return this._poolFilters;
	}

	get getDriversById() {
		return keyBy(this._drivers, "id");
	}

	public setDefaultFilters() {
		this._poolFilters = defaultFilers;
	}

	public getSortedDrivers(salaryCap: number): IDriver[] {
		const filteredDrivers = this.drivers
			.filter(this.filterBySearch)
			.filter(this.filterByRegion)
			.filter((driver) =>
				DriverFiltersService.filterByPrice(driver, salaryCap, this._poolFilters.price)
			)
			.filter((driver) =>
				DriverFiltersService.filterByPoints(driver, this._poolFilters.totalPoints)
			)
			.filter(this.filterEliminated);

		return orderBy(filteredDrivers, "cost", "desc");
	}

	@action
	public async fetchDrivers() {
		if (this._isLoading) return;

		try {
			this._isLoading = true;
			const {data} = await this._jsonProvider.fetchDrivers();

			runInAction(() => {
				this._drivers = data;
			});
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	@action
	public updatePoolFilters(filters: IPlayerPoolFilters) {
		this._poolFilters = filters;
	}

	@action
	public updatePoolRegion(region: TeamPosition) {
		this._poolFilters.region = region;
	}

	public getDriverById(driverId: number | undefined | null): IDriver | undefined {
		return driverId ? this.getDriversById[driverId] : undefined;
	}

	public getPoints({driver, round}: IPoints) {
		const scoreRound = round || this._roundsStore.scoreRound;

		if (!scoreRound) {
			return "-";
		}
		const noPoints = scoreRound.status === RoundStatus.Complete ? "DNP" : "-";
		const roundId = scoreRound?.id;

		const points = get(driver, `points.rounds.${roundId || 0}`, noPoints);
		return roundId ? points : "-";
	}

	public getDriverPriceChanges(driverId: number, currentRound: number) {
		const driver = this.getDriverById(driverId);
		if (!driver) {
			return undefined;
		}

		const currentCost = driver.stats.prices[currentRound] || driver.cost;
		const lastCost = driver.stats.prices[currentRound - 1];

		if (!lastCost) {
			return undefined;
		}

		let direction = lastCost < currentCost ? DriverPriceChange.Up : DriverPriceChange.Down;
		direction = lastCost === currentCost ? DriverPriceChange.Same : direction;
		const change = getPreparedDriverCost(Math.abs(lastCost - currentCost));

		return {
			direction,
			change: change || undefined,
			currentCost,
			lastCost,
		};
	}

	private filterBySearch = (driver: IDriver): boolean => {
		if (this._poolFilters.search === "") {
			return true;
		}
		const fullName = driver.firstName + " " + driver.lastName;
		return fullName.toLowerCase().includes(this._poolFilters.search.toLowerCase());
	};

	private filterByRegion = (driver: IDriver): boolean => {
		if (this._poolFilters.region === "all" || !this.poolFilters.region) {
			return true;
		}

		return driver.regionId === this._poolFilters.region;
	};

	private filterEliminated = (driver: IDriver): boolean => {
		return driver.status !== DriverStatus.Eliminated;
	};
}
