interface IMediaPoints {
	large_desktop: string;
	med_desktop: string;
	desktop: string;
	tablet: string;
	mobile: string;
	small_mobile: string;
}
export const media: IMediaPoints = {
	large_desktop: "1200px",
	med_desktop: "1040px",
	desktop: "960px",
	tablet: "768px",
	mobile: "640px",
	small_mobile: "440px",
};

export default media;
