import React, {ElementType} from "react";
import {observer} from "mobx-react";
import {
	Checkbox as MUICheckbox,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputBaseComponentProps,
	InputLabel,
	MenuItem,
	Modal,
	OutlinedInput,
	Select,
} from "@mui/material";
import {CheckboxLabelTypography} from "views/components/forms/common/forms_common.component";
import {ModalBox, ModalContent} from "views/components/modals/modal_common.component";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router-dom";
import type {IModalTermsController} from "views/components/modals/modal_terms/modal_terms.controller";
import styled from "@emotion/styled";
import {ButtonPrimary} from "views/components/button";
import {PhoneField} from "views/components/PhoneField/phoneField.component";
import {Exist} from "views/components/Exist";
import {FORM_VALIDATION_ELEMENT_CLASSNAME, POSTCODE_COUNTRY} from "data/constants";
import media from "assets/css/media";

const SModalBox = styled(ModalBox)`
	max-width: 420px;

	.MuiFormControlLabel-root {
		width: 100%;
	}
`;

const SModalContent = styled(ModalContent)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;

	> form {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 12px;
	}
`;

const FormTitle = styled.div`
	margin: 10px auto;
	font-size: 16px;
	text-align: center;
	line-height: 20px;
	@media (max-width: ${media.mobile}) {
		font-size: 14px;
	}
`;

export const ModalTerms: React.FC = observer(() => {
	const navigate = useNavigate();
	const {
		isOpen,
		isChecked,
		isLoading,
		phoneError,
		countryList,
		handleFormSubmit,
		handleFormChange,
	} = useViewController<IModalTermsController>(Bindings.ModalTermsController, {navigate});

	const currentURL = window.location.href;
	const isNotTermPage = currentURL.indexOf("terms_conditions") === -1;

	return (
		<Modal open={isOpen && isNotTermPage}>
			<SModalBox>
				<SModalContent>
					<form onSubmit={handleFormSubmit} onChange={handleFormChange}>
						<FormTitle>
							Welcome back to Fantasy Harness Racing! Please fill in the below fields
							to start selecting your team for Season 2.
						</FormTitle>
						<FormControl fullWidth>
							<InputLabel htmlFor="country">Country</InputLabel>
							<Select
								id="country"
								name="country"
								disabled={!countryList.length}
								defaultValue={POSTCODE_COUNTRY}
								label="Country"
								placeholder="Country"
								required={true}>
								{countryList.map((country, index) => (
									<MenuItem key={index} value={country.code}>
										{country.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl fullWidth variant="outlined">
							<InputLabel htmlFor="phone">
								<span>Phone Number</span>
							</InputLabel>
							<OutlinedInput
								id="phone"
								name="phone"
								label="Phone number"
								placeholder="04XX-XXX-XXX"
								inputComponent={
									PhoneField as unknown as
										| ElementType<InputBaseComponentProps>
										| undefined
								}
							/>
							<Exist when={!phoneError}>
								<FormHelperText>
									This will be used to contact you if you are a winner
								</FormHelperText>
							</Exist>
							<Exist when={phoneError}>
								<FormHelperText error>Invalid phone number</FormHelperText>
							</Exist>
						</FormControl>

						<FormControlLabel
							labelPlacement="end"
							sx={{alignItems: "flex-start"}}
							control={
								<MUICheckbox
									id="isDriver"
									name="isDriver"
									inputProps={{className: FORM_VALIDATION_ELEMENT_CLASSNAME}}
								/>
							}
							label={
								<CheckboxLabelTypography
									sx={{marginTop: "10px"}}
									component="span"
									display="inline-block"
									dangerouslySetInnerHTML={{
										__html: `I am a driver in Harness Racing NSW`,
									}}
								/>
							}
						/>

						<FormControlLabel
							labelPlacement="end"
							sx={{alignItems: "flex-start"}}
							control={
								<Checkbox
									checked={isChecked}
									id="terms"
									name="terms"
									required={true}
								/>
							}
							label={
								<CheckboxLabelTypography
									sx={{marginTop: "10px"}}
									component="span"
									display="inline-block"
									dangerouslySetInnerHTML={{
										__html: `By selecting this box I agree to the <a href="/help/terms_conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions </a>`,
									}}
								/>
							}
						/>
						<ButtonPrimary type="submit" disabled={!isChecked || isLoading} fullWidth>
							Confirm
						</ButtonPrimary>
					</form>
				</SModalContent>
			</SModalBox>
		</Modal>
	);
});
