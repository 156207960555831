import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useViewController} from "data/services/locator";
import {INotificationBarController} from "views/components/notifications_bar/notification_bar.controller";
import {Bindings} from "data/constants/bindings";

const Bar = styled.div`
	width: 100%;
	padding: 10px;
	background: var(--secondaryColor);
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--primaryTextColor);
	border-bottom: 4px solid var(--primaryColor);
	font-weight: 600;
	min-height: 50px;

	p {
		font-size: 14px;
		font-family: var(--fontFamilyBase);

		strong {
			font-weight: 700;
		}

		i {
			font-style: italic;
		}
	}

	a {
		text-decoration: underline;
	}
`;

export const NotificationBar: React.FC = observer(() => {
	const {isVisible, message} = useViewController<INotificationBarController>(
		Bindings.NotificationBarController
	);

	if (!isVisible) {
		return null;
	}

	return <Bar dangerouslySetInnerHTML={{__html: message}} />;
});
