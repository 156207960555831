import React, {Fragment} from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {useViewController} from "data/services/locator";
import {observer} from "mobx-react";
import {Bindings} from "data/constants/bindings";
import {IJoinLeagueController} from "./join_leagues.controller";
import {Exist} from "views/components/Exist";
import styled, {css} from "styled-components";
import {LeaguePrivacy, LeagueStatus, LeagueType} from "data/enums";
import {ButtonPrimary, LoadMoreButton} from "views/components/button";
import {TwoColsLayoutWithContent} from "views/components/main_layout";
import {
	CreateLeagueButton,
	LeagueButtonsDescription,
	LeagueButtonsWrapper,
	LeagueName,
} from "views/pages/leagues/league/LeaguesStyles";
import {ReactComponent as MyEmptyLeaguesImg} from "assets/img/my-empty-leagues.svg";
import media from "assets/css/media";
import {useParams} from "react-router-dom";
import {ILeagueCodeRouteParams} from "data/types/global";

const Wrapper = styled.div`
	@media (max-width: ${media.mobile}) {
		padding: 10px;
	}
`;

const FlexCol = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	gap: 20px;
	width: 100%;
	@media (max-width: ${media.mobile}) {
		padding: 10px;
	}
`;
const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	height: 144px;
	box-shadow: 0px 0.1px 0.3px rgba(28, 28, 30, 0.1), 0px 1px 2px rgba(28, 28, 30, 0.2);
`;

const RowContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
`;

const BoldContent = styled.h2`
	font-family: var(--fontFamilyBase);
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	color: #151515;
`;

const NormalContent = styled.p`
	font-family: var(--fontFamilyBase);
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: #151515;
`;

const LastColumn = styled.div`
	padding-right: 20px;
	@media (max-width: ${media.mobile}) {
		padding: 0;
	}
`;

export interface ILeague {
	id: number;
	name: string;
	userId: number;
	code: string;
	privacy: LeaguePrivacy | string;
	status: LeagueStatus | string;
	type: LeagueType | string;
	startRound: number;
	description: string;
	teamCount: number;
	userName?: string | null;
}

export const EMPTY_LEAGUE_DATA = {
	id: 1,
	name: "",
	userId: 0,
	code: "",
	privacy: LeaguePrivacy.Private,
	status: LeagueStatus.Scheduled,
	type: LeagueType.Regular,
	startRound: 0,
	description: "",
	teamCount: 0,
	class: LeagueType.Regular,
	userName: "",
};
export interface IMyLeague extends ILeague {
	rank?: number | null;
	last_event_rank?: number | null;
	is_sponsored?: boolean;
	isJoined?: boolean;
	teamName?: string;
}

interface ILeagueRowProps extends IMyLeague {
	empty?: boolean;
	handleJoinLeague: (event: React.MouseEvent<HTMLButtonElement>, code: string) => void;
}

interface ITableRowProps {
	isSponsored?: boolean;
	empty?: boolean;
}

const getTableRowStyles = ({isSponsored}: ITableRowProps) =>
	isSponsored
		? "border-bottom: 1px solid #312daf; background: #bab9dc;"
		: `
			border-bottom: 1px solid #e1e1e1;
			background: #fff;
		  `;

const TableRow = styled(Row)<ITableRowProps>`
	color: #1e1450;
	${getTableRowStyles};
	${({empty}) =>
		empty &&
		`
		height: 200px;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	`}
`;

const ButtonJoinLeague = styled(ButtonPrimary)`
	width: 86px;
	padding-left: 0;
	padding-right: 0;
`;

const SearchBar = styled.div`
	margin-top: 20px;
	width: 100%;
	height: 56px;
	@media (max-width: ${media.mobile}) {
		margin-top: 0;
	}
`;

const FlexDiv5 = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
`;

const Input = styled(TextField)`
	width: 100%;

	.MuiInputBase-root {
		background: transparent;
	}
`;

export const contentBubbleStyles = (
	type?: LeagueType | string,
	privacy?: LeaguePrivacy | string
) => {
	if (type === LeagueType.H2H) {
		return css`
			background: rgba(33, 150, 243, 0.1);
			color: #2196f3;
			text-transform: uppercase;
		`;
	}
	if (type === LeagueType.Regular) {
		return css`
			background: rgba(24, 201, 113, 0.1);
			color: #18c971;
		`;
	}
	if (privacy === LeaguePrivacy.Private) {
		return css`
			background: rgba(33, 150, 243, 0.1);
			color: #2196f3;
		`;
	}
	if (privacy === LeaguePrivacy.Public) {
		return css`
			background: rgba(24, 201, 113, 0.1);
			color: #18c971;
		`;
	}
	return css``;
};

const ContentBubble = styled.div<{
	type?: LeagueType | string;
	privacy?: LeaguePrivacy | string;
}>`
	height: 24px;
	width: 51px;
	border-radius: 51px;
	padding: 4px 8px;
	font-family: var(--fontFamilyBase);
	font-weight: 600;
	font-size: 11px;
	line-height: 14px;
	text-transform: capitalize;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	${({type, privacy}) => contentBubbleStyles(type, privacy)};
`;

interface ILeaguePrivacyComp {
	type: LeagueType | string;
	privacy: LeaguePrivacy | string;
}
export const LeaguePrivacyType: React.FC<ILeaguePrivacyComp> = ({type, privacy}) => {
	return (
		<FlexDiv5>
			<ContentBubble privacy={privacy}>{privacy}</ContentBubble>
			<ContentBubble type={type}>{type}</ContentBubble>
		</FlexDiv5>
	);
};

const LeagueRow: React.FC<ILeagueRowProps> = ({
	name,
	teamCount,
	code,
	is_sponsored,
	isJoined,
	empty,
	userName,
	privacy,
	handleJoinLeague,
	type,
}) => {
	if (empty) {
		return (
			<TableRow empty={true}>
				<MyEmptyLeaguesImg />
				<NormalContent>
					Unfortunately we could not find any leagues that match your description at this
					time
				</NormalContent>
			</TableRow>
		);
	}
	return (
		<TableRow isSponsored={is_sponsored}>
			<RowContent>
				<LeaguePrivacyType privacy={privacy} type={type} />
				<BoldContent>{name}</BoldContent>
				<FlexDiv5>
					<BoldContent>Manager:</BoldContent>
					<NormalContent>{userName}</NormalContent>
				</FlexDiv5>
				<NormalContent>
					{teamCount} {teamCount > 1 ? "Entries" : "Entry"}
				</NormalContent>
			</RowContent>

			<LastColumn>
				<Exist when={!isJoined}>
					<ButtonJoinLeague
						onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
							handleJoinLeague(event, code)
						}>
						<span>Join</span>
					</ButtonJoinLeague>
				</Exist>
				<Exist when={Boolean(isJoined)}>
					<ButtonJoinLeague disabled={true}>
						<span>Joined</span>
					</ButtonJoinLeague>
				</Exist>
			</LastColumn>
		</TableRow>
	);
};

export const JoinLeague: React.FC = observer(() => {
	const {leagueCode = ""} = useParams<ILeagueCodeRouteParams>();

	const {leagues, nextPage, handleLoadMore, handleSearch, handleJoinLeague} =
		useViewController<IJoinLeagueController>(Bindings.JoinLeagueController, {
			leagueCode: leagueCode,
		});

	return (
		<Fragment>
			<TwoColsLayoutWithContent
				mainContent={
					<FlexCol>
						{leagues.map((league) => (
							<LeagueRow
								key={league.id}
								{...league}
								handleJoinLeague={handleJoinLeague}
							/>
						))}
						{!leagues.length && (
							<LeagueRow
								empty={true}
								{...EMPTY_LEAGUE_DATA}
								handleJoinLeague={handleJoinLeague}
							/>
						)}
						<Exist when={nextPage}>
							<LoadMoreButton onClick={handleLoadMore}>Load more</LoadMoreButton>
						</Exist>
					</FlexCol>
				}
				rightContent={
					<Wrapper>
						<SearchBar>
							<Input
								defaultValue=""
								onChange={handleSearch}
								label="Search for leagues"
								type="search"
								placeholder="Enter a League PIN"
								InputProps={{endAdornment: <SearchIcon />}}
							/>
						</SearchBar>
						<LeagueButtonsWrapper>
							<LeagueName>Create A League</LeagueName>
							<LeagueButtonsDescription>
								Can't find what you want? Why not create your own league.
							</LeagueButtonsDescription>
							<CreateLeagueButton to="/leagues/create">Create</CreateLeagueButton>
						</LeagueButtonsWrapper>
					</Wrapper>
				}
			/>
		</Fragment>
	);
});

export default JoinLeague;
