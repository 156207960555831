import styled from "@emotion/styled";
import {mobileViewQuery} from "data/constants";
import React from "react";

const Wrapper = styled.div`
	max-width: 728px;
	width: 100%;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	display: none;
	@media (${mobileViewQuery}) {
		width: 300px;
		height: 50px;
		align-self: center;
		margin: 10px 0;
	}
`;

const AdWidgetLongComponent: React.FC = () => {
	return <Wrapper />;
};

export const AdWidgetLong = React.memo(AdWidgetLongComponent);
