import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, computed, makeAutoObservable, observable} from "mobx";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ILadderUser, ILeague} from "data/providers/api/league.api.provider";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IDropdownRound, IRound} from "data/providers/json/json.provider";
import type {IUserStore} from "data/stores/user/user.store";
import type {ITitleStore} from "data/stores/title/title.store";
import React from "react";
import {LeagueType, RoundStatus} from "data/enums";
import {last} from "lodash";

interface IParams {
	leagueId: number;
}

export interface ILeagueTableController extends ViewController<IParams> {
	page: number;
	search: string;
	handleLoadMore: (event: React.MouseEvent<HTMLButtonElement>) => void;
	handleJoinLeague: (event: React.MouseEvent<HTMLButtonElement>, code: string) => void;
	handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleRoundChange: (round: number) => void;
	fetchLadder: () => void;
	loadMore: () => void;
	getIsWinner: (rank: number) => boolean;

	get leagues(): ILeague[];

	get nextPage(): boolean;

	get rankingsUser(): null | ILadderUser;

	get selectRounds(): IDropdownRound[];

	get rounds(): IRound[];

	get league(): ILeague | undefined;

	get selectedRound(): number;

	get ladderUsers(): ILadderUser[];

	get userId(): number;

	get isRequestInProgress(): boolean;

	get isScoringStarted(): boolean;

	get isUserRowSticky(): boolean;

	get rankKey(): string;

	get pointsKey(): string;
}

@injectable()
export class LeagueTableController implements ILeagueTableController {
	@observable page = 1;
	@observable search = "";
	@observable _leagueId = 0;

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@observable _selectedRound = 0;

	get selectedRound() {
		return this._selectedRound;
	}

	get leagues() {
		return this._leaguesStore.list;
	}

	get league() {
		return this._leaguesStore.selectedLeague;
	}

	get nextPage() {
		return this._leaguesStore.nextPage;
	}

	get rankingsUser(): null | ILadderUser {
		return this._leaguesStore.rankingsUser;
	}

	get rounds() {
		return this._roundsStore.list;
	}

	get selectRounds(): IDropdownRound[] {
		const rounds = this.rounds.filter((round) => {
			if (round.id < (this.league?.startRound || 0)) {
				return false;
			}
			if (round.status === RoundStatus.Playing) {
				return round.races[0].status === RoundStatus.Complete;
			}

			return round.status === RoundStatus.Complete;
		});
		if (this.isH2H) {
			return rounds.map((round) =>
				this._roundsStore.prepareRoundForDropdown(round, this.league)
			);
		}
		return rounds.map((round) => ({...round, name: `Round ${round.id}`}));
	}

	get ladderUsers() {
		return this._leaguesStore.ladderUsers;
	}

	get userId() {
		return this._userStore.user?.id || 1;
	}

	get isRequestInProgress() {
		return this._leaguesStore.isRequestInProgress;
	}

	@computed
	get isScoringStarted(): boolean {
		const startRound = this._roundsStore.list.find((e) => e.id === this.league?.startRound);
		if (!startRound) {
			return false;
		}
		if (startRound.status === RoundStatus.Playing) {
			return startRound.races[0].status === RoundStatus.Complete;
		}
		return startRound.status === RoundStatus.Complete;
	}

	get isUserRowSticky(): boolean {
		return !this.ladderUsers.some((e) => e.userId === this.userId);
	}

	get rankKey(): string {
		return this.selectedRound ? "rank" : "overallRank";
	}

	get pointsKey(): string {
		return this.selectedRound ? "points" : "overallPoints";
	}

	protected get isH2H(): boolean {
		return this.league?.type === LeagueType.H2H;
	}

	@action handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		this.page++;

		void this._leaguesStore.fetchMoreLeagues({page: this.page});
	};

	@action handleJoinLeague = (event: React.MouseEvent<HTMLButtonElement>, code: string) => {
		event.preventDefault();

		void this._leaguesStore.joinLeague(code);
	};

	@action handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		void this._leaguesStore.fetchLeagues({search: event.target.value});
	};

	@action handleRoundChange = (round: number) => {
		this._selectedRound = round;
		this.fetchLadder();
	};

	@action fetchLadder = () => {
		this._leaguesStore.fetchLadder(this.selectedRound);
	};

	@action loadMore = () => {
		this._leaguesStore.loadMoreLadder(this.selectedRound);
	};

	dispose(): void {
		return;
	}

	init(params: IParams): void {
		this.setTitle();
		this._leagueId = params.leagueId;
		void this._roundsStore.fetchRounds();
	}

	setTitle() {
		if (!this._leaguesStore.selectedLeague) {
			return;
		}
		void this._titleStore.setTitle(this._leaguesStore.selectedLeague?.name);
	}

	getIsWinner = (rank: number): boolean => {
		const isH2h = this.league?.type === LeagueType.H2H;
		const isLast = this._selectedRound === last(this._roundsStore.list)?.id;
		return !(rank !== 1 || !isH2h || !isLast);
	};
}
