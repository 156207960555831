import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {TeamPosition} from "data/enums";
import {generateFinalsNamesAccordingFinalRound} from "data/utils/finals";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const STATIC_CONTENT_JSON_URL = process.env.REACT_APP_STATIC_CONTENT_JSON_URL || "";
export const PRIZE_ARTICLE_ID = parseInt(process.env.REACT_APP_PRIZE_ARTICLE_ID || "");
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || "development";
export const FORM_VALIDATION_ELEMENT_CLASSNAME = "form-field-validate" as const;
export const EMAIL_REGEXP_STR = "\\S+@\\S+\\.\\S+";
export const PASSWORD_REGEXP_STR = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[0-9a-zA-Z!@#$%^&*]{8,128}$";
export const SITE_URL = process.env.REACT_APP_SITE_URL || window.location.origin || "";
export const IS_LADDER_STARTED = JSON.parse(
	process.env.REACT_APP_IS_LADDER_STARTED || "false"
) as boolean;

const UAT_DOMAIN = "https://harness-racing-nsw-fantasy.uat.f2p.media.geniussports.com";
const PREPROD_DOMAIN = "https://harness-racing-nsw-fantasy.preprod.f2p.media.geniussports.com";
const PROD_DOMAIN = "https://fantasyharnessracing.com";
export const SENTRY_CONFIG = {
	dsn: "https://84e86e3066394e4bb509a4a5e4086488@o151969.ingest.sentry.io/4503995923759104",
	integrations: [
		new BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	environment: SENTRY_ENV,
	ignoreErrors: ["League request in process"],
	allowUrls: [UAT_DOMAIN, PREPROD_DOMAIN, PROD_DOMAIN, "geniussports.com"],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	sampleRate: 0.1,
};

export const mobileViewQuery = `max-width: 730px`;
export const SALARY_CAP = Number(process.env.REACT_APP_SALARY_CAP) || 0;

export const PRICES_FILTER_MAPPER = [
	{name: "Only Affordable", value: "affordable"},
	{name: "Over $1,000,000", value: "over 1000"},
	{name: "$700,000 - $1,000,000", value: "700-1000"},
	{name: "$500,000 - $700,000", value: "500-700"},
	{name: "$300,000 - $500,000", value: "300-500"},
	{name: "Under $300,000", value: "under 300"},
];

export const POINTS_FILTER_MAPPER = [
	{name: "Over 100 points", value: "over 100"},
	{name: "50 - 100 points", value: "50-100"},
	{name: "20 - 50 points", value: "20-50"},
	{name: "10 - 20 points", value: "10-20"},
	{name: "Under 10 points", value: "under 10"},
];

export const REGIONS_FILTER_MAPPER = [
	TeamPosition.Hunter,
	TeamPosition.Western,
	TeamPosition.Riverina,
	TeamPosition.Metropolitan,
];

export const H2H_TEAMS_STEP = 2;
export const H2H_MINIMUM_TEAMS = 4;
export const H2H_MAXIMUM_TEAMS = 20;
export const POSTCODE_COUNTRY = "AUS";
export const EMAIL_REGEXP = "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$";
export const AVAILABLE_TRADES_COUNT = 2;

export const IS_PRESEASON = JSON.parse(process.env.REACT_APP_IS_PRE_SEASON || "false") as boolean;
export const PRESEASON_SEARCH_KEY = "seeItFirst";
export const PRESEASON_STORAGE_KEY = "thereisnospoon";

export const FINALS_NAME = generateFinalsNamesAccordingFinalRound(26);
