import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import React from "react";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {ModalType, RequestState} from "data/enums";
import {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

interface ILoginParams {
	email: string;
	password: string;
}

export interface ILoginController extends ViewController {
	handleLoginForm: (event: React.SyntheticEvent<ILoginForm>) => void;
	login: (params: ILoginParams) => Promise<void>;
	resetFormErrors: () => void;

	error?: string;
	isButtonLocked: boolean;
	goToRegister: () => void;
	goToLogin: () => void;
}

@injectable()
export class LoginController implements ILoginController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _error?: string = undefined;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get error() {
		return this._error;
	}

	get isButtonLocked() {
		return this._requestState === RequestState.PENDING;
	}

	@action resetFormErrors = () => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
	};

	@action private onLoginError = (error: AxiosError<{errors: {message: string}[]}>) => {
		this._error = error.response?.data?.errors[0]?.message || error.message;
		this._requestState = RequestState.ERROR;
	};

	@action login({email, password}: ILoginParams) {
		this._requestState = RequestState.PENDING;
		return this._userStore.login({email, password}).catch(this.onLoginError);
	}

	@action handleLoginForm = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		const {email, password} = event.currentTarget;

		void this.login({
			email: email.value,
			password: password.value,
		});
	};

	goToRegister = () => {
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};

	goToLogin = () => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
