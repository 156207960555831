import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IDriverStore} from "data/stores/driver/drivers.store";
import {IDriver} from "data/types/team";

export interface IDriverPoolController extends ViewController {
	closeDriverPool: () => void;

	get drivers(): IDriver[];

	get isPoolLoading(): boolean;

	get isLockout(): boolean;
}

@injectable()
export class DriverPoolController implements IDriverPoolController {
	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore
	) {
		makeAutoObservable(this);
	}

	get isPoolLoading(): boolean {
		return this._driverStore.isLoading;
	}

	get drivers(): IDriver[] {
		return this._driverStore.getSortedDrivers(this._teamStore.salaryCap);
	}

	get isLockout() {
		return this._teamStore.isTeamInLockout;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		void this._driverStore.fetchDrivers();
	}

	closeDriverPool = (): void => {
		this._teamStore.toggleDriverPool(false);
	};
}
