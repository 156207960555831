import React from "react";
import styled from "styled-components";
import {ReactComponent as Twitter} from "assets/img/twitter.svg";
import {ReactComponent as Facebook} from "assets/img/facebook.svg";
import hrnswLogo from "assets/img/HRA_AssociateLogo.png";
import geniusLogo from "assets/img/genius.png";
import {mobileViewQuery} from "data/constants";

const MainWrapper = styled.div`
	display: flex;
	width: 100%;
	background: #333333;
	margin-top: auto;
	@media (${mobileViewQuery}) {
		flex-direction: column;
		align-items: center;
	}
`;
const Wrapper = styled.div`
	max-width: 1280px;
	height: 160px;
	padding: 50px 100px;
	background: #333333;
	display: flex;
	justify-content: space-between;
	gap: 14px;
	align-items: center;
	margin: 0 auto;
	width: 100%;

	@media screen and (max-width: 900px) {
		padding-left: 24px;
		padding-right: 24px;
	}

	@media (${mobileViewQuery}) {
		flex-direction: column;
		height: unset;
		padding: 20px;
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	@media (${mobileViewQuery}) {
		align-items: center;
		margin: 10px 0;
	}
`;

const CopyrightText = styled.p`
	font-family: var(--fontFamilyBase);
	font-size: 16px;
	font-weight: 600;
	line-height: 26px;
	color: #848484;
	@media (${mobileViewQuery}) {
		text-align: center;
	}
`;

const LinkWrapper = styled.div`
	display: flex;
	margin-top: 10px;
`;

const ExternalLink = styled.a`
	font-family: var(--fontFamilyBase);
	font-size: 16px;
	font-weight: 600;
	line-height: 26px;
	color: #ffffff;
	.genius {
		width: 110px;
		height: auto;
	}

	.logo {
		max-width: 200px;
	}
`;

const SecondLink = styled(ExternalLink)`
	margin-left: 65px;
	@media (${mobileViewQuery}) {
		margin-left: 30px;
	}
`;

const FollowText = styled(CopyrightText)`
	color: #ffffff;
`;

const TwitterWrapper = styled.a`
	svg {
		height: 24px;
		width: 24px;
	}
`;

const FacebookWrapper = styled(TwitterWrapper)`
	margin-left: 15px;
`;

export const Footer: React.FC = () => {
	const year = new Date().getFullYear();
	return (
		<MainWrapper>
			<Wrapper>
				<ExternalLink
					href="https://www.harness.org.au/"
					target={"_blank"}
					rel={"noreferrer"}>
					<img className="logo" src={hrnswLogo} alt="HRNSW Logo" />
				</ExternalLink>
				<ContentWrapper>
					<CopyrightText>
						© {year} Harness Racing Australia • All Rights Reserved
					</CopyrightText>
					<LinkWrapper>
						<ExternalLink
							href="/help/terms_conditions"
							target={"_blank"}
							rel={"noreferrer"}>
							Terms of Use
						</ExternalLink>
						<SecondLink
							href="https://www.hrnsw.com.au/uploads/files/hrnsw policys/hrnsw privacy policy revised 20 feb 2018.pdf"
							target={"_blank"}
							rel={"noreferrer"}>
							Privacy Policy
						</SecondLink>
					</LinkWrapper>
				</ContentWrapper>

				<ContentWrapper>
					<FollowText>Follow & like us on</FollowText>
					<LinkWrapper>
						<TwitterWrapper
							aria-label="Join Harness on Twitter"
							href="https://twitter.com/HarnessFantasy"
							target={"_blank"}
							rel={"noreferrer"}>
							<Twitter style={{padding: 4}} />
						</TwitterWrapper>
						<FacebookWrapper
							aria-label="Join Harness on Facebook"
							href="https://www.facebook.com/profile.php?id=100088523736364"
							target={"_blank"}
							rel={"noreferrer"}>
							<Facebook />
						</FacebookWrapper>
					</LinkWrapper>
				</ContentWrapper>
				<ContentWrapper>
					<ExternalLink
						href="https://geniussports.com"
						target={"_blank"}
						rel={"noreferrer"}>
						<img src={geniusLogo} alt="Genius Logo" className="genius" />
					</ExternalLink>
				</ContentWrapper>
			</Wrapper>
		</MainWrapper>
	);
};
