import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import {IPlayerPoolFilters, NameValueGeneric} from "data/types/team";
import {TeamPosition} from "data/enums";
import {REGIONS_FILTER_MAPPER} from "data/constants";
import {getDashedValue} from "data/utils";
import type {IDriverStore} from "data/stores/driver/drivers.store";

export interface IPoolFiltersController extends ViewController {
	get filters(): IPlayerPoolFilters;

	get regions(): NameValueGeneric<string, TeamPosition>[];

	updateFilter: (name: string, value: string | number) => void;
}

@injectable()
export class PoolFiltersController implements IPoolFiltersController {
	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore
	) {
		makeAutoObservable(this);
	}

	get filters(): IPlayerPoolFilters {
		return this._driverStore.poolFilters;
	}

	get regions(): NameValueGeneric<string, TeamPosition>[] {
		return REGIONS_FILTER_MAPPER.map((region) => ({
			name: getDashedValue(this._teamStore.getRegionNameById(region)),
			value: region,
		}));
	}

	dispose(): void {
		this._driverStore.setDefaultFilters();
	}

	init(param: void): void {
		return;
	}

	public updateFilter = (name: string, value: string | number) => {
		this._driverStore.updatePoolFilters({
			...this._driverStore.poolFilters,
			[name]: value,
		});
	};
}
