import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ILockoutStore} from "data/stores/lockout/lockout.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IReactionDisposer, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {getDashedValue} from "data/utils";
import {first} from "lodash";
import type {IGamebar} from "data/types/team";
import type {IGamebarStore} from "data/stores/gamebar/gamebar.store";
import {RoundStatus} from "data/enums";

export interface IGamebarController extends ViewController {
	fetchGamebar: () => void;

	fetchRoundsWithDelay: () => void;

	get teamName(): string;

	get gamebarData(): IGamebar | null;

	get salaryCap(): number;

	get isOverBudget(): boolean;

	get nextRoundStartDate(): string;

	get roundIndex(): number;

	get isLockout(): boolean;

	get isTradingState(): boolean;

	get isCompleteState(): boolean;

	get isLoading(): boolean;

	get isSoftLock(): boolean;

	get availableTrades(): number | undefined;

	get totalTrades(): number;
}

@injectable()
export class GamebarController implements IGamebarController {
	@observable private _subscription$: IReactionDisposer[] = [];
	@observable private _isSoftLock: boolean = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.LockoutStore) private _lockoutStore: ILockoutStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.GamebarStore) private _gamebarStore: IGamebarStore
	) {
		makeAutoObservable(this);
	}

	get gamebarData(): IGamebar | null {
		return this._gamebarStore.gamebarData;
	}

	get isSoftLock(): boolean {
		return this._isSoftLock;
	}

	get isLoading(): boolean {
		return this._gamebarStore.isLoading;
	}

	get salaryCap(): number {
		return this._teamStore.salaryCap;
	}

	get isOverBudget(): boolean {
		return this.salaryCap < 0;
	}

	get teamName(): string {
		return getDashedValue(this._teamStore.team.name);
	}

	get isLockout(): boolean {
		// return this._roundStore.selectedRound?.status === RoundStatus.Playing;
		return this._lockoutStore.isLockout;
	}

	get isTradingState(): boolean {
		// return this._roundStore.selectedRound?.status === RoundStatus.Scheduled && !this._lockoutStore.isLockout;
		return this._teamStore.isTradingState && !this.isLockout;
	}

	get isCompleteState(): boolean {
		return this._roundStore.selectedRound?.status === RoundStatus.Complete;
	}

	get nextRoundStartDate(): string {
		return first(this._roundStore.scheduledRounds)?.startDate || new Date().toISOString();
	}

	get roundIndex(): number {
		return this._roundStore.currentRound?.id || 1;
	}

	get availableTrades(): number | undefined {
		return this._teamStore.team.availableTrades;
	}

	get totalTrades(): number {
		return this._teamStore.team.totalTrades;
	}

	private get roundId(): number {
		return this._roundStore.currentRound?.id || 0;
	}

	public fetchGamebar = () => {
		if (!this.roundId) {
			return;
		}

		void this._gamebarStore.fetchGamebar(this.roundId);
	};

	public fetchRoundsWithDelay = () => {
		void this._roundStore.fetchRounds();
		runInAction(() => {
			this._isSoftLock = true;
		});

		setTimeout(() => {
			void this._roundStore.fetchRounds();
		}, 10000);
	};

	dispose(): void {
		this._subscription$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		this.fetchGamebar();
		const subscription = reaction(
			() => [this.roundId],
			() => this.fetchGamebar()
		);
		this._subscription$.push(subscription);
	}
}
