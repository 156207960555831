import React from "react";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {NavigateFunction} from "react-router-dom";
import type {IAxiosApiError} from "data/types/global";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ICountry} from "data/providers/json/json.provider";
import type {ICountriesStore} from "data/stores/countries/countries.store";

interface IForm extends HTMLFormElement {
	isDriver: HTMLInputElement;
	terms: HTMLInputElement;
	phone: HTMLInputElement;
	country: HTMLInputElement;
}

interface IParams {
	navigate: NavigateFunction;
}

export interface IModalTermsController extends ViewController<IParams> {
	handleFormChange: ({target}: React.ChangeEvent<IForm>) => void;
	handleFormSubmit: (e: React.SyntheticEvent<IForm>) => void;

	get countryList(): ICountry[];

	get isOpen(): boolean;

	get isChecked(): boolean;

	get phoneError(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class ModalTermsController implements IModalTermsController {
	@observable private _phone: string = "";
	@observable private _phoneError: boolean = false;
	@observable private _isLoading: boolean = false;
	@observable private _isChecked: boolean = false;
	@observable private _navigate: NavigateFunction | undefined;

	constructor(
		@inject(Bindings.CountriesStore) private _countryStore: ICountriesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	public get phoneError(): boolean {
		return this._phoneError;
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public get isOpen(): boolean {
		if (!this.user) {
			return false;
		}
		return !this.user.recovered;
	}

	public get isChecked(): boolean {
		return this._isChecked;
	}

	public get countryList() {
		return this._countryStore.list;
	}

	protected get user(): IUser | undefined {
		return this._userStore.user;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		void this._countryStore.fetchCountries();
	}

	public handleFormSubmit = (e: React.SyntheticEvent<IForm>): void => {
		e.preventDefault();

		const {isDriver, country} = e.currentTarget;

		if (this._phone.length > 0 && this._phone.length !== 10) {
			this._phoneError = true;
			return;
		}

		const payload = {
			isDriver: isDriver.checked,
			phone: this._phone || null,
			country: country.value || null,
		};

		this._isLoading = true;
		this._userStore
			.recoverUser(payload)
			.catch((e) => {
				const error = e as AxiosError<IAxiosApiError, unknown>;
				this._modalsStore.showAxiosError(error);
			})
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	public handleFormChange = ({target}: React.ChangeEvent<IForm>): void => {
		this._phoneError = false;
		if (target.name === "phone") {
			this._phone = String(target.value).replaceAll(/[- )(]/gm, "");
			return;
		}
		if (target.name === "terms") {
			this._isChecked = target.checked as boolean;
		}
	};
}
