import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface ITradesActionsBarController extends ViewController {
	clearTrades: () => void;
	makeTrades: () => void;

	get isResetDisabled(): boolean;
	get isMakeTradesDisabled(): boolean;

	get isOverBudget(): boolean;
}

@injectable()
export class TradesActionsBarController implements ITradesActionsBarController {
	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {}

	clearTrades = () => {
		this._teamStore.resetTrades();
	};

	makeTrades = () => {
		this._modalsStore.showModal(ModalType.TRADE_CONFIRM);
	};

	get isResetDisabled(): boolean {
		return this._teamStore.team.trades.length === 0 && this._teamStore.userTrades.length === 0;
	}

	get isMakeTradesDisabled(): boolean {
		return this._teamStore.teamFullTrades.length === 0;
	}

	get isOverBudget(): boolean {
		return this._teamStore.isOverBudget;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
