import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable} from "mobx";
import {IS_PRESEASON, PRESEASON_STORAGE_KEY} from "data/constants";

export interface IGameGateController extends ViewController {
	get isSessionChecked(): boolean;

	updateStorage: () => void;
}

@injectable()
export class GameGateController implements IGameGateController {
	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	get isSessionChecked(): boolean {
		return this._userStore.isSessionChecked;
	}

	updateStorage = () => {
		localStorage.setItem(PRESEASON_STORAGE_KEY, "true");
	};

	dispose(): void {
		return;
	}

	async init() {
		if (!IS_PRESEASON || localStorage.getItem(PRESEASON_STORAGE_KEY as string) === "true") {
			await this._userStore.restoreSession();
		}
	}
}
