import {ViewController} from "data/types/structure";
import React from "react";
import type {ILeague} from "data/providers/api/league.api.provider";
import {IRound} from "data/providers/json/json.provider";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {EMPTY_LEAGUE_DATA} from "views/pages/leagues/join_leagues/join_leagues.page";
import {H2H_MAXIMUM_TEAMS, H2H_MINIMUM_TEAMS} from "data/constants";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";
import {H2HFinalType, LeaguePrivacy, LeagueType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ICreateLeagueParams, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {SelectChangeEvent} from "@mui/material";
import {first, last} from "lodash";

interface ICreateLeagueForm {
	name: string;
	startRound: number | null;
	privacy: LeaguePrivacy;
	type: LeagueType;
	teamLimit?: number;
	finalsFormat: H2HFinalType;
}

export interface ICreateLeagueStepOneFormController extends ViewController {
	handleTeamLimitInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleTeamLimitInputBlur: (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	handleTeamLimitSliderChange: (event: Event, newValue: number | number[]) => void;

	handleFormSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void;

	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
	handleSelectChange: (event: SelectChangeEvent<number | null>) => void;
	handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;

	get ish2h(): boolean;

	get createdLeague(): ILeague;

	get scheduledRounds(): IRound[];

	get isLeagueCreating(): boolean;

	get isSubmitDisabled(): boolean;

	get form(): ICreateLeagueForm;

	get noScheduleRounds(): boolean;
}

@injectable()
export class CreateLeagueStepOneFormController implements ICreateLeagueStepOneFormController {
	@observable private _rounds: IRound[] = this._roundsStore.list;
	@observable private _startRound: number = 0;

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _form: ICreateLeagueForm = {
		finalsFormat: H2HFinalType.Top4,
		name: "",
		type: LeagueType.Regular,
		privacy: LeaguePrivacy.Private,
		startRound: 0,
		teamLimit: 4,
	};

	get form(): ICreateLeagueForm {
		return this._form;
	}

	@observable private _isLeagueCreating = false;

	get isLeagueCreating(): boolean {
		return this._isLeagueCreating;
	}

	@observable private _createdLeague: ILeague = EMPTY_LEAGUE_DATA;

	get createdLeague() {
		return this._createdLeague;
	}

	get ish2h() {
		return this._form.type === LeagueType.H2H;
	}

	get leagues() {
		return this._leaguesStore.list;
	}

	get scheduledRounds() {
		if (this.form.type === LeagueType.H2H) {
			return this._roundsStore.h2hLeagueRounds(this.form.finalsFormat);
		}
		return this._roundsStore.scheduledRounds;
	}

	get ish2hDisabled() {
		return this._roundsStore.getIsH2hDisabled(this.form.finalsFormat);
	}

	get isSubmitDisabled() {
		return this.isLeagueCreating || this.ish2hDisabled;
	}

	get noScheduleRounds(): boolean {
		return this.scheduledRounds.length === 0;
	}

	@action
	setStartRound = (round: number) => {
		this._startRound = Number(round);
	};

	@action
	handleTeamLimitInputBlur = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (this._form.teamLimit === undefined) {
			return;
		}
		if (this._form.teamLimit > H2H_MAXIMUM_TEAMS) {
			this._form.teamLimit = H2H_MAXIMUM_TEAMS;
			return;
		}

		if (this._form.teamLimit < H2H_MINIMUM_TEAMS) {
			this._form.teamLimit = H2H_MINIMUM_TEAMS;
		}
	};

	@action
	handleTeamLimitInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this._form.teamLimit = Number(event.target.value);
		this.checkH2HFinals();
	};

	@action
	handleTeamLimitSliderChange = (event: Event, newValue: number | number[]) => {
		this._form.teamLimit = newValue as number;
		this.checkH2HFinals();
	};

	@action
	async createLeague(payload: ICreateLeagueParams): Promise<void> {
		try {
			this._isLeagueCreating = true;
			const league = await this._leaguesStore.createLeague(payload);
			runInAction(() => {
				this._createdLeague = league!;
			});
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalStore.showAxiosError(error);
		} finally {
			runInAction(() => {
				this._isLeagueCreating = false;
			});
		}
	}

	public handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>): void => {
		e.preventDefault();

		const startRound = this.getStartRound();

		void this.createLeague({
			name: this.form.name,
			privacy: this.form.privacy,
			startRound,
			type: this.form.type,
			teamLimit: this.form.teamLimit,
			finalsFormat: this.form.finalsFormat,
		});
	};

	public dispose(): void {
		return;
	}

	public init(param: void): void {
		return;
	}

	public handleSelectChange = (event: SelectChangeEvent<number | null>) => {
		const {name, value} = event.target;
		this.handleFormChangeByKeyValue(name, value);
	};

	public handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
		const name = event.target.name;
		this.handleFormChangeByKeyValue(name, value);
		this.checkH2HRounds();
	};

	public handleFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
		const {name, value} = event.target;
		this.handleFormChangeByKeyValue(name, value as string | number | null);
	};

	@action
	private handleFormChangeByKeyValue(name: string, value: unknown) {
		if (!name || !Object.keys(this.form).includes(name)) {
			return;
		}

		const nameKey = name as keyof ICreateLeagueForm;
		runInAction(() => {
			// TODO: Refactor to pass correct type, implementation is working
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.form[nameKey] = value;
		});
	}

	@action
	private checkH2HFinals = () => {
		const value = Number(this.form.teamLimit);
		if (value < 8 && this.form.finalsFormat === H2HFinalType.Top8) {
			runInAction(() => {
				this._form.finalsFormat = H2HFinalType.Top4;
			});
		}
	};

	private getStartRound() {
		if (this.form.startRound !== 0) {
			return this.form.startRound;
		}

		return first(this.scheduledRounds)?.id || 0;
	}

	@action
	private checkH2HRounds() {
		// Selected next scheduled round
		if (this.form.startRound === 0) {
			return;
		}

		const isRoundExists = this.scheduledRounds.some((e) => e.id === this.form.startRound);
		if (!isRoundExists) {
			const lastRound = last(this.scheduledRounds);
			runInAction(() => {
				this._form.startRound = lastRound?.id || 0;
			});
		}
	}
}
