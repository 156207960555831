import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider, INotification} from "data/providers/json/json.provider";
import {isEqual} from "lodash";
import {PRIZE_ARTICLE_ID} from "data/constants";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IStaticContent {
	page: number;
	previous_page: null;
	next_page: null;
	per_page: number;
	page_count: number;
	count: number;
	sort_by: string;
	sort_order: string;
}

export interface ISections extends IStaticContent {
	sections: {
		id: number;
		url: string;
		html_url: string;
		category_id: number;
		position: number;
		sorting: string;
		created_at: string;
		updated_at: string;
		name: string;
		description: string;
		locale: string;
		source_locale: string;
		outdated: boolean;
		parent_section_id: null;
		theme_template: string;
	}[];
}

export interface ICategories extends IStaticContent {
	categories: {
		id: number;
		url: string;
		html_url: string;
		position: number;
		created_at: string;
		updated_at: string;
		name: string;
		description: string;
		locale: string;
		source_locale: string;
		outdated: boolean;
	}[];
}

export interface IArticles extends IStaticContent {
	articles: {
		id: number;
		url: string;
		html_url: string;
		author_id: number;
		comments_disabled: boolean;
		draft: boolean;
		promoted: boolean;
		position: number;
		vote_sum: number;
		vote_count: number;
		section_id: number;
		created_at: string;
		updated_at: string;
		name: string;
		title: string;
		source_locale: string;
		locale: string;
		outdated: boolean;
		outdated_locales: unknown[];
		edited_at: string;
		user_segment_id: null;
		permission_group_id: number;
		label_names: string[];
		body: string;
	}[];
}

export type TArticle = IArticles["articles"][number];

export interface IStaticContentStore {
	get prizes(): TArticle | undefined;

	get sections(): ISections["sections"];
	get articles(): IArticles["articles"];

	fetchStaticContent: () => Promise<void>;
	fetchPrizesContent: () => Promise<void>;

	fetchNotificationBar: () => void;
	get notificationBar(): INotification;
}

@injectable()
export class StaticContentStore implements IStaticContentStore {
	@observable private _helpSections?: ISections["sections"];
	@observable private _helpComponents?: IArticles["articles"];
	@observable private _prizeComponents?: IArticles["articles"][number];

	@observable private _notificationBar: INotification = {
		name: "",
		isEnabled: false,
		message: "",
	};

	@computed get sections() {
		return this._helpSections ?? [];
	}

	@computed get articles() {
		return this._helpComponents ?? [];
	}

	@computed get prizes() {
		return this._prizeComponents;
	}

	@computed get notificationBar() {
		return this._notificationBar;
	}

	constructor(
		@inject(Bindings.JSONProvider) private readonly _jsonProvider: IJSONProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	public async fetchPrizesContent() {
		try {
			const articles = await this._jsonProvider.helpArticles();

			runInAction(() => {
				this._prizeComponents = articles.data.articles.find(
					(article) => article.id === PRIZE_ARTICLE_ID
				);
			});
		} catch (e) {
			this._modalsStore.showError({
				title: "Error",
				message: "Error while loading static content",
			});
		}
	}

	public async fetchStaticContent() {
		const [categories, sections, articles] = await Promise.all([
			this._jsonProvider.helpCategories(),
			this._jsonProvider.helpSections(),
			this._jsonProvider.helpArticles(),
		]);

		const helpComponentsId = categories.data.categories.find(({name}) =>
			isEqual(name, "Help Centre")
		)?.id;

		runInAction(() => {
			this._helpSections = sections.data.sections.filter(
				(section) => section.category_id === helpComponentsId
			);

			this._helpComponents = articles.data.articles;
		});
	}

	@action
	public async fetchNotificationBar() {
		try {
			const {data} = await this._jsonProvider.notificationBar();

			if (data) {
				runInAction(() => {
					this._notificationBar = data;
				});
			}
		} catch (e) {
			this._modalsStore.showError({
				title: "Error",
				message: "Error while loading static content",
			});
		}
	}
}
