import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {useNavigate} from "react-router-dom";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITitleStore} from "data/stores/title/title.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILeague} from "data/providers/api/league.api.provider";
import {EMPTY_LEAGUE_DATA} from "views/pages/leagues/join_leagues/join_leagues.page";

interface IInit {
	navigate: ReturnType<typeof useNavigate>;
}

export interface ICreateLeagueController extends ViewController<IInit> {
	skipStep: () => void;
	setCreatedLeague: (league: ILeague) => void;

	get createdLeague(): ILeague;

	get activeStep(): number;

	setActiveStep: (step: number) => void;
}

@injectable()
export class CreateLeagueController implements ICreateLeagueController {
	private _navigate!: IInit["navigate"];

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TitleStore) public _titleStore: ITitleStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _createdLeague: ILeague = EMPTY_LEAGUE_DATA;

	get createdLeague(): ILeague {
		return this._createdLeague;
	}

	@observable private _activeStep = 1;

	get activeStep() {
		return this._activeStep;
	}

	@action
	public setCreatedLeague = (value: ILeague): void => {
		runInAction(() => {
			this._createdLeague = value;
		});
	};

	skipStep = () => {
		const league = this.createdLeague;
		if (league) {
			this._navigate(`/leagues/${league.id}/table`);
		} else {
			this._navigate("/leagues");
		}
	};

	dispose(): void {
		return;
	}

	init({navigate}: IInit) {
		this._navigate = navigate;
		void this._roundsStore.fetchRounds();
		this._titleStore.setTitle("Create League");
	}

	@action
	setActiveStep = (step: number) => {
		runInAction(() => {
			this._activeStep = step;
		});
	};
}
