import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {IFetchRankings} from "data/stores/rankings/rankings.store";

export interface IRank {
	leagueId: number;
	overallPoints: number;
	overallRank: number;
	points: number;
	rank: number;
	roundId: number;
	userId: number;
	userName: string;
}

type IRankingsResponse = IApiResponse<{
	rankings: IRank[];
	nextPage: boolean;
	user: IRank;
}>;

export interface IRankingsApiProvider {
	rankings: (params: IFetchRankings) => Promise<AxiosResponse<IRankingsResponse>>;
}

@injectable()
export class RankingsApiProvider implements IRankingsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}
	rankings = (params: IFetchRankings) => {
		return this._http.get<IRankingsResponse>(`/ranking`, params);
	};
}
