import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {first} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {matchPath} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IStaticContentStore} from "data/stores/static_content/static_content.store";

interface IMenuItem {
	url: string;
	label: string;
	children?: {url: string; label: string}[];
}

export interface INavController extends ViewController {
	get isAuthorized(): boolean;

	get userInitials(): string;

	get userFullName(): string;

	get mainMenuList(): IMenuItem[];

	get staticPagesMenuList(): IMenuItem[];

	get isOpenDrawer(): boolean;

	get menuOpen(): boolean;

	get isNotificationBarExists(): boolean;

	get isPrivateLocked(): boolean;

	toggleDraw: () => void;

	_drawerState: boolean;

	goToRegister: () => void;

	goToLogin: () => void;

	logout: () => void;

	toggleMenu: () => void;

	closeMenu: () => void;
}

@injectable()
export class NavController implements INavController {
	@observable _drawerState = false;
	@observable _menuOpen = false;

	private static modalPaths = {
		"/login": ModalType.LOGIN,
		"/registration": ModalType.REGISTRATION,
		"/reset-password": ModalType.RESET_PASSWORD,
		"/forgot-password": ModalType.FORGOT_PASSWORD,
	} as const;

	constructor(
		@inject(Bindings.StaticContentStore) private _staticContentStore: IStaticContentStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action
	toggleMenu = () => {
		runInAction(() => {
			this._menuOpen = !this._menuOpen;
		});
	};

	@action
	closeMenu = () => {
		runInAction(() => {
			this._menuOpen = false;
		});
	};

	get isPrivateLocked(): boolean {
		return this._userStore.isPrivateLocked;
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get rootPath(): string {
		return "/my-team";
	}

	get menuOpen(): boolean {
		return this._menuOpen;
	}

	get userInitials(): string {
		const firstName = first(this._userStore.user?.firstName) || "";
		const lastName = first(this._userStore.user?.lastName) || "";

		return `${firstName}${lastName}`.toUpperCase();
	}

	get userFullName(): string {
		const firstName = this._userStore.user?.firstName || "";
		const lastName = this._userStore.user?.lastName || "";

		return `${firstName} ${lastName}`;
	}

	get isOpenDrawer(): boolean {
		return this._drawerState;
	}

	get mainMenuList() {
		return [
			{
				url: this.rootPath,
				label: "My Team",
			},
			{
				url: "leagues",
				label: "Leagues",
				children: [
					{
						url: "leagues",
						label: "My Leagues",
					},
					{
						url: "leagues/create",
						label: "Create a League",
					},
					{
						url: "leagues/join",
						label: "Join a League",
					},
				],
			},
			{
				url: "standings",
				label: "Standings",
			},
		];
	}

	get staticPagesMenuList() {
		return [
			{
				url: "/help",
				label: "Help",
				children: [
					{
						url: "help/game_guidelines",
						label: "Game Guidelines",
					},
					{
						url: "help/faq",
						label: "FAQs",
					},
					{
						url: "help/prizes",
						label: "Prizes",
					},
					{
						url: "help/terms_conditions",
						label: "T&Cs",
					},
					{
						url: "help/contact_us",
						label: "Contact Us",
					},
				],
			},
		];
	}

	mapModalsToLocation = (pathname: string) => {
		const keys = Object.keys(
			NavController.modalPaths
		) as (keyof typeof NavController.modalPaths)[];

		keys.some((pathPattern) => {
			const isMatch = matchPath(pathPattern, pathname);

			if (isMatch) {
				this._modalsStore.showModal(NavController.modalPaths[pathPattern]);
			}

			return isMatch;
		});
	};

	@action toggleDraw(): void {
		this._drawerState = !this._drawerState;
	}

	@action goToRegister = () => {
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};

	@action goToLogin = () => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	@action logout = async () => {
		await this._userStore.logout();
	};

	dispose(): void {
		return;
	}

	init(): void {
		this.mapModalsToLocation(window.location.pathname);
	}

	public get isNotificationBarExists(): boolean {
		return this._staticContentStore.notificationBar.isEnabled;
	}
}
