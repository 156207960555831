import {IDriver} from "data/types/team";
import type {Empty} from "data/types/global";
import {ViewController} from "data/types/structure";
import {makeAutoObservable, observable, toJS} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IDriverStore} from "data/stores/driver/drivers.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IMatchupStore} from "data/stores/matchup/matchup.store";
import {IMatchupTeam} from "data/providers/api/league.api.provider";
import {getDashedValue} from "data/utils";
import {uniq} from "lodash";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IDriverData {
	isCaptain: boolean;
	isViceCaptain: boolean;
	isGroupOne: boolean;
	isGroupTwo: boolean;
	isReserve: boolean;
}

interface IParams {
	homeDriverId: number;
	awayDriverId: number;
	roundId: number;

	homeTeamId: Empty<number>;
	awayTeamId: Empty<number>;
}

export interface IMatchupDriverToDriverController extends ViewController<IParams> {
	openHomeDriverModal: () => void;
	openAwayDriverModal: () => void;

	get homeDriver(): Empty<IDriver>;

	get awayDriver(): Empty<IDriver>;

	get homeDriverScore(): Empty<number>;

	get awayDriverScore(): Empty<number>;

	get homeDriverData(): IDriverData;

	get awayDriverData(): IDriverData;

	get homeDriverRegion(): string;

	get awayDriverRegion(): string;
}

@injectable()
export class MatchupDriverToDriverController implements IMatchupDriverToDriverController {
	@observable private _homeDriverId: Empty<number>;
	@observable private _awayDriverId: Empty<number>;
	@observable private _homeTeamId: Empty<number>;
	@observable private _awayTeamId: Empty<number>;
	@observable private _roundId: Empty<number>;

	constructor(
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.MatchupStore) private _matchupStore: IMatchupStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get homeDriver(): Empty<IDriver> {
		return this._driverStore.getDriverById(this._homeDriverId);
	}

	get awayDriver(): Empty<IDriver> {
		return this._driverStore.getDriverById(this._awayDriverId);
	}

	get homeDriverScore(): Empty<number> {
		if (!this.homeDriver || !this._roundId) {
			return;
		}

		const points = this.homeDriver?.points.rounds?.[this._roundId];
		if (points === undefined) {
			return;
		}

		if (this.homeDriverData.isCaptain) {
			return points * 2;
		}

		if (this.homeDriverData.isViceCaptain && !this.homeCaptain?.points) {
			return points * 2;
		}

		return points;
	}

	get awayDriverScore(): Empty<number> {
		if (!this.awayDriver || !this._roundId) {
			return;
		}

		const points = this.awayDriver?.points.rounds?.[this._roundId];
		if (points === undefined) {
			return;
		}

		if (this.awayDriverData.isCaptain) {
			return points * 2;
		}

		if (this.awayDriverData.isViceCaptain && !this.awayCaptain?.points) {
			return points * 2;
		}

		return points;
	}

	get homeDriverData(): IDriverData {
		const isCaptain = this._homeDriverId === this.homeTeam?.lineup.captain;
		const isViceCaptain = this._homeDriverId === this.homeTeam?.lineup.viceCaptain;
		const groups = this.getDriverGroups(this.homeDriver);
		const isReserve = Object.values(this.homeTeam?.lineup.reserve || {}).includes(
			Number(this._homeDriverId)
		);
		return {
			isReserve,
			isCaptain,
			isViceCaptain,
			isGroupOne: groups.includes(1),
			isGroupTwo: groups.includes(2),
		};
	}

	get awayDriverData(): IDriverData {
		const isCaptain = this._awayDriverId === this.awayTeam?.lineup.captain;
		const isViceCaptain = this._awayDriverId === this.awayTeam?.lineup.viceCaptain;
		const groups = this.getDriverGroups(this.awayDriver);
		const isReserve = Object.values(this.homeTeam?.lineup.reserve || {}).includes(
			Number(this._homeDriverId)
		);
		return {
			isReserve,
			isCaptain,
			isViceCaptain,
			isGroupOne: groups.includes(1),
			isGroupTwo: groups.includes(2),
		};
	}

	get homeDriverRegion(): string {
		return getDashedValue(this.homeDriver?.regionName);
	}

	get awayDriverRegion(): string {
		return getDashedValue(this.awayDriver?.regionName);
	}

	private get homeTeam(): Empty<IMatchupTeam> {
		if (!this._homeTeamId) return;
		return this._matchupStore.getMatchupTeamById(this._homeTeamId);
	}

	private get awayTeam(): Empty<IMatchupTeam> {
		if (!this._awayTeamId) return;
		return this._matchupStore.getMatchupTeamById(this._awayTeamId);
	}

	private get homeCaptain() {
		const captainId = this.homeTeam?.lineup.captain;
		return this._driverStore.getDriverById(captainId);
	}

	private get awayCaptain() {
		const captainId = this.awayTeam?.lineup.captain;
		return this._driverStore.getDriverById(captainId);
	}

	public openHomeDriverModal = () => {
		if (!this._homeDriverId) {
			return;
		}
		this._modalStore.showModal(ModalType.DRIVER_PROFILE, {driverId: this._homeDriverId});
	};

	public openAwayDriverModal = () => {
		if (!this._awayDriverId) {
			return;
		}
		this._modalStore.showModal(ModalType.DRIVER_PROFILE, {driverId: this._awayDriverId});
	};

	init(params: IParams): void {
		this._homeDriverId = params.homeDriverId;
		this._awayDriverId = params.awayDriverId;
		this._homeTeamId = params.homeTeamId;
		this._awayTeamId = params.awayTeamId;
		this._roundId = params.roundId;
	}

	dispose(): void {
		return;
	}

	private getDriverGroups(driver: Empty<IDriver>): number[] {
		const jsDriver = toJS(driver);
		const round = this._roundsStore.getRoundById(this._roundId);
		const races = toJS(round)?.races;

		if (!jsDriver || !races) {
			return [];
		}

		const driverRaces = races.filter((race) => {
			const isDriverInRace = race.drivers.includes(jsDriver.id);
			const isGroupRace = [race.group1, race.group2].includes(true);
			return isDriverInRace && isGroupRace;
		});
		return driverRaces.length > 0
			? uniq(
					driverRaces.map((race) => {
						return race.group1 ? 1 : 2;
					})
			  ).sort()
			: [];
	}
}
