import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {ICountriesStore} from "data/stores/countries/countries.store";
import type {ICountry} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {getErrorMessageFromAxiosResponse, trackSentryErrors} from "data/utils";
import {get} from "lodash";
import React from "react";
import type {ITitleStore} from "data/stores/title/title.store";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";

interface IMyAccountFormValues {
	firstName: string;
	lastName: string;
	email: string;
	teamName: string;
	isNotificationsEnabled: boolean | undefined;
	optIn: boolean | undefined;
}

interface IUpdateForm extends HTMLFormElement {
	firstName: HTMLInputElement;
	lastName: HTMLInputElement;
	email: HTMLInputElement;
	teamName: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
	optIn: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	logout: () => void;
	formErrors: Record<string, string>;
	handleFormChange: (e: React.ChangeEvent<HTMLFormElement>) => void;
	submit: (e: React.SyntheticEvent<IUpdateForm>) => void;
	formValues: IMyAccountFormValues;
	error: string | undefined;
	deactivate: () => void;
	openZendesk: () => void;

	get countries(): ICountry[];

	get userName(): string;

	get user(): IUser | undefined;

	get isLoading(): boolean;

	get isSubmitDisabled(): boolean;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper,
		@inject(Bindings.TitleStore) public _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	@observable _error: string | undefined = undefined;

	get error() {
		return this._error;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isSubmitDisabled() {
		return this._isLoading || !this.isChanged;
	}

	@observable _formValues = {
		firstName: this._userStore.user?.firstName || "",
		lastName: this._userStore.user?.lastName || "",
		email: this._userStore.user?.email || "",
		teamName: this._userStore.user?.teamName || "",
		isNotificationsEnabled: this._userStore.user?.isNotificationsEnabled,
		optIn: this._userStore.user?.optIn,
	};

	get formValues() {
		return this._formValues;
	}

	get countries() {
		return this._countriesStore.list;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get userName() {
		return this._userStore.fullName;
	}

	get user() {
		return this._userStore.user;
	}

	get isChanged() {
		let changed = false;
		const user = this.user;
		Object.keys(this.formValues).forEach((fieldName) => {
			const formValue = get(this.formValues, fieldName) as string | boolean;
			const userValue = get(user, fieldName) as string | boolean;
			if (formValue !== userValue) {
				changed = true;
			}
		});
		return changed;
	}

	@action
	public handleFormChange = ({
		target: {name, value, checked},
	}: React.ChangeEvent<HTMLFormElement>) => {
		this._error = undefined;
		this._validationHelper.clearFormFieldError(name);
		const valueToUse = ["isNotificationsEnabled", "optIn"].includes(name)
			? Boolean(checked)
			: String(value);

		this._formValues = {
			...this._formValues,
			[name]: valueToUse as string,
		};
	};

	@action submit = async (event: React.SyntheticEvent<IUpdateForm>) => {
		event.preventDefault();
		this._error = undefined;
		const {checkValidity} = this._validationHelper;
		const form = event.currentTarget;
		if (this.formErrors["teamName"]) {
			return;
		}

		const canSubmit = checkValidity(form);

		if (canSubmit) {
			this.updateLoadingState(true);
			const isSubmitted = await this.checkUsernameUnique(
				"teamName",
				this._formValues.teamName
			).catch(this.onError);

			if (isSubmitted) {
				this._userStore
					.update(this._formValues)
					.then(this.onSuccess)
					.catch(this.onError)
					.finally(this.onFinally);
			} else {
				this.updateLoadingState(false);
			}
		}
	};

	@action deactivate = () => {
		void this._modalsStore.showModal(ModalType.DEACTIVATE);
	};

	public openZendesk(): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		window.zE?.activate();
	}

	dispose(): void {
		return;
	}

	logout = () => void this._userStore.logout();

	init(params: void): void {
		void this._countriesStore.fetchCountries();
		void this._titleStore.setTitle("My Account");
	}

	private checkUsernameUnique(fieldName: string, fieldValue: string): Promise<boolean> {
		if (!fieldValue) return Promise.resolve(false);

		return this._userStore
			.checkUsername({
				username: fieldValue,
			})
			.then((error) => {
				if (error) {
					this._validationHelper.setFormFieldError(fieldName, error);
					return false;
				}

				return true;
			})
			.catch((err: Error) => {
				trackSentryErrors(err, {}, "form registration - check username");
				this._validationHelper.setFormFieldError(fieldName, err.message);

				return false;
			});
	}

	private onSuccess = () => {
		this.updateLoadingState(false);
		this._modalsStore.showModal(ModalType.ACCOUNT_UPDATED);
	};

	@action private onError = (event: AxiosError<IAxiosApiError, unknown>) => {
		this._error = getErrorMessageFromAxiosResponse(event);
		this.updateLoadingState(false);
	};

	private onFinally = () => {
		this.updateLoadingState(false);
	};

	@action
	private updateLoadingState(value: boolean) {
		runInAction(() => {
			this._isLoading = value;
		});
	}
}
