import React, {useCallback} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IForgotPasswordFormController} from "views/components/forms/forgot_password_form/forgot_password_form.controller";

import styled from "@emotion/styled";
import {FormControl, TextField} from "@mui/material";
import {ButtonPrimary} from "views/components/button";
import {observer} from "mobx-react";
import {EMAIL_REGEXP_STR, FORM_VALIDATION_ELEMENT_CLASSNAME} from "data/constants";
import {Bindings} from "data/constants/bindings";

const Title = styled.h3`
	font-family: var(--fontFamilyBase);
	color: var(--textColor3);
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.2px;
`;

const Text = styled.p`
	font-family: var(--fontFamilyBase);
	color: var(--textColor3);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.02em;
	padding: 0 20px;
	margin-bottom: 10px;
`;

const SubmitButton = styled(ButtonPrimary)`
	height: 52px;
	width: 100%;
`;

export const ForgotPasswordFormComponent: React.FC = observer(() => {
	const controller = useViewController<IForgotPasswordFormController>(
		Bindings.ForgotPasswordFormController
	);
	const {handleFormChange, submit, isLoading, error, formErrors, tmpUserData} = controller;

	const submitHandler = useCallback(
		(e: React.SyntheticEvent<HTMLFormElement, Event>) => {
			void submit(e);
		},
		[submit]
	);

	const errorEmail = formErrors["email"] || error;

	return (
		<React.Fragment>
			<Title>Forgotten Password</Title>
			<Text>Please enter the email address you’re currently registered with.</Text>
			<form onChange={handleFormChange} onSubmit={submitHandler} noValidate>
				<FormControl fullWidth sx={{mb: 3}}>
					<TextField
						fullWidth
						defaultValue={tmpUserData?.email}
						type="email"
						name="email"
						label={"Email"}
						required
						inputProps={{
							pattern: EMAIL_REGEXP_STR,
							className: FORM_VALIDATION_ELEMENT_CLASSNAME,
						}}
						error={Boolean(errorEmail)}
						helperText={errorEmail}
					/>
				</FormControl>
				<SubmitButton type="submit" disabled={isLoading}>
					Submit
				</SubmitButton>
			</form>
		</React.Fragment>
	);
});
