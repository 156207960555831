import styled from "styled-components";
import React from "react";
import {IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Exist} from "views/components/Exist";
import {mobileViewQuery} from "data/constants";

export const Form = styled.form`
	display: flex;
	flex-flow: column;
	gap: 20px;
`;

interface IButtonViewPasswordProps {
	isVisible: boolean;
	onClick: () => void;
}

export const ButtonViewPassword: React.FC<IButtonViewPasswordProps> = ({isVisible, onClick}) => (
	<InputAdornment position="end">
		<IconButton onClick={onClick}>
			<Exist when={isVisible}>
				<Visibility />
			</Exist>
			<Exist when={!isVisible}>
				<VisibilityOff />
			</Exist>
		</IconButton>
	</InputAdornment>
);

export const CheckboxLabelTypography = styled(Typography)`
	font-size: 14px;

	a {
		color: var(--primaryColor);
		font-weight: 500;
	}
` as typeof Typography;

export const FormField = styled.div`
	margin-bottom: 20px;

	span {
		text-transform: capitalize;
		margin-top: 5px;

		&.h2h {
			text-transform: uppercase;
		}
	}
`;

export const Input = styled(TextField)`
	background: #fff;
	color: #a3a3a3;
	border-radius: 4px;

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: #a3a3a3;
	}

	.MuiFilledInput-root::before,
	.MuiFilledInput-root::after {
		border-radius: 3px;
	}

	.MuiFilledInput-root::after {
		border-color: #a3a3a3;
	}
`;

export const SliderWrapper = styled.div`
	display: flex;
	width: 60%;
	padding: 0 10px;
	gap: 20px;
	min-height: 60px;

	.MuiInputBase-input {
		text-align: center;
	}

	&.settings {
		.MuiInputBase-root {
			width: 60px;
		}

		.MuiFormControl-root {
			border: none;
		}

		input {
			padding-right: 6px;
		}
	}

	@media (${mobileViewQuery}) {
		width: 100%;
	}
`;
