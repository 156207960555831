import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ILeague} from "data/providers/api/league.api.provider";
import type {ITitleStore} from "data/stores/title/title.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import React from "react";

export interface IMyLeaguesController extends ViewController {
	get myLeagues(): ILeague[];
	get user(): IUser | undefined;
	get nextPage(): boolean;
	page: number;
	handleLoadMore: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable page = 1;

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TitleStore) public _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	get myLeagues() {
		return this._leaguesStore.myLeagues;
	}

	get nextPage() {
		return this._leaguesStore.nextPage;
	}

	get user() {
		return this._userStore.user;
	}

	@action handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		this.page++;

		void this._leaguesStore.fetchMoreMyLeagues({page: this.page});
	};

	init(): void {
		void this._titleStore.setTitle("My Leagues");
		void this._leaguesStore.fetchMyLeagues();
	}

	dispose(): void {
		return;
	}
}
