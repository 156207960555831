import {ILeague} from "data/providers/api/league.api.provider";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {computed, makeAutoObservable, observable, runInAction} from "mobx";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ModalType} from "data/enums";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";
import {useNavigate} from "react-router-dom";

interface IInitProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IDeleteOrLeaveLeagueModalController extends ViewController<IInitProps> {
	deleteLeague: () => void;
	leaveLeague: () => void;
	closeModal: () => void;

	get league(): ILeague | undefined;

	get isModalOpen(): boolean;

	get isOwner(): boolean;

	get isRequestInProcess(): boolean;
}

@injectable()
export class DeleteOrLeaveLeagueModalController implements IDeleteOrLeaveLeagueModalController {
	private _navigate: ReturnType<typeof useNavigate> | null = null;
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isRequestInProcess = false;

	get isRequestInProcess(): boolean {
		return this._isRequestInProcess;
	}

	@computed
	get league(): ILeague | undefined {
		return this._leaguesStore.selectedLeague;
	}

	@computed
	get isOwner(): boolean {
		return this.league?.userId === this._userStore.user?.id;
	}

	get isModalOpen(): boolean {
		return this._modalsStore.modal === ModalType.LEAGUE_LEAVE;
	}

	dispose(): void {
		return;
	}

	init(props: IInitProps): void {
		this._navigate = props.navigate;
	}

	deleteLeague = (): void => {
		if (this.isRequestInProcess || !this.league?.id) return;

		this.setInProcess(true);

		this._leaguesStore
			.deleteLeague({leagueId: this.league.id})
			.then(() => {
				this.closeModal();
				this.navigateToLeagues();
			})
			.catch((e) => {
				const error = e as AxiosError<IAxiosApiError, unknown>;
				this._modalsStore.showAxiosError(error);
			})
			.finally(() => {
				this.setInProcess(false);
			});
	};

	leaveLeague = (): void => {
		if (this.isRequestInProcess || !this.league?.id) return;

		this.setInProcess(true);

		this._leaguesStore
			.leaveLeague({leagueId: this.league.id})
			.then(() => {
				this.closeModal();
				this.navigateToLeagues();
			})
			.catch((e) => {
				const error = e as AxiosError<IAxiosApiError, unknown>;
				this._modalsStore.showAxiosError(error);
			})
			.finally(() => {
				this.setInProcess(false);
			});
	};

	closeModal = () => {
		this._modalsStore.hideModal();
	};

	private navigateToLeagues() {
		if (this._navigate) {
			this._navigate("/leagues");
		}
	}

	private setInProcess(value: boolean) {
		runInAction(() => {
			this._isRequestInProcess = value;
		});
	}
}
