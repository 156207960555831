import {inject, injectable} from "inversify";
import type {
	IChangePasswordPayload,
	IPreRegoPayload,
	IRegistrationPayload,
	IResetPasswordPayload,
	IUpdateUserPayload,
	IUser,
	IRecoverUserPayload,
} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface ILoginPayload {
	email: string;
	password: string;
}

export interface IForgotPasswordPayload {
	email: string;
}

export interface ICheckUserNamePayload {
	username: string;
}

type TLoginResponse = IApiResponse<{user: IUser}>;

type TRegisterResponse = IApiResponse<{user: IUser}>;

type TUpdateResponse = IApiResponse<{user: IUser}>;

type TUserResponse = IApiResponse<{user: IUser}>;

export interface IAuthApiProvider {
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	preregister: (params: IPreRegoPayload) => Promise<AxiosResponse>;
	logout: () => Promise<AxiosResponse>;
	checkUsername: (params: ICheckUserNamePayload) => Promise<AxiosResponse>;
	signUp: (params: IRegistrationPayload) => Promise<AxiosResponse<TRegisterResponse>>;
	forgotPassword: (params: IForgotPasswordPayload) => Promise<AxiosResponse>;
	resetPassword: (params: IResetPasswordPayload) => Promise<AxiosResponse>;
	update: (params: IUpdateUserPayload) => Promise<AxiosResponse<TUpdateResponse>>;
	deactivate: () => Promise<AxiosResponse>;
	changePassword: (params: IChangePasswordPayload) => Promise<AxiosResponse<TUpdateResponse>>;
	getCurrentUser: () => Promise<AxiosResponse<TUserResponse>>;
	recoverUser: (payload: IRecoverUserPayload) => Promise<AxiosResponse<TUserResponse>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>("auth/login", params);
	preregister = (params: IPreRegoPayload) =>
		this._http.post<TLoginResponse>("auth/preregister", params);

	logout = () => this._http.post("auth/logout");
	signUp = (params: IRegistrationPayload) =>
		this._http.post<TRegisterResponse>("auth/register", params);
	checkUsername = (params: ICheckUserNamePayload) =>
		this._http.post("user/check_username", params);
	forgotPassword = (params: IForgotPasswordPayload) =>
		this._http.post("auth/password_reset/request", params);
	resetPassword = (params: IResetPasswordPayload) =>
		this._http.post("auth/password_reset", params);
	update = (params: IUpdateUserPayload) =>
		this._http.post<TUpdateResponse>("user/update", params);
	deactivate = () => this._http.post("auth/deactivate_account");
	changePassword = (params: IChangePasswordPayload) =>
		this._http.post<TUpdateResponse>("user/update_password", params);
	getCurrentUser = () => this._http.get<TUserResponse>("user");
	recoverUser = (payload: IRecoverUserPayload) =>
		this._http.post<TUserResponse>("user/recover", payload);
}
