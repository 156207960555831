import "reflect-metadata";
import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import Routes from "routes";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {ThemeProvider} from "@mui/material";
import * as Sentry from "@sentry/react";
import {
	controllers,
	DIContainer,
	providers,
	services,
	stores,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import "assets/css/fonts.css";
import "assets/css/reset.css";
import "assets/css/core.css";
import {theme} from "data/constants/theme";
import {Modals} from "views/components/modals/modals.component";
import {SENTRY_CONFIG} from "data/constants";
import {GameGate} from "views/components/session_gate/game_gate.component";

const root = document.getElementById("root");

Sentry.init(SENTRY_CONFIG);

if (root) {
	DIContainer.load(services, providers, stores, controllers);

	createRoot(root).render(
		<React.StrictMode>
			<ThemeProvider theme={theme}>
				<InjectionProvider container={DIContainer}>
					<GameGate>
						<BrowserRouter>
							<Suspense fallback={null}>
								<Routes />
								<Modals />
							</Suspense>
						</BrowserRouter>
					</GameGate>
				</InjectionProvider>
			</ThemeProvider>
		</React.StrictMode>
	);
} else {
	throw Error("Root element was not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
