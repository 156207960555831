import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";
import {Bindings} from "data/constants/bindings";
import type {ITeamApiProvider} from "data/providers/api/team.api.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IGamebar} from "data/types/team";

export interface IGamebarStore {
	get gamebarData(): IGamebar | null;

	get isLoading(): boolean;

	fetchGamebar(roundId?: number): void;

	fetchGamebarForSelectedRound(): void;
}

@injectable()
export class GamebarStore implements IGamebarStore {
	constructor(
		@inject(Bindings.TeamApiProvider) private _teamProvider: ITeamApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _gamebarData: IGamebar | null = null;

	get gamebarData(): IGamebar | null {
		return this._gamebarData;
	}

	fetchGamebar(roundId?: number): void {
		if (this.isLoading) return;

		this.changeLoadingState(true);
		this._teamProvider
			.fetchGameBar(roundId)
			.then((response) => {
				runInAction(() => {
					this._gamebarData = response.data.success;
				});
			})
			.catch((e) => {
				const error = e as AxiosError<IAxiosApiError, unknown>;
				this._modalsStore.showAxiosError(error);
			})
			.finally(() => {
				this.changeLoadingState(false);
			});
	}

	fetchGamebarForSelectedRound(): void {
		const round = this._roundStore.selectedRound;
		if (!round) {
			return;
		}

		this.fetchGamebar(round.id);
	}

	private changeLoadingState(value: boolean): void {
		runInAction(() => {
			this._isLoading = value;
		});
	}
}
