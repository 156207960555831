import React, {useState} from "react";
import {observer} from "mobx-react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {FormControl, FormHelperText, TextField} from "@mui/material";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IFormResetPasswordController} from "views/components/forms/reset_password/form_reset_password.controller";
import styled from "@emotion/styled";
import {
	FORM_VALIDATION_ELEMENT_CLASSNAME,
	mobileViewQuery,
	PASSWORD_REGEXP_STR,
} from "data/constants";
import {ButtonViewPassword} from "views/components/forms/common/forms_common.component";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/Exist";

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 42px;
		height: 42px;
	}
`;

const Title = styled.h3`
	font-family: var(--fontFamilyBase);
	color: var(--textColor3);
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.2px;
	margin-bottom: 15px;
`;

const FlexCol = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
`;

const UpdateButton = styled.button`
	width: 100%;
	height: 44px;
	border-radius: 32px;
	color: ${({theme}) => theme.colors.white};
	background: ${({theme}) => theme.colors.primary};
	border: 1px solid ${({theme}) => theme.colors.primary};
	transition: all 0.2s;
	font-weight: 600;
	margin-top: 30px;
	font-size: 16px;

	&:hover {
		background: ${({theme}) => theme.colors.white};
		color: ${({theme}) => theme.colors.primary};
	}

	&:disabled {
		opacity: 0.4;
		pointer-events: none;
	}

	@media (${mobileViewQuery}) {
		width: 100%;
		margin-bottom: 0;
	}
`;

const inputProps = {className: FORM_VALIDATION_ELEMENT_CLASSNAME};

export const FormResetPasswordComponent: React.FC = observer(() => {
	const {
		error,
		formValues,
		formErrors,
		isAuthorized,
		isSubmitDisabled,
		isLoading,
		handleFormChange,
		submit,
	} = useViewController<IFormResetPasswordController>(Bindings.FormResetPasswordController);

	const [isPasswordVisible, setPasswordVisible] = useState(false);
	const handleToggleShowPassword = () => setPasswordVisible(!isPasswordVisible);

	const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
	const handleToggleShowConfirmPassword = () =>
		setConfirmPasswordVisible(!isConfirmPasswordVisible);

	const errorPassword = formErrors["password"];
	const errorConfirmPassword = formErrors["confirmPassword"];

	return (
		<form
			onChange={handleFormChange}
			onSubmit={submit}
			noValidate
			className="reset-password-form">
			<Exist when={!isAuthorized}>
				<IconWrapper>
					<CheckCircleIcon color="primary" width={42} height={42} />
				</IconWrapper>
				<Title>{isAuthorized ? "Change" : "Reset"} Password</Title>
			</Exist>
			<FlexCol>
				<FormControl fullWidth>
					<TextField
						label={"Password"}
						name="password"
						type={isPasswordVisible ? "text" : "password"}
						value={formValues.password}
						required
						inputProps={{
							pattern: PASSWORD_REGEXP_STR,
							className: inputProps.className,
						}}
						InputProps={{
							sx: {paddingRight: 0},
							endAdornment: (
								<ButtonViewPassword
									isVisible={isPasswordVisible}
									onClick={handleToggleShowPassword}
								/>
							),
						}}
						error={Boolean(errorPassword)}
						helperText={errorPassword}
					/>
				</FormControl>
				<FormControl fullWidth>
					<TextField
						label={"Confirm Password"}
						name="confirmPassword"
						type={isConfirmPasswordVisible ? "text" : "password"}
						value={formValues.confirmPassword}
						required
						inputProps={{
							pattern: PASSWORD_REGEXP_STR,
							className: inputProps.className,
						}}
						InputProps={{
							sx: {paddingRight: 0},
							endAdornment: (
								<ButtonViewPassword
									isVisible={isConfirmPasswordVisible}
									onClick={handleToggleShowConfirmPassword}
								/>
							),
						}}
						error={Boolean(errorConfirmPassword)}
						helperText={errorConfirmPassword}
					/>
				</FormControl>
			</FlexCol>

			<Exist when={Boolean(error)}>
				<FormHelperText error>{error}</FormHelperText>
			</Exist>
			<UpdateButton type="submit" disabled={isSubmitDisabled}>
				<Exist when={isAuthorized}>
					<Exist when={isLoading}>Updating...</Exist>
					<Exist when={!isLoading}>Update</Exist>
				</Exist>
				<Exist when={!isAuthorized}>
					<Exist when={isLoading}>Resetting...</Exist>
					<Exist when={!isLoading}>Submit</Exist>
				</Exist>
			</UpdateButton>
		</form>
	);
});
