import {IGamebar, IRegion, ITeam} from "data/types/team";
import {AxiosResponse} from "axios";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import {IAxiosApiResponse} from "data/types/global";

interface IFetchTeamResponse {
	team: ITeam;
}

export interface ITeamApiProvider {
	fetchTeam(roundId?: number): Promise<IAxiosApiResponse<IFetchTeamResponse>>;

	updateTeam(params: ITeam["lineup"]): Promise<IAxiosApiResponse<IFetchTeamResponse>>;

	fetchRegions(): Promise<AxiosResponse<IRegion[]>>;

	autoPick(params: ITeam["lineup"]): Promise<IAxiosApiResponse<IFetchTeamResponse>>;

	fetchGameBar(roundId?: number): Promise<IAxiosApiResponse<IGamebar>>;

	makeTrades(
		trades: {driverOut: number; driverIn: number}[]
	): Promise<IAxiosApiResponse<IFetchTeamResponse>>;

	rollbackTrades(): Promise<IAxiosApiResponse<IFetchTeamResponse>>;
}

@injectable()
export class TeamApiProvider implements ITeamApiProvider {
	constructor(
		@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService,
		@inject(Bindings.JsonHTTPClient) private _json: IHttpClientService
	) {}

	public fetchTeam(roundId?: number): Promise<IAxiosApiResponse<IFetchTeamResponse>> {
		return this._http.get<IApiResponse<IFetchTeamResponse>>("/team/show", {roundId});
	}

	public updateTeam(params: ITeam["lineup"]): Promise<IAxiosApiResponse<IFetchTeamResponse>> {
		return this._http.post<IApiResponse<IFetchTeamResponse>>("/team", {lineup: params});
	}

	public fetchRegions(): Promise<AxiosResponse<IRegion[]>> {
		return this._json.get<IRegion[]>("regions.json");
	}

	public autoPick(params: ITeam["lineup"]): Promise<IAxiosApiResponse<IFetchTeamResponse>> {
		return this._http.post<IApiResponse<IFetchTeamResponse>>("/team/autopick", {
			lineup: params,
		});
	}

	public fetchGameBar(roundId?: number): Promise<IAxiosApiResponse<IGamebar>> {
		return this._http.get<IApiResponse<IGamebar>>("ranking/gamebar", {roundId});
	}

	public makeTrades(
		trades: {driverOut: number; driverIn: number}[]
	): Promise<IAxiosApiResponse<IFetchTeamResponse>> {
		return this._http.post<IApiResponse<IFetchTeamResponse>>("/trade/make", {trades});
	}

	public rollbackTrades(): Promise<IAxiosApiResponse<IFetchTeamResponse>> {
		return this._http.post<IApiResponse<IFetchTeamResponse>>("/trade/rollback");
	}
}
