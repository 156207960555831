import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IMatchupStore} from "data/stores/matchup/matchup.store";
import type {IDriverStore} from "data/stores/driver/drivers.store";
import type {ILeague, IMatchupTeam} from "data/providers/api/league.api.provider";
import {TeamPosition} from "data/enums";

interface IParams {
	index: number;
	roundId: number;
}

export interface ILeagueH2hMatchupViewController extends ViewController<IParams> {
	get isViewMatchupAllowed(): boolean;

	get fixture(): number[] | undefined;

	get homeTeam(): IMatchupTeam | undefined;

	get awayTeam(): IMatchupTeam | undefined;

	get isMatchupsExists(): boolean;

	get league(): ILeague | undefined;

	get homeTeamDrivers(): number[];

	get awayTeamDrivers(): number[];

	get homeCaptainImage(): string;

	get awayCaptainImage(): string;

	fetchMatchup: () => void;
}

@injectable()
export class LeagueH2hMatchupViewController implements ILeagueH2hMatchupViewController {
	@observable private _matchupIndex: number | undefined;
	@observable private _roundId: number | undefined;

	constructor(
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.MatchupStore) private _matchupStore: IMatchupStore,
		@inject(Bindings.DriverStore) private _driversStore: IDriverStore
	) {
		makeAutoObservable(this);
	}

	get isViewMatchupAllowed(): boolean {
		return true;
	}

	get fixture(): number[] | undefined {
		if (this._matchupIndex === undefined || this._roundId === undefined) {
			return;
		}
		return this._leaguesStore.selectedLeague?.fixture?.[this._roundId][this._matchupIndex];
	}

	get homeTeam(): IMatchupTeam | undefined {
		if (!this.fixture?.[0]) {
			return;
		}
		return this._matchupStore.getMatchupTeamById(this.fixture[0]);
	}

	get awayTeam(): IMatchupTeam | undefined {
		if (!this.fixture?.[1]) {
			return;
		}
		return this._matchupStore.getMatchupTeamById(this.fixture[1]);
	}

	get homeTeamDrivers(): number[] {
		if (!this.homeTeam?.lineup) {
			return [];
		}
		return this.getTeamDriversFlatten(this.homeTeam);
	}

	get awayTeamDrivers(): number[] {
		if (!this.awayTeam?.lineup) {
			return [];
		}
		return this.getTeamDriversFlatten(this.awayTeam);
	}

	get league(): ILeague | undefined {
		return this._leaguesStore.selectedLeague;
	}

	get isMatchupsExists(): boolean {
		return this._matchupStore.isMatchupsExists;
	}

	public fetchMatchup = (): void => {
		void this._matchupStore.fetchMatchups(this._roundId);
	};

	public get awayCaptainImage(): string {
		const captain = this.awayTeam?.lineup.captain;
		const driver = this._driverStore.getDriverById(captain);
		if (!driver) {
			return "";
		}
		return driver.image;
	}

	public get homeCaptainImage(): string {
		const captainId = this.homeTeam?.lineup.captain;
		const driver = this._driverStore.getDriverById(captainId);
		if (!driver) {
			return "";
		}
		return driver.image;
	}

	init(param: IParams): void {
		this._matchupIndex = param.index;
		this._roundId = param.roundId;

		if (this._driversStore.drivers.length === 0) {
			this._driversStore.fetchDrivers();
		}
	}

	dispose(): void {
		return;
	}

	private getTeamDriversByRegion(team: IMatchupTeam | undefined, region: TeamPosition): number[] {
		if (!team?.lineup || !team?.lineup[region]) {
			return [];
		}
		return [...team.lineup[region], team.lineup.reserve[region]];
	}

	private getTeamDriversFlatten(team: IMatchupTeam | undefined): number[] {
		if (!team) {
			return [];
		}

		return [
			...this.getTeamDriversByRegion(team, TeamPosition.Hunter),
			...this.getTeamDriversByRegion(team, TeamPosition.Riverina),
			...this.getTeamDriversByRegion(team, TeamPosition.Western),
			...this.getTeamDriversByRegion(team, TeamPosition.Metropolitan),
		];
	}
}
