import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface ITitleStore {
	title: string;
	setTitle: (newTitle: string) => void;
}

@injectable()
export class TitleStore implements ITitleStore {
	@observable private _title: string = "";

	constructor() {
		makeAutoObservable(this);
	}

	get title() {
		return this._title;
	}

	@action setTitle(newTitle: string) {
		this._title = newTitle;
	}
}
