import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITitleStore} from "data/stores/title/title.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface ITeamController extends ViewController {
	get isOpen(): boolean;

	get isTeamScoring(): boolean;

	get isGroupRound(): boolean;

	get isTradesRound(): boolean;
}

@injectable()
export class TeamController implements ITeamController {
	@observable private _subscription$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TitleStore) public _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._teamStore.isPoolOpen;
	}

	get isTeamScoring() {
		return this._teamStore.isTeamScoring;
	}

	get isTradesRound() {
		return this._teamStore.isTradesRound;
	}

	get isGroupRound(): boolean {
		if (!this._roundsStore.selectedRound) {
			return false;
		}
		return this._teamStore.hasGroupDrivers(this._roundsStore.selectedRound);
	}

	private get user() {
		return this._userStore.user;
	}

	dispose(): void {
		this._subscription$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		void this._roundsStore.fetchRounds();
		void this._teamStore.fetchRegions();
		this._titleStore.setTitle("My Team");
		this.fetchTeam();

		const subscription = reaction(
			() => [this.user?.recovered],
			() => this.fetchTeam()
		);
		this._subscription$.push(subscription);
	}

	private fetchTeam() {
		if (!this.user?.recovered) {
			return;
		}
		void this._teamStore.fetchTeam();
	}
}
