import {IDriver} from "data/types/team";
import {getNonUndefinedValue} from "data/utils";

type IPriceFilterFunction = (driver: IDriver, budget: number) => boolean;
type IPointsFilterFunction = (driver: IDriver) => boolean;

export abstract class DriverFiltersService {
	public static _pricesMapper = {
		affordable: (driver: IDriver, budget: number) => {
			return driver.cost <= budget;
		},
		"over 1000": (driver: IDriver, budget: number) => {
			return driver.cost >= 1000000;
		},
		"700-1000": (driver: IDriver, budget: number) =>
			DriverFiltersService.isBetweenRange(driver.cost, 700000, 1000000),
		"500-700": (driver: IDriver, budget: number) =>
			DriverFiltersService.isBetweenRange(driver.cost, 500000, 700000),
		"300-500": (driver: IDriver, budget: number) =>
			DriverFiltersService.isBetweenRange(driver.cost, 300000, 500000),
		"under 300": (driver: IDriver, budget: number) => {
			return driver.cost <= 300000;
		},
	} as Record<string, IPriceFilterFunction>;

	private static _pointsMapper = {
		all: (driver: IDriver) => true,
		"over 100": (driver: IDriver) => getNonUndefinedValue(driver.points.total) > 100,
		"50-100": (driver: IDriver) =>
			DriverFiltersService.isBetweenRange(getNonUndefinedValue(driver.points.total), 50, 100),
		"20-50": (driver: IDriver) =>
			DriverFiltersService.isBetweenRange(getNonUndefinedValue(driver.points.total), 20, 50),
		"10-20": (driver: IDriver) =>
			DriverFiltersService.isBetweenRange(getNonUndefinedValue(driver.points.total), 10, 20),
		"under 10": (driver: IDriver) => getNonUndefinedValue(driver.points.total) < 10,
	} as Record<string, IPointsFilterFunction>;

	public static filterByPoints(driver: IDriver, type: string): boolean {
		const sortFunction = this._pointsMapper[type];
		if (!sortFunction || typeof sortFunction !== "function") {
			return true;
		}
		return this._pointsMapper[type](driver);
	}

	public static filterByPrice(driver: IDriver, budget: number, type: string): boolean {
		const sortFunction = this._pricesMapper[type];
		if (!sortFunction || typeof sortFunction !== "function") {
			return true;
		}
		return this._pricesMapper[type](driver, budget);
	}

	public static isBetweenRange(value: number, min: number, max: number) {
		return value >= min && value <= max;
	}
}
