import React, {ElementType, useState} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import styled from "styled-components";
import {IFormRegistrationController} from "views/components/forms/registration/form_registration.controller";
import {
	Button,
	Checkbox as MUICheckbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	OutlinedInput,
	InputBaseComponentProps,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import {
	ButtonViewPassword,
	CheckboxLabelTypography,
} from "views/components/forms/common/forms_common.component";
import {LinkStyled} from "views/components/links/link_styled/link_styled.component";

import {
	EMAIL_REGEXP_STR,
	FORM_VALIDATION_ELEMENT_CLASSNAME,
	PASSWORD_REGEXP_STR,
} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/Exist";
import {PhoneField} from "views/components/PhoneField/phoneField.component";

const Link = styled(LinkStyled)`
	font-size: 14px;
`;

export const Form = styled.form`
	display: flex;
	flex-flow: column;
	gap: 20px;

	a {
		margin: 0;
		color: #00689f;
		text-decoration-color: rgba(0, 104, 159, 0.4);
		text-decoration: none;
		outline: none;
		background-image: linear-gradient(currentcolor, currentcolor);
		background-position: 0 100%;
		background-repeat: no-repeat;
		transition: background-size 0.5s ease 0s;
		background-size: 0 1px;
		cursor: pointer;
		font-size: 14px;
	}
`;

const checkboxSX = {alignItems: "flex-start"};

const inputProps = {minLength: 2, maxLength: 100, className: FORM_VALIDATION_ELEMENT_CLASSNAME};

export const FromRegistration: React.FC = observer(() => {
	const {
		handleSubmitForm,
		handleFormChange,
		handleInputFieldChange,
		handleUsernameFieldChange,
		goToLogin,
		setCountry,
		defaultCountry,
		error,
		formErrors,
		isFormLocked,
		tmpUserData,
		formValidationHelper,
		countryList,
		isSubmitDisabled,
		isAU,
	} = useViewController<IFormRegistrationController>(Bindings.FormRegistrationController);

	const [isPasswordVisible, setPasswordVisible] = useState(false);
	const handleToggleShowPassword = () => setPasswordVisible(!isPasswordVisible);

	const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
	const handleToggleShowConfirmPassword = () =>
		setConfirmPasswordVisible(!isConfirmPasswordVisible);

	return (
		<Form onSubmit={handleSubmitForm} onChange={handleFormChange} noValidate autoComplete="off">
			<Typography sx={{fontWeight: 500, textAlign: "center"}}>Register an account</Typography>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					defaultValue={tmpUserData?.firstName}
					placeholder="First name"
					type="text"
					name="firstName"
					inputProps={inputProps}
					required
					error={Boolean(formErrors["firstName"])}
					helperText={formErrors["firstName"]}
				/>
			</FormControl>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					defaultValue={tmpUserData?.lastName}
					label="Last name"
					placeholder="Last name"
					type="text"
					name="lastName"
					inputProps={inputProps}
					required
					error={Boolean(formErrors["lastName"])}
					helperText={formErrors["lastName"]}
				/>
			</FormControl>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					onInput={handleUsernameFieldChange}
					defaultValue={tmpUserData?.teamName}
					placeholder="Display name"
					type="text"
					name="teamName"
					inputProps={inputProps}
					required
					error={Boolean(formErrors["teamName"])}
					helperText={formErrors["teamName"]}
				/>
			</FormControl>

			<FormControl>
				<TextField
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					defaultValue={tmpUserData?.email}
					label="Email"
					placeholder="Email"
					type="email"
					name="email"
					required
					inputProps={{
						pattern: EMAIL_REGEXP_STR,
						className: inputProps.className,
					}}
					error={Boolean(formErrors["email"])}
					helperText={formErrors["email"]}
				/>
			</FormControl>
			<FormControl>
				<TextField
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					defaultValue={tmpUserData?.email}
					label="Confirm Email"
					placeholder="Confirm Email"
					type="confirmEmail"
					name="confirmEmail"
					required
					inputProps={{
						pattern: EMAIL_REGEXP_STR,
						className: inputProps.className,
					}}
					error={Boolean(formErrors["confirmEmail"])}
					helperText={formErrors["confirmEmail"]}
				/>
			</FormControl>
			<FormControl>
				<TextField
					autoComplete="new-password"
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					defaultValue={tmpUserData?.password}
					label="Password"
					placeholder="Password"
					type={isPasswordVisible ? "text" : "password"}
					name="password"
					required
					inputProps={{
						pattern: PASSWORD_REGEXP_STR,
						className: inputProps.className,
					}}
					InputProps={{
						sx: {paddingRight: 0},
						endAdornment: (
							<ButtonViewPassword
								isVisible={isPasswordVisible}
								onClick={handleToggleShowPassword}
							/>
						),
					}}
					error={Boolean(formErrors["password"])}
					helperText={
						formErrors["password"] || formValidationHelper.errors.byFieldName.password
					}
				/>
			</FormControl>
			<FormControl>
				<TextField
					autoComplete="new-password"
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					label="Confirm Password"
					placeholder="Confirm Password"
					type={isConfirmPasswordVisible ? "text" : "password"}
					name="confirmPassword"
					required
					inputProps={{
						pattern: PASSWORD_REGEXP_STR,
						className: inputProps.className,
					}}
					InputProps={{
						sx: {paddingRight: 0},
						endAdornment: (
							<ButtonViewPassword
								isVisible={isConfirmPasswordVisible}
								onClick={handleToggleShowConfirmPassword}
							/>
						),
					}}
					error={Boolean(formErrors["confirmPassword"])}
					helperText={formErrors["confirmPassword"]}
				/>
			</FormControl>
			<FormControl>
				<InputLabel htmlFor="country">Country *</InputLabel>
				<Select
					id="country"
					name="country"
					disabled={!countryList.length}
					defaultValue={defaultCountry}
					onChange={(event) => setCountry(event.target.value)}
					label="Country"
					placeholder="Country"
					required={true}>
					{countryList.map((country, index) => (
						<MenuItem key={index} value={country.code}>
							{country.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl variant="outlined">
				<InputLabel htmlFor="phone">Phone Number</InputLabel>
				<OutlinedInput
					id="phone"
					name="phone"
					label="Phone number"
					placeholder="04XX-XXX-XXX"
					disabled={isFormLocked}
					onChange={handleInputFieldChange}
					inputComponent={
						PhoneField as unknown as ElementType<InputBaseComponentProps> | undefined
					}
					inputProps={{
						className: inputProps.className,
					}}
				/>
				<Exist when={!formErrors["phone"]}>
					<FormHelperText>
						This will be used to contact you if you are a winner
					</FormHelperText>
				</Exist>
				<Exist when={Boolean(formErrors["phone"])}>
					<FormHelperText error>{formErrors["phone"]}</FormHelperText>
				</Exist>
			</FormControl>
			<Exist when={isAU}>
				<FormControl>
					<TextField
						autoComplete="new-postcode"
						disabled={isFormLocked}
						onInput={handleInputFieldChange}
						label="Postcode"
						placeholder="Postcode"
						type="number"
						name="postcode"
						required={isAU}
						inputProps={{
							className: inputProps.className,
							maxLength: 4,
						}}
						error={Boolean(formErrors["postcode"])}
						helperText={formErrors["postcode"]}
					/>
				</FormControl>
			</Exist>
			<FormControl>
				<TextField
					autoComplete="new-dob"
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					type="date"
					name="dob"
					label="Date of birth"
					required
					InputLabelProps={{shrink: true}}
					inputProps={{
						className: inputProps.className,
					}}
					error={Boolean(formErrors["dob"])}
					helperText={formErrors["dob"]}
				/>
			</FormControl>
			<FormControl>
				<TextField
					autoComplete="new-fav-driver"
					disabled={isFormLocked}
					onInput={handleInputFieldChange}
					label="Favourite Driver"
					placeholder="Favourite Driver"
					type="text"
					name="favDriver"
					inputProps={{
						className: inputProps.className,
					}}
				/>
			</FormControl>

			<FormControlLabel
				disabled={isFormLocked}
				labelPlacement={"end"}
				sx={checkboxSX}
				control={
					<MUICheckbox
						disabled={isFormLocked}
						id="terms"
						checked={tmpUserData?.terms}
						onChange={handleInputFieldChange}
						name="terms"
						inputProps={{className: FORM_VALIDATION_ELEMENT_CLASSNAME}}
						required={true}
					/>
				}
				label={
					<CheckboxLabelTypography
						sx={{marginTop: "10px"}}
						component="span"
						display="inline-block"
						dangerouslySetInnerHTML={{
							__html: `By selecting this box I agree to the <a href="/help/terms_conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions </a>`,
						}}
					/>
				}
			/>
			<FormControlLabel
				disabled={isFormLocked}
				labelPlacement="end"
				sx={checkboxSX}
				control={
					<MUICheckbox
						disabled={isFormLocked}
						id="isDriver"
						checked={Boolean(tmpUserData?.isDriver)}
						onChange={handleInputFieldChange}
						name="isDriver"
						inputProps={{className: FORM_VALIDATION_ELEMENT_CLASSNAME}}
					/>
				}
				label={
					<CheckboxLabelTypography
						sx={{marginTop: "10px"}}
						component="span"
						display="inline-block"
						dangerouslySetInnerHTML={{
							__html: `I am a driver in Harness Racing NSW`,
						}}
					/>
				}
			/>
			<FormControlLabel
				disabled={isFormLocked}
				labelPlacement="end"
				sx={checkboxSX}
				control={
					<MUICheckbox
						disabled={isFormLocked}
						id="notifications"
						checked={Boolean(tmpUserData?.notifications)}
						onChange={handleInputFieldChange}
						name="notifications"
						inputProps={{className: FORM_VALIDATION_ELEMENT_CLASSNAME}}
					/>
				}
				label={
					<CheckboxLabelTypography
						sx={{marginTop: "10px"}}
						component="span"
						display="inline-block"
						dangerouslySetInnerHTML={{
							__html: `I would like to sign up to emails from Harness Racing NSW and their sponsors`,
						}}
					/>
				}
			/>

			<Exist when={Boolean(error)}>
				<FormHelperText error>{error}</FormHelperText>
			</Exist>
			<Button
				size="large"
				fullWidth
				variant="contained"
				disabled={isFormLocked || isSubmitDisabled}
				type="submit">
				<Exist when={!isFormLocked}>Register</Exist>
				<Exist when={isFormLocked}>
					<CircularProgress size={20} color="inherit" sx={{mr: 1.5}} />
					Loading...
				</Exist>
			</Button>
			<Typography
				sx={{textAlign: "center", fontSize: 14, fontFamily: "var(--fontFamilyBase)"}}>
				Already have an account? <Link onClick={goToLogin}>Log In</Link>
			</Typography>
		</Form>
	);
});
