import styled from "@emotion/styled";
import media from "assets/css/media";
import {NavLink} from "react-router-dom";

export const LeagueWrapper = styled.div`
	border: none;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	background: #fff;
	color: var(--baseBlack);
	display: flex;
	height: 125px;
	justify-content: space-between;
	padding: 20px;
	position: relative;
	margin: 20px 0;
	width: 100%;

	@media (max-width: 728px) {
		padding: 20px 15px;
	}
`;

export const LeagueButtonsWrapper = styled(LeagueWrapper)`
	flex-direction: column;
	height: auto;
`;

export const LeagueName = styled.div`
	font-size: 16px;
	color: var(--textColor1);
	margin-bottom: 0;
	font-family: var(--fontFamilyBase);
	font-weight: 700;
	display: inline-block;

	@media (max-width: 728px) {
		font-size: 18px;
	}
	@media (max-width: ${media.mobile}) {
		font-size: 16px;
	}
`;

export const LeagueButtonsDescription = styled.p`
	font-size: 14px;
	line-height: 18px;
	margin: 10px 0 20px 0;
	font-family: var(--fontFamilyBase);
`;

export const buttonLinkStyles = `
    width: 100%;
    max-width: 210px;
    display: flex;
    font-weight: 500;`;

export const CreateLeagueButton = styled(NavLink)`
	font-family: ${({theme}) => theme.typography.fontFamily};
	display: block;
	color: #004d76;
	border: 1px solid #004d76;
	height: 44px;
	max-width: 360px;
	border-radius: 32px;
	line-height: 40px;
	text-align: center;
	transition: all 0.25s linear;

	&:hover {
		background: #f2f2f5;
		border: 1px solid #006da6;
		color: #006da6;
	}

	@media (max-width: ${media.mobile}) {
		width: 100%;
	}
`;
