import React, {Fragment, useEffect, useState} from "react";
import styled from "styled-components";
import {useViewController} from "data/services/locator";
import {INavController} from "./nav.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/Exist";
import {NavLink} from "react-router-dom";
import {ListItemButton, ListItemText, Typography, useMediaQuery} from "@mui/material";
import logo from "assets/img/hrnsw-logo.svg";
import {mobileViewQuery} from "data/constants";
import {observer} from "mobx-react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useLocation} from "react-router";

const HeaderWrap = styled.div`
	display: flex;
	background: #004d76;
	width: 100%;
	border-bottom: 4px solid var(--primaryColor);
`;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	height: 72px;
	color: var(--textColor4);
	background: #004d76;
	padding: 0;
	max-width: 1280px;
	margin: 0 auto;

	&.off-season {
		@media (${mobileViewQuery}) {
			max-height: 50px;
			padding: 0 24px;
			border-bottom: 2px solid #004d76 !important;
			min-width: 100% !important;
			justify-content: flex-start !important;

			p {
				font-size: 14px !important;
			}
		}
	}
`;

const FlexRow = styled.div`
	display: flex;
	align-items: center;
`;

const InnerWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	.active {
		color: var(--secondaryColor);
	}
`;

const StyledListItem = styled(ListItemButton)`
	min-width: 150px;
	height: 100%;
	z-index: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center !important;
	padding: 0;
	font-weight: 600;

	@media (${mobileViewQuery}) {
		max-height: 50px;
		border-bottom: 2px solid #004d76 !important;
		min-width: 100% !important;
		justify-content: flex-start !important;

		&.child {
			border-bottom: none !important;
		}
	}
`;

const LogoSection = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 20px;

	img {
		height: auto;
		width: 250px;
	}

	@media (${mobileViewQuery}) {
		img {
			width: 150px;
		}
	}
`;

const ListItemFull = styled(ListItemButton)`
	width: 100%;
	height: 48px;
	position: relative;

	svg {
		margin-right: 20px;
	}
`;

const ListItemUser = styled(ListItemFull)`
	height: 72px;
	padding-bottom: 16px;
	padding-top: 16px;

	span {
		font-weight: 600 !important;
	}
`;

const LinkSpan = styled(Typography)`
	line-height: 32px !important;
	font-weight: 600 !important;
	text-align: center !important;
	@media (${mobileViewQuery}) {
		color: white;
		font-size: 20px !important;
	}
`;

const ChildLinkSpan = styled(LinkSpan)`
	font-size: 14px;
	font-weight: 400 !important;
	@media (${mobileViewQuery}) {
		font-size: 16px !important;
	}
`;

const OuterContainer = styled.div`
	position: relative;
	z-index: 1;
	height: 72px;

	.sub-menu {
		display: none;
	}

	&:hover {
		.sub-menu {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			top: 72px;
			height: fit-content;
			left: -25px;
			width: 150px;
			background: #004d76;
			padding: 5px 5px 10px 5px;
			border-top: 4px solid var(--primaryColor);
		}
	}

	@media (${mobileViewQuery}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: unset;
		justify-content: flex-start;
		min-width: 100%;
		a {
			width: 100%;
		}
	}
`;

const Submenu = styled.div`
	padding: 0 10px;
`;

const MobileHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 50px;
	background: var(--primaryColor);
	width: 100%;
	position: relative;
`;

const HamburgerButton = styled.button`
	width: 30px;
	height: 30px;
	outline: none;
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	position: absolute;
	left: 10px;
	border: none;
`;

const MenuOverlay = styled.div<{
	isOpen: boolean;
	hasBar: boolean;
}>`
	width: 100%;
	height: 100vh;
	position: absolute;
	left: ${({isOpen}) => (!isOpen ? "-100vw" : "0px")};
	top: ${({hasBar}) => (hasBar ? "100px" : "50px")};
	transition: all 0.2s;
	background: var(--primaryColor);
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 10;
`;

const ArrowDownButton = styled.button`
	border: none;
	background: none;
	padding: 0 10px 0 0;
	transition: all 0.2s;

	&.not-expandable {
		display: none;
	}

	&.expandable {
		display: block;
		position: absolute;
		top: 10px;
		right: 5px;
		z-index: 5;
	}
`;

const arrowToShow = (isActive: boolean) => {
	return isActive ? (
		<ExpandLess style={{fill: "#fff", width: "1.2em", height: "1.2em"}} />
	) : (
		<ExpandMore style={{fill: "#fff", width: "1.2em", height: "1.2em"}} />
	);
};

const MobileNav: React.FC = observer(() => {
	const location = useLocation();
	const {
		isAuthorized,
		mainMenuList,
		staticPagesMenuList,
		menuOpen,
		isNotificationBarExists,
		goToRegister,
		goToLogin,
		logout,
		toggleMenu,
		closeMenu,
	} = useViewController<INavController>(Bindings.NavController);

	const [openSubMenu, setSubMenu] = useState("");
	const isActive = (val: string) => val === openSubMenu;

	useEffect(() => {
		closeMenu();
	}, [location, closeMenu]);

	return (
		<Fragment>
			<MobileHeader>
				<HamburgerButton
					name="menu-button"
					aria-label="menu-button"
					onClick={toggleMenu}
					type="button">
					<Exist when={menuOpen}>
						<CloseIcon sx={{fill: "white"}} />
					</Exist>
					<Exist when={!menuOpen}>
						<MenuIcon sx={{fill: "white"}} />
					</Exist>
				</HamburgerButton>
				<LogoSection>
					<img src={logo} alt="HRNSW Logo" />
				</LogoSection>
			</MobileHeader>

			<MenuOverlay isOpen={menuOpen} hasBar={isNotificationBarExists}>
				<Exist when={isAuthorized}>
					{mainMenuList.map((item, index) => (
						<OuterContainer key={index}>
							<NavLink to={item.url}>
								<StyledListItem sx={{minWidth: "100px"}}>
									<LinkSpan>{item.label}</LinkSpan>
								</StyledListItem>
							</NavLink>
							<Exist when={isActive(item.label)}>
								{item.children?.map((subMenuItem) => (
									<NavLink key={subMenuItem.url} to={subMenuItem.url}>
										<StyledListItem
											sx={{
												minWidth: "100px",
												paddingLeft: "10px",
												paddingRight: "5px",
											}}
											className="child">
											<ChildLinkSpan>{subMenuItem.label}</ChildLinkSpan>
										</StyledListItem>
									</NavLink>
								))}
							</Exist>
							<ArrowDownButton
								name="expand"
								aria-label="expand"
								onClick={() => setSubMenu(isActive(item.label) ? "" : item.label)}
								className={item.children ? "expandable" : "not-expandable"}>
								{arrowToShow(isActive(item.label))}
							</ArrowDownButton>
						</OuterContainer>
					))}
				</Exist>
				<Exist when={!isAuthorized}>
					<StyledListItem onClick={goToLogin} sx={{minWidth: "100px"}}>
						<LinkSpan>Login</LinkSpan>
					</StyledListItem>
					<StyledListItem onClick={goToRegister} sx={{minWidth: "100px"}}>
						<LinkSpan>Register</LinkSpan>
					</StyledListItem>
				</Exist>
				{staticPagesMenuList.map(({label, url, children}, index) => (
					<OuterContainer
						key={index}
						onClick={(e) => setSubMenu(isActive(label) ? "" : label)}>
						<NavLink to={url} onClick={(e) => setSubMenu(label)}>
							<StyledListItem sx={{minWidth: "100px"}}>
								<LinkSpan>{label}</LinkSpan>
							</StyledListItem>
						</NavLink>
						<Exist when={isActive(label)}>
							{children?.map((subMenuItem) => (
								<NavLink key={subMenuItem.url} to={subMenuItem.url}>
									<StyledListItem
										sx={{
											minWidth: "100px",
											paddingLeft: "10px",
											paddingRight: "5px",
											marginLeft: "10px;",
										}}
										className="child">
										<ChildLinkSpan>{subMenuItem.label}</ChildLinkSpan>
									</StyledListItem>
								</NavLink>
							))}
						</Exist>
						<ArrowDownButton
							name="expand-button"
							aria-label="expand-button"
							onClick={() => setSubMenu(isActive(label) ? "" : label)}
							className={children ? "expandable" : "not-expandable"}>
							{arrowToShow(isActive(label))}
						</ArrowDownButton>
					</OuterContainer>
				))}
				<Exist when={isAuthorized}>
					<OuterContainer>
						<NavLink to="/my-account">
							<StyledListItem>
								<LinkSpan>My Account</LinkSpan>
							</StyledListItem>
						</NavLink>
					</OuterContainer>
					<StyledListItem onClick={logout} sx={{minWidth: "100px"}}>
						<LinkSpan>Logout</LinkSpan>
					</StyledListItem>
				</Exist>
			</MenuOverlay>
		</Fragment>
	);
});

export const Nav: React.FC = () => {
	const controller = useViewController<INavController>(Bindings.NavController);
	const {
		isPrivateLocked,
		isAuthorized,
		mainMenuList,
		staticPagesMenuList,
		goToRegister,
		goToLogin,
	} = controller;
	const hideDrawer = () => controller.toggleDraw();

	const isMobile = useMediaQuery(`(${mobileViewQuery})`);

	if (isPrivateLocked) {
		return (
			<HeaderWrap>
				<Wrapper className="off-season">
					<InnerWrapper>
						<LogoSection>
							<img src={logo} alt="HRNSW Logo" />
						</LogoSection>
						<NavLink to="/">
							<StyledListItem sx={{minWidth: "100px"}}>
								<LinkSpan>Home</LinkSpan>
							</StyledListItem>
						</NavLink>
					</InnerWrapper>
				</Wrapper>
			</HeaderWrap>
		);
	}

	if (isMobile) {
		return <MobileNav />;
	}

	return (
		<HeaderWrap>
			<Wrapper>
				<InnerWrapper>
					<FlexRow>
						<NavLink to={"/"}>
							<LogoSection>
								<img src={logo} alt="HRNSW Logo" />
							</LogoSection>
						</NavLink>
						<Exist when={isAuthorized}>
							{mainMenuList.map((item, index) => (
								<OuterContainer key={index}>
									<NavLink to={item.url}>
										<StyledListItem sx={{minWidth: "100px"}}>
											<LinkSpan>{item.label}</LinkSpan>
										</StyledListItem>
									</NavLink>
									<Exist when={Boolean(item.children)}>
										<Submenu className="sub-menu">
											{item.children?.map((subMenuItem) => (
												<NavLink key={subMenuItem.url} to={subMenuItem.url}>
													<StyledListItem
														onClick={hideDrawer}
														sx={{
															minWidth: "100px",
															paddingLeft: "10px",
															paddingRight: "5px",
														}}>
														<ChildLinkSpan>
															{subMenuItem.label}
														</ChildLinkSpan>
													</StyledListItem>
												</NavLink>
											))}
										</Submenu>
									</Exist>
								</OuterContainer>
							))}
						</Exist>
						<Exist when={!isAuthorized}>
							<StyledListItem onClick={goToLogin} sx={{minWidth: "100px"}}>
								<LinkSpan>Login</LinkSpan>
							</StyledListItem>
						</Exist>
						<Exist when={!isAuthorized}>
							<StyledListItem onClick={goToRegister} sx={{minWidth: "100px"}}>
								<LinkSpan>Register</LinkSpan>
							</StyledListItem>
						</Exist>
						{staticPagesMenuList.map(({label, url, children}, index) => (
							<OuterContainer key={index}>
								<NavLink to={url}>
									<StyledListItem onClick={hideDrawer} sx={{minWidth: "100px"}}>
										<LinkSpan>{label}</LinkSpan>
									</StyledListItem>
								</NavLink>
								<Exist when={Boolean(children)}>
									<Submenu className="sub-menu">
										{children?.map((subMenuItem) => (
											<NavLink key={subMenuItem.url} to={subMenuItem.url}>
												<StyledListItem
													onClick={hideDrawer}
													sx={{
														minWidth: "100px",
														paddingLeft: "10px",
														paddingRight: "5px",
													}}>
													<ChildLinkSpan>
														{subMenuItem.label}
													</ChildLinkSpan>
												</StyledListItem>
											</NavLink>
										))}
									</Submenu>
								</Exist>
							</OuterContainer>
						))}
					</FlexRow>

					<Exist when={isAuthorized}>
						<NavLink to="/my-account">
							<ListItemUser>
								<ListItemText sx={{fontWeight: 600}}>My Account</ListItemText>
							</ListItemUser>
						</NavLink>
					</Exist>
				</InnerWrapper>
			</Wrapper>
		</HeaderWrap>
	);
};
