import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import {HeadsPosition} from "data/enums";

interface IInitProps {
	driverId: number;
}

export interface IHeadsLabelController extends ViewController<IInitProps> {
	get isDriverCaptain(): boolean;

	get isDriverViceCaptain(): boolean;
}

@injectable()
export class HeadsLabelController implements IHeadsLabelController {
	@observable private _driverId: number = 0;
	constructor(@inject(Bindings.TeamStore) private _teamStore: ITeamStore) {
		makeAutoObservable(this);
	}

	get isDriverCaptain(): boolean {
		if (!this._driverId) {
			return false;
		}
		return this._teamStore.getIsDriverRole(this._driverId, HeadsPosition.Captain);
	}

	get isDriverViceCaptain(): boolean {
		if (!this._driverId) {
			return false;
		}
		return this._teamStore.getIsDriverRole(this._driverId, HeadsPosition.ViceCaptain);
	}

	dispose(): void {
		return;
	}

	init(param: IInitProps): void {
		if (!param.driverId) {
			return;
		}
		this._driverId = param.driverId;
	}
}
