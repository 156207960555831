import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {
	IJoinedUser,
	ILeaguesStore,
	IRemoveUserModalPayload,
	IUpdateLeague,
} from "data/stores/leagues/leagues.store";
import type {ILeague} from "data/providers/api/league.api.provider";
import {H2HFinalType, LeaguePrivacy, LeagueStatus, LeagueType, ModalType} from "data/enums";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {IRound} from "data/providers/json/json.provider";
import {SelectChangeEvent} from "@mui/material";
import type {ITitleStore} from "data/stores/title/title.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import React from "react";
import {first} from "lodash";
import {H2H_MAXIMUM_TEAMS, H2H_MINIMUM_TEAMS} from "data/constants";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";

const EMPTY_LEAGUE = {
	id: 0,
	name: "",
	userId: 0,
	code: "",
	privacy: LeaguePrivacy.Private,
	status: LeagueStatus.Scheduled,
	type: LeagueType.Regular,
	startRound: 0,
	description: "",
	teamCount: 0,
	teamLimit: 0,
	userName: "",
};

interface IInit {
	leagueId: number;
}

export interface ISettingsLeagueController extends ViewController<IInit> {
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	updateLeague: (event: React.MouseEvent<HTMLButtonElement>) => void;
	handleSelectChange: (e: SelectChangeEvent) => void;
	handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
	fetchUsers: () => void;
	removeUser: (payload: IRemoveUserModalPayload) => void;
	handleTeamLimitSliderChange: (event: Event, newValue: number | number[]) => void;
	handleTeamLimitInputBlur: (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;

	get leagues(): ILeague[];

	get nextPage(): boolean;

	get scheduledRounds(): IRound[];

	get isLeagueDataChanged(): boolean;

	get joinedUsers(): IJoinedUser[];

	get isRequestInProgress(): boolean;

	get user(): IUser | undefined;

	get isInProcess(): boolean;

	get selectedLeague(): ILeague;

	get noScheduleRounds(): boolean;

	get isNoLeagueOrLoading(): boolean;
}

@injectable()
export class SettingsLeagueController implements ISettingsLeagueController {
	@observable _tmpLeague: ILeague = this._leaguesStore.selectedLeague || EMPTY_LEAGUE;

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isInProcess: boolean = false;

	get isInProcess(): boolean {
		return this._isInProcess;
	}

	get leagues() {
		return this._leaguesStore.list;
	}

	get nextPage() {
		return this._leaguesStore.nextPage;
	}

	get selectedLeague() {
		return this._tmpLeague;
	}

	get isLeagueDataChanged() {
		const currentName = this._tmpLeague.name;
		const currentStart = this._tmpLeague.startRound;
		return currentName !== this.league?.name || currentStart !== this.league?.startRound;
	}

	get league() {
		return this._leaguesStore.selectedLeague;
	}

	get user() {
		return this._userStore.user;
	}

	get scheduledRounds() {
		if (this.league?.type === LeagueType.H2H) {
			return this._roundsStore.h2hLeagueRounds(this.league.finalsFormat);
		}
		return this._roundsStore.scheduledRounds;
	}

	get joinedUsers() {
		return this._leaguesStore.joinedUsers;
	}

	get isRequestInProgress() {
		return this._leaguesStore.isRequestInProgress;
	}

	get noScheduleRounds() {
		return this.scheduledRounds.length === 0;
	}

	get isNoLeagueOrLoading() {
		return this._tmpLeague.code === "";
	}

	@action handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = e.target;
		if (!name) {
			return;
		}

		this._tmpLeague = {
			...this._tmpLeague,
			[name]: value,
		};
	};
	@action handleSelectChange = (e: SelectChangeEvent) => {
		const {name, value} = e.target;
		this._tmpLeague = {
			...this._tmpLeague,
			[name]: value,
		};
	};

	@action removeUser = (payload: IRemoveUserModalPayload) => {
		this.openConfirmationModal(payload);
	};

	@action updateLeague = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (this.isInProcess) return;

		try {
			this.setInProcess(true);
			const payload: IUpdateLeague = {
				name: this._tmpLeague.name,
				teamLimit: this._tmpLeague?.teamLimit,
				startRound: this.getStartRound(),
				finalsFormat: this._tmpLeague?.finalsFormat,
			};
			await this._leaguesStore.updateLeague(payload, this._tmpLeague.id);
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		} finally {
			this.setInProcess(false);
		}
	};

	@action fetchUsers = () => {
		void this._leaguesStore.fetchJoinedUsers();
	};

	dispose(): void {
		return;
	}

	init(params: IInit): void {
		void this._roundsStore.fetchRounds(true);
		this.setTitle();
		if (params.leagueId) {
			this._leaguesStore.clearUsers();
			this.fetchUsers();
			runInAction(() => {
				this._tmpLeague = this._leaguesStore.selectedLeague || this._tmpLeague;
			});
		}
	}

	setTitle() {
		if (!this._leaguesStore.selectedLeague) {
			return;
		}
		void this._titleStore.setTitle(this._leaguesStore.selectedLeague?.name);
	}

	handleTeamLimitSliderChange = (event: Event, newValue: number | number[]): void => {
		this._tmpLeague.teamLimit = newValue as number;
		this.checkH2hFinals();
	};

	@action
	handleTeamLimitInputBlur = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (this._tmpLeague.teamLimit === undefined) {
			return;
		}
		if (this._tmpLeague.teamLimit > H2H_MAXIMUM_TEAMS) {
			this._tmpLeague.teamLimit = H2H_MAXIMUM_TEAMS;
			return;
		}

		if (this._tmpLeague.teamLimit < H2H_MINIMUM_TEAMS) {
			this._tmpLeague.teamLimit = H2H_MINIMUM_TEAMS;
		}
	};
	@action
	handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
		const {name} = event.target;
		if (!name || !Object.keys(this._tmpLeague).includes(name)) {
			return;
		}

		this._tmpLeague = {
			...this._tmpLeague,
			[name]: value,
		};
		this.checkH2hFinals();
	};

	private openConfirmationModal(payload: IRemoveUserModalPayload) {
		this._modalsStore.showModal(ModalType.LEAGUE_REMOVE_USER, payload);
	}

	private getStartRound() {
		if (this._tmpLeague.startRound !== 0) {
			return this._tmpLeague.startRound;
		}
		return first(this.scheduledRounds)?.id || 0;
	}

	private checkH2hFinals() {
		const value = Number(this._tmpLeague.teamLimit);
		if (value < 8 && this._tmpLeague.finalsFormat === H2HFinalType.Top8) {
			runInAction(() => {
				this._tmpLeague.finalsFormat = H2HFinalType.Top4;
			});
		}
	}

	private setInProcess(state: boolean) {
		runInAction(() => {
			this._isInProcess = state;
		});
	}
}
