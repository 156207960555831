import React from "react";
import {Button, Modal, Typography} from "@mui/material";
import {isEqual} from "lodash";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {ModalType} from "data/enums";
import {observer} from "mobx-react";
import {ModalBox, ModalContent} from "views/components/modals/modal_common.component";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/Exist";
import styled from "@emotion/styled";
import {IModalMessage} from "data/types/modals";

const SModalContent = styled(ModalContent)`
	text-align: center;
`;

export const ModalError: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController);
	const {visibleModal, modalContent, close} = controller;
	const isVisible = isEqual(visibleModal, ModalType.ERROR);

	if (!isVisible) {
		return null;
	}

	const title = (modalContent as IModalMessage)?.title;
	const message = (modalContent as IModalMessage)?.message;

	return (
		<Modal open={isVisible} onClose={close}>
			<ModalBox>
				<SModalContent>
					<Exist when={Boolean(title)}>
						<Typography align="center" variant="h5">
							{title}
						</Typography>
					</Exist>
					<Exist when={Boolean(message)}>
						<Typography sx={{mb: 3}}>{message}</Typography>
					</Exist>
					<Button fullWidth color="error" variant="contained" onClick={close}>
						Close
					</Button>
				</SModalContent>
			</ModalBox>
		</Modal>
	);
});
