import {ViewController} from "data/types/structure";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import React from "react";
import type {IResetPasswordPayload, IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {getErrorMessageFromAxiosResponse, trackSentryErrors} from "data/utils";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {ModalType} from "data/enums";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";

export interface IFormResetPasswordValues extends IResetPasswordPayload {
	confirmPassword: string;
}

export interface IResetPasswordForm extends HTMLFormElement {
	code: HTMLInputElement;
	email: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
}

export interface IFormResetPasswordController extends ViewController {
	submit: (event: React.SyntheticEvent<IResetPasswordForm>) => void;
	handleFormChange: ({target}: React.ChangeEvent<IResetPasswordForm>) => void;
	error: string | undefined;

	get formErrors(): Record<string, string>;

	get formValues(): IFormResetPasswordValues;

	get isAuthorized(): boolean;

	get isSubmitDisabled(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class FormResetPasswordController implements IFormResetPasswordController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _error?: string;

	get error() {
		return this._error;
	}

	@observable private _formValues: IFormResetPasswordValues = {
		token: this.token || "",
		password: "",
		confirmPassword: "",
	};

	get formValues() {
		return this._formValues;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get token() {
		return new URLSearchParams(window.location.search).get("token");
	}

	get isSubmitDisabled() {
		if (this.isLoading) {
			return true;
		}
		return (
			this.formValues.password.length === 0 || this.formValues.confirmPassword.length === 0
		);
	}

	@action public submit = (event: React.SyntheticEvent<IResetPasswordForm>) => {
		event.preventDefault();
		this._error = undefined;

		const {checkValidity, setFormFieldError, clearFormFieldError, errors} =
			this._validationHelper;
		const form = event.currentTarget;
		const {confirmPassword, password} = form;

		clearFormFieldError(confirmPassword.name);
		checkValidity(form);

		if (confirmPassword.value !== password.value) {
			setFormFieldError(confirmPassword.name, errors.password_mismatch);
		}

		if (!this._validationHelper.isValid) {
			return;
		}

		this.updateLoadingState(true);

		if (this.isAuthorized) {
			this._userStore
				.changePassword({password: this.formValues.password})
				.then(this.onSuccess)
				.catch(this.onError)
				.finally(this.onFinally);
			return;
		}
		this._userStore
			.resetPassword({password: this.formValues.password, token: this.formValues.token})
			.then(this.onSuccess)
			.catch(this.onError)
			.finally(this.onFinally);
	};

	@action
	public handleFormChange = ({target: {name, value}}: React.ChangeEvent<HTMLFormElement>) => {
		this._error = undefined;
		this._validationHelper.clearFormFieldError(name);

		this._formValues = {
			...this._formValues,
			[name]: value as string,
		};
	};

	public dispose(): void {
		return;
	}

	public init(): void {
		return;
	}

	private onSuccess = () => {
		this._formValues = {
			token: this.token || "",
			password: "",
			confirmPassword: "",
		};
		this._modalsStore.hideModal();
		this._modalsStore.showModal(ModalType.PASSWORD_CHANGED);
	};

	@action private onError = (event: AxiosError<IAxiosApiError, unknown>) => {
		trackSentryErrors(event, {}, "form reset password");
		this._error = getErrorMessageFromAxiosResponse(event);
	};

	private onFinally = () => {
		this.updateLoadingState(false);
	};

	private updateLoadingState(value: boolean) {
		runInAction(() => {
			this._isLoading = value;
		});
	}
}
