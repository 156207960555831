import styled from "@emotion/styled";
import {Button} from "@mui/material";
import {NavLink} from "react-router-dom";

const ButtonPrimaryBase = styled(Button)`
	min-width: 120px;
	font-weight: 600;
	font-size: 16px;
	font-family: var(--fontFamilyBase);
	border-radius: 32px;
	text-transform: initial;
	transition: 0.25s;

	font-family: ${({theme}) => theme.typography.fontFamily};
	background: ${({theme}) => theme.button.primary.default.background};
	color: ${({theme}) => theme.button.primary.default.color};

	&:hover {
		background: ${({theme}) => theme.button.primary.hover.background};
		color: ${({theme}) => theme.button.primary.hover.color};
	}

	&:disabled {
		background: ${({theme}) => theme.button.primary.disabled.background};
		color: ${({theme}) => theme.button.primary.disabled.color};
		opacity: ${({theme}) => theme.button.primary.disabled.opacity};
	}
`;

export const ButtonBaseNavLink = styled(NavLink)`
	background: ${({theme}) => theme.button.primary.default.background};
	color: ${({theme}) => theme.button.primary.default.color};

	&:hover {
		background: ${({theme}) => theme.button.primary.hover.background};
		color: ${({theme}) => theme.button.primary.hover.color};
	}

	&:disabled {
		background: ${({theme}) => theme.button.primary.disabled.background};
		color: ${({theme}) => theme.button.primary.disabled.color};
		opacity: ${({theme}) => theme.button.primary.disabled.opacity};
	}
`;

export const ButtonSecondaryBase = styled(Button)`
	font-family: ${({theme}) => theme.typography.fontFamily};
	background: ${({theme}) => theme.button.secondary.default.background};
	color: ${({theme}) => theme.button.secondary.default.color};
	border: 2px solid ${({theme}) => theme.button.secondary.default.color};

	&:hover {
		background: ${({theme}) => theme.button.secondary.hover.background};
		color: ${({theme}) => theme.button.secondary.hover.color};
		border-color: ${({theme}) => theme.button.secondary.hover.color};
	}

	&:disabled {
		background: ${({theme}) => theme.button.secondary.disabled.background};
		color: ${({theme}) => theme.button.secondary.disabled.color};
		opacity: ${({theme}) => theme.button.secondary.disabled.opacity};
		border-color: ${({theme}) => theme.button.secondary.disabled.color};
	}
`;

export const SecondaryNavLinkBase = styled(NavLink)`
	font-family: ${({theme}) => theme.typography.fontFamily};
	background: ${({theme}) => theme.button.secondary.default.background};
	color: #004d76;
	border: 1px solid #004d76;
	height: 44px;
	max-width: 360px;
	border-radius: 32px;
	line-height: 40px;
	text-align: center;
	transition: all 0.25s linear;

	&:hover {
		background: #f2f2f5;
		border: 1px solid #006da6;
		color: #006da6;
	}

	&:disabled {
		background: ${({theme}) => theme.button.secondary.disabled.background};
		color: ${({theme}) => theme.button.secondary.disabled.color};
		opacity: ${({theme}) => theme.button.secondary.disabled.opacity};
		border-color: ${({theme}) => theme.button.secondary.disabled.color};
	}
`;

ButtonPrimaryBase.defaultProps = {
	variant: "contained",
};

/**
 * Type casting is required because of MUI issue
 * https://github.com/mui/material-ui/issues/13921#issuecomment-484133463
 */
export const ButtonPrimary = ButtonPrimaryBase as typeof Button;

export const ActionButton = styled(ButtonPrimary)`
	width: 30px;
	height: 30px;
	min-height: 30px;
	min-width: unset !important;
	padding: 0 !important;
	border-radius: 50%;

	&:disabled {
		opacity: 1 !important;
		background: #aeaeb2 !important;
		pointer-events: none !important;
	}
`;

export const ButtonPure = styled(Button)`
	outline: none;
	background: none;
	border: none;
	cursor: pointer;

	padding: 0;
	margin: 0;
	width: auto;
	min-width: unset;
`;

export const ModalCloseButton = styled(ButtonPure)`
	position: absolute;
	right: 20px;
	top: 20px;
	color: var(--primaryTextColor);
`;

export const BaseButton = styled.button`
	border: 0;
	background: transparent;
	appearance: none;
	outline: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
	font-size: 18px;
`;

export const ButtonPrimaryAlt = styled(ButtonPrimary)`
	--btnBackground: #3230be;
	--btnHoverBackground: #4745c0;
	--btnDisabledBackground: #7371c4;
`;

export const ButtonSecondary = styled(ButtonPrimary)`
	--btnTextColor: #00689f;
	--btnHoverTextColor: #00689f;
	--btnDisabledTextColor: var(--btnHoverTextColor);
	--btnBackground: white;
	--btnHoverBackground: #ff6c4f;
	--btnDisabledBackground: #ffcdc3;
	--btnBorderColor: #00689f;
	--btnDisableOpacity: 0.25;

	border: 1px solid var(--btnBorderColor) !important;
	background: var(--btnBackground) !important;
	border-color: var(--btnBorderColor) !important;
	color: var(--btnTextColor) !important;

	&:hover {
		border-color: var(--btnHoverTextColor) !important;
		color: var(--btnBackground) !important;
		background: var(--btnHoverTextColor) !important;
	}
`;

export const ButtonWarning = styled(ButtonPrimary)`
	--btnBackground: #ff2261;
	--btnHoverBackground: #ff5a88;
	--btnDisabledBackground: var(--btnHoverBackground);
	--btnDisableOpacity: 0.4;
`;

export const ButtonError = styled(ButtonPrimary)`
	background: var(--redColor);
	cursor: default;
	&:hover {
		background: var(--redColor);
	}
`;

export const LoadMoreButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 60px;
	background: white;
	border: none;
	border-radius: 0 0 4px 4px;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.02em;
	color: #00689f;
	margin-bottom: 10px;
	box-shadow: 0px 0.1px 0.3px rgba(28, 28, 30, 0.1), 0px 1px 2px rgba(28, 28, 30, 0.2);

	&:disabled {
		opacity: 0.5;
	}
`;

export const IconButton = styled(ButtonPure)`
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	background: #004d76;
	transition: all 0.25s linear;
	&:hover {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
			rgba(0, 104, 159, 0.8);
	}
	&:disabled {
		opacity: 1;
		background: #cccccc;
		cursor: default;
	}
`;
