import React from "react";
import {IMaskInput} from "react-imask";

interface IProps {
	onChange: (event: {target: {name: string; value: string}}) => void;
	name: string;
}

export const PhoneField = React.forwardRef<HTMLInputElement, IProps>(function TextMaskCustom(
	props,
	ref
) {
	const {onChange, ...other} = props;
	return (
		<IMaskInput
			{...other}
			mask="*#00-000-000"
			definitions={{
				"#": /[45]/,
				"*": /0/,
			}}
			inputRef={ref}
			onAccept={(value: string) => onChange({target: {name: props.name, value}})}
			overwrite
		/>
	);
});
