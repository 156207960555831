import React, {useCallback} from "react";
import {useViewController} from "data/services/locator";
import {INotificationController} from "views/components/Notification/notification.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {ButtonPure} from "views/components/button";
import {Exist} from "views/components/Exist";
import CloseIcon from "@mui/icons-material/Close";
import {IconSuccess} from "views/components/icons/icon_success";

const Wrapper = styled.div`
	position: fixed;
	right: 12px;
	bottom: 12px;
	display: flex;
	justify-content: flex-end;
	align-items: stretch;
	flex-direction: column-reverse;
	gap: 12px;

	@media screen and (max-width: 475px) {
		right: 12px;
		left: 12px;
	}
`;

const Notification = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	background: #ffffff;
	gap: 30px;
	padding: 0 10px;
	box-shadow: 0px 0.8px 1.5px rgba(28, 28, 30, 0.1), 0px 6px 12px rgba(28, 28, 30, 0.2);
	max-width: 300px;

	.info {
		font-family: "OpenSans", sans-serif;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		margin-right: auto;

		svg {
			min-width: 28px;
			width: 28px;
			height: 28px;
		}

		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.02em;
		color: ${({theme}) => theme.colors.black};
	}

	button {
		margin-top: 4px;
	}

	@media screen and (max-width: 475px) {
		max-width: unset;
		width: 100%;
	}
`;

export const NotificationComponent: React.FC = observer(() => {
	const {notifications, closeNotification} = useViewController<INotificationController>(
		Bindings.NotificationController
	);

	const handleClose = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			closeNotification(e.currentTarget.dataset.uuid);
		},
		[closeNotification]
	);

	return (
		<Wrapper>
			{notifications.map((notification) => (
				<Notification key={notification.uuid} className={notification.customClass || ""}>
					<div className="info">
						<IconSuccess /> <span>{notification.text}</span>
					</div>
					<Exist when={Boolean(notification.cancellable)}>
						<ButtonPure onClick={handleClose} data-uuid={notification.uuid}>
							<CloseIcon />
						</ButtonPure>
					</Exist>
				</Notification>
			))}
		</Wrapper>
	);
});
