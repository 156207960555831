import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {NavigateFunction} from "react-router-dom";
import {ModalType} from "data/enums";

interface IParams {
	navigate: NavigateFunction;
}
export interface IModalUserExistsController extends ViewController<IParams> {
	get isOpen(): boolean;

	close: () => void;
	toLogin: () => void;
}

@injectable()
export class ModalUserExistsController implements IModalUserExistsController {
	@observable private _navigate: NavigateFunction | undefined;
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
	}

	public get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.USER_EXISTS;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};

	public toLogin = (): void => {
		this.close();
		this._modalsStore.showModal(ModalType.LOGIN);
	};
}
