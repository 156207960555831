import {observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {RoundStatus} from "data/enums";

export interface ILockoutStore {
	get isLockout(): boolean;
}

@injectable()
export class LockoutStore implements ILockoutStore {
	@observable private _isLockout: boolean = false;

	constructor(@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore) {}

	get isLockout(): boolean {
		return this._roundStore.currentRound?.status === RoundStatus.Playing;
	}
}
