import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ModalType} from "data/enums";

interface IProps {
	username: string;
	userid: number;
}

export interface IRemoveLeagueUserModalController extends ViewController {
	cancelRemove: () => void;
	confirmRemove: () => void;
	get username(): string | undefined;
	get isModalOpen(): boolean;
}

@injectable()
export class RemoveLeagueUserModalController implements IRemoveLeagueUserModalController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}
	cancelRemove = (): void => {
		this._modalsStore.hideModal();
	};

	@action
	confirmRemove = (): void => {
		if (!this.modalData?.userid) {
			this._modalsStore.hideModal();
			this._modalsStore.showError({message: "Invalid user id, user does not exist"});
			return;
		}
		this._leaguesStore.removeUser(this.modalData.userid);
		this._modalsStore.hideModal();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get isModalOpen(): boolean {
		return this._modalsStore.modal === ModalType.LEAGUE_REMOVE_USER;
	}

	get username(): string | undefined {
		return this.modalData?.username;
	}

	private get modalData(): IProps | undefined {
		return this._modalsStore.modalContent as IProps;
	}
}
