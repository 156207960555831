import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable} from "mobx";
import type {IUserStore} from "data/stores/user/user.store";

export interface IContactUsController extends ViewController {
	openZendesk(): void;
}

@injectable()
export class ContactUsController implements IContactUsController {
	constructor(@inject(Bindings.UserStore) public _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init() {
		return;
	}

	get user() {
		return this._userStore.tmpUserData;
	}

	public openZendesk(): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		window.zE?.activate();
	}
}
