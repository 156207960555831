import React, {Fragment} from "react";

interface IProps {
	children: React.ReactNode;
	when: boolean;
}

export const Exist: React.FC<IProps> = ({children, when}) => (
	<Fragment>{when ? children : null}</Fragment>
);
