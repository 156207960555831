import {ModalType, RequestState} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {ICustomFieldFragment, IAxiosApiError} from "data/types/global";
import {ViewController} from "data/types/structure";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {inject, injectable} from "inversify";
import {debounce} from "lodash";
import {action, makeAutoObservable, observable} from "mobx";
import React from "react";
import {Bindings} from "data/constants/bindings";
import {getErrorMessageFromAxiosResponse, trackSentryErrors} from "data/utils";
import type {ICountriesStore} from "data/stores/countries/countries.store";
import type {ICountry} from "data/providers/json/json.provider";
import {POSTCODE_COUNTRY} from "data/constants";
import {AxiosError} from "axios";

export interface IRegistrationForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
	firstName: HTMLInputElement;
	lastName: HTMLInputElement;
	teamName: HTMLInputElement;
	dob: HTMLInputElement;
	phone: HTMLInputElement;
	country: HTMLSelectElement;
	postcode: HTMLInputElement;
	favDriver: HTMLInputElement;
}

const initialRegistrationState = {
	email: "",
	password: "",
	confirmPassword: "",
	firstName: "",
	lastName: "",
	teamName: "",
	dob: "",
	phone: "",
	country: "",
	postcode: 0,
	favDriver: "",
	terms: false,
	notifications: true,
	isDriver: false,
};

export interface IUserRegoData {
	email: string;
	password: string;
	confirmPassword: string;
	firstName: string;
	lastName: string;
	teamName: string;
	dob: string;
	country: string;
	postcode: number;
	favDriver: string;
	terms: boolean;
	notifications: boolean;
	isDriver: boolean;
}

export interface IFormRegistrationController extends ViewController {
	get error(): string | undefined;

	get requestState(): RequestState;

	get formErrors(): Record<string, string>;

	get isFormLocked(): boolean;

	get isSubmitDisabled(): boolean;

	get tmpUserData(): IUserRegoData;

	get customFields(): ICustomFieldFragment[] | null;

	get formValidationHelper(): IFormValidationHelper;

	get countryList(): ICountry[];

	get isAU(): boolean;

	get defaultCountry(): string;

	setCountry: (value: string) => void;

	checkValidity(form: IRegistrationForm): Promise<boolean>;

	register(): Promise<void> | void;

	handleSubmitForm: (event: React.SyntheticEvent<IRegistrationForm>) => void;
	handleFormChange: () => void;
	handleInputFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleUsernameFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	goToLogin: () => void;
	handleCustomFieldToTmp: (name: string, value: string | boolean) => void;
}

@injectable()
export class FormRegistrationController implements IFormRegistrationController {
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _error?: string = undefined;
	@observable private _userRegistrationState = initialRegistrationState;

	private checkUsernameUniqueDebounced = debounce(this.checkUsernameUnique.bind(this), 750, {
		leading: false,
		trailing: true,
	});

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper,
		@inject(Bindings.CountriesStore) private _countryStore: ICountriesStore
	) {
		makeAutoObservable(this);
	}

	get tmpUserData() {
		return this._userRegistrationState;
	}

	get error() {
		return this._error;
	}

	get requestState() {
		return this._requestState;
	}

	get formErrors() {
		return this._validationHelper.formErrors;
	}

	get isSubmitDisabled() {
		const {terms} = this._userRegistrationState;

		return !terms;
	}

	get isFormLocked() {
		return this._requestState === RequestState.PENDING;
	}

	get customFields() {
		const fields = this._userStore.registrationCustomFields || [];
		return fields.filter(({isRegistration}) => isRegistration);
	}

	get countryList() {
		return this._countryStore.list;
	}

	get isAU() {
		// If nothing selected - default value AU
		if (!this._userStore.tmpUserData?.country) {
			return true;
		}
		return this._userStore.tmpUserData?.country === POSTCODE_COUNTRY;
	}

	get defaultCountry() {
		return "AUS";
	}

	@action handleFormChange = () => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
	};

	private checkUsernameUnique(fieldName: string, fieldValue: string): Promise<boolean> {
		if (!fieldValue) return Promise.resolve(false);

		return this._userStore
			.checkUsername({
				username: fieldValue,
			})
			.then((error) => {
				if (error) {
					this._validationHelper.setFormFieldError(fieldName, error);
					return false;
				}

				return true;
			})
			.catch((err: Error) => {
				trackSentryErrors(err, {}, "form registration - check username");
				this._validationHelper.setFormFieldError(fieldName, err.message);

				return false;
			});
	}

	handleUsernameFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target;

		this.handleInputFieldChange(event);
		void this.checkUsernameUniqueDebounced(name, value);
	};

	handleInputFieldChange = ({
		target: {name, value, checked},
	}: React.ChangeEvent<HTMLInputElement>) => {
		if (["password", "confirmPassword"].includes(name)) {
			this._validationHelper.clearFormFieldError("password");
			this._validationHelper.clearFormFieldError("confirmPassword");
		} else {
			this._validationHelper.clearFormFieldError(name);
		}

		const valueToUse = ["notifications", "terms", "isDriver"].includes(name) ? checked : value;
		this._userStore.setTmpUserData({[name]: valueToUse});
		this._userRegistrationState = {
			...this._userRegistrationState,
			[name]: valueToUse,
		};
	};

	setCountry = (value: string) => {
		this._userStore.setTmpUserData({
			country: value,
		});

		if (value !== POSTCODE_COUNTRY) {
			this._userStore.setTmpUserData({
				postcode: undefined,
			});
			this._validationHelper.clearFormFieldError("postcode");
		}
	};

	@action private onError = (error: AxiosError) => {
		trackSentryErrors(error, {}, "form registration - error handler");

		this._requestState = RequestState.ERROR;
		this._error = getErrorMessageFromAxiosResponse(
			error as AxiosError<IAxiosApiError, unknown>
		);
	};

	@action private onSuccess = () => {
		this._modalsStore.hideModal();
		this._requestState = RequestState.SUCCESS;
	};

	@action register() {
		this._requestState = RequestState.PENDING;

		if (!this._userStore.tmpUserData) {
			this._error = "Unexpected error. User's data not found";
			this._requestState = RequestState.ERROR;
			return;
		}

		const {
			email,
			firstName,
			teamName,
			lastName,
			password,
			notifications,
			phone,
			terms,
			dob,
			favDriver,
			isDriver,
			postcode,
		} = this._userRegistrationState;

		const country = this._userStore.tmpUserData.country;

		const tmpUserData = {
			email,
			firstName,
			lastName,
			teamName,
			password,
			country: country || this.defaultCountry,
			notifications,
			terms,
			isDriver,
			dob,
			favDriver,
			phone: phone || null,
			postcode: this.isAU ? postcode : undefined,
		};

		return this._userStore.register(tmpUserData).then(this.onSuccess).catch(this.onError);
	}

	goToLogin = () => this._modalsStore.showModal(ModalType.LOGIN);

	async checkValidity(form: IRegistrationForm) {
		const {password, confirmPassword, teamName, phone} = form;
		const {checkValidity, setFormFieldError, formErrors, errors} = this._validationHelper;

		checkValidity(form);

		if (confirmPassword.value !== password.value) {
			setFormFieldError(confirmPassword.name, errors.password_mismatch);
		}

		const phoneValue = phone?.value.replaceAll(/[- )(]/gm, "");
		if (phone?.value.length > 0 && phoneValue?.length !== 10) {
			setFormFieldError(phone.name, "Invalid phone number");
		}

		if (!this._validationHelper.isValid) {
			return false;
		}

		if (!formErrors[teamName.name]) {
			await this.checkUsernameUnique(teamName.name, teamName.value);
		}

		return this._validationHelper.isValid;
	}

	handleSubmitForm = (event: React.SyntheticEvent<IRegistrationForm>) => {
		event.preventDefault();
		event.stopPropagation();

		void this.checkValidity(event.currentTarget).then((isValid) => {
			if (isValid) void this.register();
		});
	};

	@action handleCustomFieldToTmp(name: string, value: string | boolean) {
		this._userStore.setTmpUserData({
			[name]: value,
		});
	}

	dispose(): void {
		return;
	}

	async init() {
		await this._countryStore.fetchCountries();
	}

	get formValidationHelper(): IFormValidationHelper {
		return this._validationHelper;
	}
}
