import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {H2HFinalType, LeaguePrivacy, LeagueStatus, LeagueType} from "data/enums";
import {
	ICreateLeagueParams,
	IFetchLeagueParams,
	IFetchLeaguesParams,
	IInviteLeagueParams,
	IJoinedUser,
	IJoinLeaguesParams,
	ILeaveLeagueParams,
	IShowJoinedUsersParams,
	IShowLadderUsers,
	IUpdateLeague,
} from "data/stores/leagues/leagues.store";
import {IMyLeague} from "views/pages/leagues/join_leagues/join_leagues.page";
import {ITeamLineup} from "data/types/team";

// import {IFetchLeaguesParams} from "data/stores/leagues/leagues.store";

export type IFixture = Record<number, number[][]>;

export interface ILeague {
	id: number;
	name: string;
	userId: number;
	code: string;
	privacy: LeaguePrivacy;
	status: LeagueStatus;
	type: LeagueType;
	startRound: number;
	description: string;
	teamCount: number;
	userName?: string | null;
	isJoined?: boolean;
	teamLimit?: number;
	finalsFormat?: H2HFinalType;
	fixture?: IFixture;
}

type TShowLeagueResponse = IApiResponse<{
	nextPage: boolean;
	leagues: ILeague[];
}>;

type TJoinLeagueResponse = IApiResponse<{league: IMyLeague}>;
type TCreateLeagueResponse = IApiResponse<{
	league: ILeague;
}>;

export interface ILadderUser {
	leagueId: number;
	overallPoints: number;
	overallRank: number;
	points: number;
	rank: number;
	roundId: number;
	userId: number;
	userName: string;
}

export interface IRemoveUserParams {
	league_id: number;
	user_id: number;
}

export interface IFetchMatchupsPayload {
	leagueId: number;
	roundId?: number;
}

export interface IMatchupTeam {
	id: number;
	lineup: ITeamLineup;
	name: string;
	points: number;
	userId: number;
	firstName: string;
	lastName: string;
}

type TShowMyLeaguesResponse = IApiResponse<{leagues: ILeague[]; nextPage: boolean}>;

type TFetchLeagueMatchup = IApiResponse<{teams: IMatchupTeam[]}>;

type TLeaveLeagueResponse = IApiResponse<{leagues: ILeague[]}>;

type TInviteLeagueResponse = IApiResponse<{league: ILeague}>;

type IShowLadderUsersResponse = IApiResponse<{
	rankings: ILadderUser[];
	nextPage: boolean;
	user: ILadderUser;
}>;

type IUpdateLeagueResponse = IApiResponse<{league: ILeague}>;

type IShowJoinedUsersResponse = IApiResponse<{users: IJoinedUser[]; nextPage: boolean}>;

type IRemoveUserResponse = IApiResponse<{users: IJoinedUser[]}>;

type IFetchLeagueResponse = IApiResponse<ILeague>;

export interface ILeagueApiProvider {
	showLeagues: (params: IFetchLeaguesParams) => Promise<AxiosResponse<TShowLeagueResponse>>;
	getLeague: (params: IFetchLeagueParams) => Promise<AxiosResponse<IApiResponse<ILeague>>>;
	joinLeagues: (params: IJoinLeaguesParams) => Promise<AxiosResponse<TJoinLeagueResponse>>;
	createLeague: (params: ICreateLeagueParams) => Promise<AxiosResponse<TCreateLeagueResponse>>;
	showMyLeagues: (params?: IFetchLeaguesParams) => Promise<AxiosResponse<TShowMyLeaguesResponse>>;
	fetchMatchups: (params: IFetchMatchupsPayload) => Promise<AxiosResponse<TFetchLeagueMatchup>>;
	leaveLeague: (params: ILeaveLeagueParams) => Promise<AxiosResponse<TLeaveLeagueResponse>>;
	deleteLeague: (params: ILeaveLeagueParams) => Promise<AxiosResponse<TLeaveLeagueResponse>>;
	inviteLeague: (params: IInviteLeagueParams) => Promise<AxiosResponse<TInviteLeagueResponse>>;
	showLadderUsers: (params: IShowLadderUsers) => Promise<AxiosResponse<IShowLadderUsersResponse>>;
	updateLeague: (
		params: IUpdateLeague,
		leagueId: number
	) => Promise<AxiosResponse<IUpdateLeagueResponse>>;
	showJoinedUsers: (
		params: IShowJoinedUsersParams
	) => Promise<AxiosResponse<IShowJoinedUsersResponse>>;
	removeUser: (params: IRemoveUserParams) => Promise<AxiosResponse<IRemoveUserResponse>>;
}

@injectable()
export class LeagueApiProvider implements ILeagueApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	getLeague = (params: IFetchLeagueParams) => {
		return this._http.get<IFetchLeagueResponse>(`/league/${params.leagueId}/show`);
	};
	showLeagues = (params: IFetchLeaguesParams) => {
		return this._http.get<TShowLeagueResponse>("/league/available-leagues", params);
	};

	joinLeagues = (params: IJoinLeaguesParams) => {
		return this._http.post<TJoinLeagueResponse>(`/league/${params.code}/join`);
	};

	createLeague = (params: ICreateLeagueParams) => {
		return this._http.post<TCreateLeagueResponse>("/league/create", params);
	};

	showMyLeagues = (params?: IFetchLeaguesParams) => {
		return this._http.get<TShowMyLeaguesResponse>("/league/my-leagues", params);
	};

	leaveLeague = (params: ILeaveLeagueParams) => {
		return this._http.post<TLeaveLeagueResponse>(`/league/${params.leagueId}/leave`);
	};

	deleteLeague = (params: ILeaveLeagueParams) => {
		return this._http.post<TLeaveLeagueResponse>(`league/${params.leagueId}/delete`);
	};

	inviteLeague = (params: IInviteLeagueParams) => {
		return this._http.post<TInviteLeagueResponse>(`/league/${params.league_id}/invite`, {
			invites: params.emails,
		});
	};
	showLadderUsers = (params: IShowLadderUsers) => {
		const {league_id, ...rest} = params;
		return this._http.get<IShowLadderUsersResponse>(`/ranking/league/${league_id}`, rest);
	};
	updateLeague = (params: IUpdateLeague, leagueId: number) => {
		return this._http.post<IUpdateLeagueResponse>(`/league/${leagueId}/update`, params);
	};
	showJoinedUsers = (params: IShowJoinedUsersParams) => {
		return this._http.get<IShowJoinedUsersResponse>(`/league/${params.league_id}/users`);
	};
	removeUser = (params: IRemoveUserParams) => {
		return this._http.post<IRemoveUserResponse>(
			`/league/${params.league_id}/remove/user/${params.user_id}`,
			params
		);
	};
	fetchMatchups = (params: IFetchMatchupsPayload) => {
		return this._http.get<TFetchLeagueMatchup>(`/league/${params.leagueId}/matchup`, {
			roundId: params.roundId,
		});
	};
}
