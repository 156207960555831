import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ModalType, RoundStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {noop} from "lodash";
import {observable, reaction} from "mobx";

export interface IFieldActionsBarController extends ViewController {
	clearTeam: () => void;
	saveTeam: () => void;
	autoPick: () => void;

	get driversCounter(): number;

	get driversReserveCounter(): number;

	get driversTotalCounter(): number;

	get takenPlayersCounter(): number;

	get isTeamFull(): boolean;

	get isTeamHasPlayers(): boolean;

	get isLoading(): boolean;

	get isSaveDisabled(): boolean;
}

@injectable()
export class FieldActionsBarController implements IFieldActionsBarController {
	@observable private _isComplete: boolean = false;
	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {}

	get driversCounter(): number {
		const lineup = this._teamStore.teamLineUp;
		return Object.values(lineup).flatMap((e) => e).length;
	}

	get driversReserveCounter(): number {
		return Object.values(this._teamStore.team.lineup.reserve).length;
	}

	get driversTotalCounter(): number {
		return this.driversCounter + this.driversReserveCounter;
	}

	get takenPlayersCounter(): number {
		return this.teamPlayersLineup.filter((e) => e !== 0).length;
	}

	get isTeamFull(): boolean {
		return this.teamPlayersLineup.every((e) => e !== 0);
	}

	get isTeamHasPlayers(): boolean {
		const drivers = Object.values(this._teamStore.teamLineUp).flatMap((e) => e);
		const reserve = Object.values(this._teamStore.team.lineup.reserve);
		return [...drivers, ...reserve].some((e) => e !== 0);
	}

	get isLoading(): boolean {
		return this._teamStore.isTeamLoading;
	}

	get isSaveDisabled(): boolean {
		return this.isLoading || !this._teamStore.hasTeamBeenChanged;
	}

	private get teamPlayersLineup(): number[] {
		const drivers = Object.values(this._teamStore.teamLineUp).flatMap((e) => e);
		const reserve = Object.values(this._teamStore.team.lineup.reserve);
		return [...drivers, ...reserve];
	}

	public clearTeam = (): void => {
		this._teamStore.clearTeam();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		const subscription = reaction(
			() => this._teamStore.team,
			() => {
				if (this._teamStore.team) {
					this._isComplete = this._teamStore.team.isComplete;
					subscription();
				}
			}
		);
	}

	public saveTeam = (): void => {
		if (!this._teamStore.isHeadsSet) {
			this._modalsStore.showModal(ModalType.NO_HEADS_SAVE);
			return;
		}

		this._teamStore
			.saveTeam()
			.then(() => {
				this.checkTeamSavedModal();
			})
			.catch(noop);
	};

	public autoPick = (): void => {
		this._teamStore
			.autoPick()
			.then(() => {
				this.checkTeamSavedModal();
			})
			.catch(noop);
	};

	private checkTeamSavedModal() {
		const round = this._roundsStore.currentRound;
		if (!round) {
			return;
		}

		if (!this._isComplete) {
			this._modalsStore.showModal(ModalType.TEAM_SAVED);
			this._isComplete = true;
		}

		if ([RoundStatus.Complete, RoundStatus.Playing].includes(round.status)) {
			this._modalsStore.showModal(ModalType.TEAM_SAVED);
		}
	}
}
