import React from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {isEqual} from "lodash";
import {ModalType} from "data/enums";
import {Modal} from "@mui/material";
import {ModalBox, ModalContent} from "views/components/modals/modal_common.component";
import styled from "@emotion/styled";
import {ButtonPrimary, ModalCloseButton} from "views/components/button/";
import WarningIcon from "@mui/icons-material/Warning";
import {observer} from "mobx-react";
import CloseIcon from "@mui/icons-material/Close";
import {Bindings} from "data/constants/bindings";

const Title = styled.h2`
	font-family: var(--fontFamilyBase);
	color: var(--primaryTextColor);
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.2px;
`;

const Text = styled.p`
	font-family: var(--fontFamilyBase);
	color: var(--primaryTextColor);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.02em;
`;

const CancelButton = styled(ButtonPrimary)`
	height: 44px;
	text-transform: uppercase;
	margin: 14px 0;
	width: 120px;
`;

const SubmitButton = styled(CancelButton)`
	background: var(--iconErrorColor);
`;

const SModalBox = styled(ModalBox)`
	max-width: 600px;
`;

const SModalContent = styled(ModalContent)`
	margin: 0 auto;
	max-width: 360px;
	padding: 28px 28px 20px 28px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
`;

const IconWrapper = styled.div`
	margin-top: 32px;
	margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 20px;
`;

export const ModalDeactivateConfirm: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController);
	const {visibleModal, close} = controller;
	const isVisible = isEqual(visibleModal, ModalType.DEACTIVATE);

	if (!isVisible) {
		return null;
	}

	return (
		<Modal open={isVisible} onClose={close}>
			<SModalBox>
				<SModalContent>
					<ModalCloseButton onClick={close}>
						<CloseIcon />
					</ModalCloseButton>
					<IconWrapper>
						<WarningIcon color="error" fontSize="large" />
					</IconWrapper>
					<Title>Deactivate Account</Title>
					<Text>Are you sure you want to deactivate your account?</Text>
					<ButtonWrapper>
						<SubmitButton color="error" onClick={controller.deactivate}>
							Yes
						</SubmitButton>
						<CancelButton onClick={close}>Cancel</CancelButton>
					</ButtonWrapper>
				</SModalContent>
			</SModalBox>
		</Modal>
	);
});
