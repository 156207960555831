import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	ISections,
	ICategories,
	IArticles,
} from "data/stores/static_content/static_content.store";
import {STATIC_CONTENT_JSON_URL} from "data/constants";
import {IDriver} from "data/types/team";
import {RoundStatus} from "data/enums";

export interface ICountry {
	name: string;
	code: string;
}

export interface IRace {
	id: number;
	completedAt: string | null;
	date: string;
	drivers: number[];
	group1: boolean;
	group2: boolean;
	name: string;
	regionId: number;
	regionName: string;
	status: RoundStatus;
}

export interface IRound {
	id: number;
	status: RoundStatus;
	startDate: string;
	endDate: string;
	races: IRace[];
}

export interface IDropdownRound extends IRound {
	name: string;
}

export interface INotification {
	name: string;
	isEnabled: boolean;
	message: string;
}

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;

	helpCategories(): Promise<AxiosResponse<ICategories>>;

	helpSections(): Promise<AxiosResponse<ISections>>;

	helpArticles(): Promise<AxiosResponse<IArticles>>;
	rounds(): Promise<AxiosResponse<IRound[]>>;
	fetchDrivers(): Promise<AxiosResponse<IDriver[]>>;

	notificationBar(): Promise<AxiosResponse<INotification>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(@inject(Bindings.JsonHTTPClient) private _http: IHttpClientService) {}

	countries = () => this._http.get<ICountry[]>("countries.json");
	rounds = () => this._http.get<IRound[]>("rounds.json");
	helpSections = () =>
		this._http.get<ISections>(
			`sections.json`,
			{},
			{
				baseURL: STATIC_CONTENT_JSON_URL,
			}
		);

	helpCategories = () =>
		this._http.get<ICategories>(
			`categories.json`,
			{},
			{
				baseURL: STATIC_CONTENT_JSON_URL,
			}
		);

	helpArticles = () =>
		this._http.get<IArticles>(
			`articles.json`,
			{},
			{
				baseURL: STATIC_CONTENT_JSON_URL,
			}
		);

	notificationBar = () => this._http.get<INotification>("notification_bar.json");

	public async fetchDrivers(): Promise<AxiosResponse<IDriver[]>> {
		return this._http.get<IDriver[]>(`drivers.json`);
	}
}
