import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {SelectChangeEvent} from "@mui/material";
import {TeamStats} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";

export interface ITeamStatsSelectController extends ViewController {
	onChange: (event: SelectChangeEvent<TeamStats>) => void;

	get isVisible(): boolean;

	get selection(): TeamStats;
}

@injectable()
export class TeamStatsSelectController implements ITeamStatsSelectController {
	constructor(@inject(Bindings.TeamStore) private _teamStore: ITeamStore) {
		makeAutoObservable(this);
	}

	public get selection(): TeamStats {
		return this._teamStore.fieldStats;
	}

	public get isVisible(): boolean {
		return true;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public onChange = (event: SelectChangeEvent<TeamStats>): void => {
		this._teamStore.fieldStats = event.target.value as TeamStats;
	};
}
